import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useStyles } from './style';
import S3Image from 'components/S3Image';
import restaurant_empty_icon from 'assets/img/restaurant-empty-icon.png';
import geoDistance from 'util/geoDistance';
import { useStore } from 'store';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

interface IProps {
  logo: string | null | undefined;
  imgSrc: string | null | undefined;
  title: string;
  link: string;
  lat: number | null | undefined;
  lng: number | null | undefined;
  takeOut: boolean;
  dining: boolean;
  delivery: boolean;
  takeOutPause: boolean;
  deliveryPause: boolean;
}

const GroceryStoreCard: React.FC<IProps> = ({ imgSrc, logo, title, link, lat, lng, takeOut, dining, delivery, takeOutPause, deliveryPause }) => {
  const classes = useStyles();

  const {
    state: { user }
  } = useStore();
  const {
    coordinate: { latitude, longitude }
  } = user;

  let miles: number | null = null;

  if (lat && lng) {
    miles = geoDistance(latitude, longitude, lat, lng, 'M');
  }

  return (
    <Box className={classes.root} boxShadow={3}>
      <Link to={link}>
        <Box height={165} borderRadius={5} overflow="hidden" position="relative">
          <S3Image src={imgSrc} defaultImage={restaurant_empty_icon} />
          <Box display="flex" width="100%" justifyContent="space-between" className={classes.iconsWrapper}>
            <Box />
            <Box className={classes.miles}>
              <Typography variant="caption"> {miles && latitude !== 0 && longitude !== 0 ? ` ${miles.toFixed(2)} miles` : ''}</Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" padding={1}>
          <Box className={classes.restaurantLogoBox} overflow="hidden">
            <S3Image src={logo} defaultImage={restaurant_empty_icon} />
          </Box>
          <Box>
            <Box>
              <Typography variant="subtitle2">{title}</Typography>
            </Box>

            <Box display="flex" alignItems="center" flexWrap="wrap">
              {takeOut && (
                <Typography variant="body2" color={takeOutPause ? 'error' : 'initial'} className={classes.restaurantDetail}>
                  {`Takeout ${takeOutPause ? '(Paused)' : ''}`}
                </Typography>
              )}
              {delivery && <FiberManualRecordIcon color={deliveryPause ? 'error' : undefined} className={classes.restaurantDetailDot} />}
              {delivery && (
                <Typography variant="body2" color={deliveryPause ? 'error' : 'initial'} className={classes.restaurantDetail}>
                  {`Delivery ${deliveryPause ? '(Paused)' : ''}`}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box>
          {((takeOut && takeOutPause) || (delivery && deliveryPause)) && (
            <Box paddingX={1} paddingBottom={0.5}>
              <Typography className={classes.restaurantDetail} variant="body2" color="error">
                Temporarily paused for the day, future orders can be placed.
              </Typography>
            </Box>
          )}
        </Box>
      </Link>
    </Box>
  );
};

export default GroceryStoreCard;

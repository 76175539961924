import React from 'react';
import { Box, Button, Divider, Grid, Typography, useMediaQuery } from '@material-ui/core';
import S3Image from 'components/S3Image';
import PhoneIcon from '@material-ui/icons/Phone';
import { formatPhoneNumber } from 'util/number';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useHistory } from 'react-router-dom';
import useActiveGroceryStore from 'hooks/groceryStore/useActiveGroceryStore';
import { useGetGroceryDiscountedItems, useGetGroceryStoreById } from 'graphql/hooks';
import { useStyles } from './style';
import restaurant_empty_icon from 'assets/img/restaurant-empty-icon.png';
import GroceryCoupons from 'pages/GroceryStore/GroceryStoreInfo/Deals/Coupons';
import GroceryDiscountedItems from 'pages/GroceryStore/GroceryStoreInfo/Deals/DiscountedItems';

const GroceryDeals = () => {
  const classes = useStyles();

  const history = useHistory();

  const { groceryStoreId } = useActiveGroceryStore();

  const { groceryStore, loading: storeLoading } = useGetGroceryStoreById(groceryStoreId);

  const coupons = groceryStore?.coupons || [];

  const { discountedItems, loading: itemsLoading } = useGetGroceryDiscountedItems(groceryStoreId);

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  const phone = groceryStore?.phone;

  let formattedPhone = null;
  if (phone) {
    formattedPhone = formatPhoneNumber(phone);
  }

  return (
    <Box height="100%" display="flex" flexDirection="column" overflow="auto">
      {!isDesktop ? (
        <Box className={classes.banner} paddingTop={2}>
          {groceryStore && (
            <Box display="flex" alignItems="center" flexDirection="column">
              <Grid container={true} alignItems="center">
                <Grid item={true} xs={6}>
                  <Box paddingX={1}>
                    <S3Image src={groceryStore.getLogo} className={classes.image} defaultImage={restaurant_empty_icon} />
                  </Box>
                </Grid>
                <Grid item={true} xs={6}>
                  <Box display="flex" flexDirection="column" className={classes.restaurantName}>
                    <Typography variant="h6" noWrap={false}>
                      {groceryStore.name}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <a className={classes.link} target="_blank" rel="noopener noreferrer" href={'https://maps.google.com?q=' + groceryStore.getLatLng?.lat + ',' + groceryStore.getLatLng?.lng + ''}>
                <Box display="flex" justifyItems="center" alignItems="center" width="100%" justifyContent="center">
                  <LocationOnIcon fontSize="small" className={classes.locationIcon} />
                  <Typography variant="caption" className={classes.restaurantAbout}>
                    {groceryStore.address?.text}
                  </Typography>
                </Box>
              </a>
              <a href={`tel:${phone}`} className={classes.link}>
                <Box display="flex" justifyItems="center" alignItems="center" className={classes.phoneContainer}>
                  <PhoneIcon fontSize="small" className={classes.phoneIcon} />
                  <Typography variant="caption" className={classes.restaurantAbout}>
                    {formattedPhone ? formattedPhone : phone}
                  </Typography>
                </Box>
              </a>
              <Box display="flex" alignItems="center" justifyContent="center" paddingBottom={1}>
                <Typography variant="caption">Powered by</Typography>
                <Box paddingX={0.5}>
                  <Typography variant="body1">Lokobee</Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box className={classes.bannerWhite}>
            <Box display="flex" justifyContent="center">
              <Button
                size="medium"
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => {
                  history.push(`/grocery/${groceryStoreId}`);
                }}>
                <Typography variant="body1">Order Online</Typography>
              </Button>
            </Box>
            <Box width="100%" paddingY={1}>
              <Divider variant="middle" />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={classes.banner}>
          {groceryStore && (
            <Grid container={true} direction="row" alignItems="center">
              <Grid item={true} md={5}>
                <Grid container={true} alignItems="center">
                  <Grid item={true} md={6}>
                    <Box paddingX={1} className={classes.imageBox}>
                      <S3Image src={groceryStore.getLogo} className={classes.DesktopImage} defaultImage={restaurant_empty_icon} />
                    </Box>
                  </Grid>
                  <Grid item={true} md={6}>
                    <Typography variant="h6" noWrap={false}>
                      {groceryStore.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} md={4} className={classes.aboutDesktop}>
                <Grid container={true} direction="column">
                  <a className={classes.link} target="_blank" rel="noopener noreferrer" href={'https://maps.google.com?q=' + groceryStore.getLatLng?.lat + ',' + groceryStore.getLatLng?.lng + ''}>
                    <Box display="flex" justifyItems="center" alignItems="center" width="100%" justifyContent="start">
                      <LocationOnIcon fontSize="small" className={classes.locationIcon} />
                      <Typography variant="caption" className={classes.restaurantAbout}>
                        {groceryStore.address?.text}
                      </Typography>
                    </Box>
                  </a>
                  <a href={`tel:${phone}`} className={classes.link}>
                    <Box display="flex" justifyItems="start" alignItems="center" width="100%">
                      <PhoneIcon fontSize="small" className={classes.phoneIcon} />
                      <Typography variant="caption" className={classes.restaurantAbout}>
                        {formattedPhone ? formattedPhone : phone}
                      </Typography>
                    </Box>
                  </a>
                </Grid>
              </Grid>
              <Grid item={true} md={3}>
                <Grid container={true} direction="column">
                  <Box display="flex" justifyContent="center">
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => {
                        history.push(`/grocery/${groceryStoreId}`);
                      }}>
                      <Typography variant="body1">Order Online</Typography>
                    </Button>
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="center" paddingBottom={1}>
                    <Typography variant="caption">Powered by</Typography>

                    <Box paddingX={0.5}>
                      <Typography variant="body1">Lokobee</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
      <Box flex={1} paddingTop={1} className={classes.scrollComponent}>
        {coupons.length === 0 && discountedItems && discountedItems.length === 0 && !storeLoading && !itemsLoading ? (
          <Box display="flex" justifyContent="center" paddingX={2}>
            <Typography variant="body1" noWrap={false}>
              Sorry currently no deals available please come back later
            </Typography>
          </Box>
        ) : (
          <>
            {groceryStoreId && (
              <Box padding={isDesktop ? 2 : 1} paddingX={isDesktop ? 4 : 1}>
                <GroceryCoupons clickableCoupons={true} storeId={groceryStoreId} coupons={coupons} />
              </Box>
            )}
            {groceryStore && (
              <Box paddingX={isDesktop ? 4 : 0}>
                <GroceryDiscountedItems groceryStore={groceryStore} allowAddToCart={false} />
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default GroceryDeals;

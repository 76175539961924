import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Divider, Paper, Grid, useTheme } from '@material-ui/core';
import { useHistory, Redirect, useLocation } from 'react-router-dom';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useStyles } from './style';
import { useStore } from 'store';
import PointsIcon from 'assets/img/points_icon.svg';
import { calculateRewardPoints } from 'util/priceCalculator';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import { useContext } from 'react';
import { AuthContext } from 'fbase/authContext';
import { FirebaseContext } from 'fbase';
import { getLocalStorage, removeLocalStorage } from 'util/storage';
import { convertToCCTokens } from 'util/number';

type locationType = {
  show: boolean;
  restaurantId: string;
  subtotalAmount: string;
  googleReviewLink?: string;
  issueRewardPoint?: boolean;
};

const OrderSuccessPage: React.FC = () => {
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const paymentSuccess = query.get('success');

  const { currentUser } = useContext(AuthContext);

  const { firebase } = useContext(FirebaseContext);

  const [showSuccessPage, setShowSuccessPage] = useState(true);

  const [restaurantId, setRestaurantId] = useState('');

  const [googleReviewLink, setGoogleReviewLink] = useState<string | null>(null);
  const [restaurantClosed, setRestaurantClosed] = useState(false);

  const [consumerReward, setConsumerReward] = useState<string>('');
  const [orderType, setOrderType] = useState();

  const {
    dispatch,
    state: { user }
  } = useStore();

  const [totalPoints, setTotalPoints] = useState<null | string>(null);

  useEffect(() => {
    if (paymentSuccess === 'true') {
      dispatch({
        type: 'CART_ACTION_CLEAR_ALL'
      });
      dispatch({
        type: 'USER_ACTION_CLEAR_NUMBER_FROM_URL'
      });
      dispatch({
        type: 'CART_ACTION_IS_PHONE_ORDER',
        payload: false
      });
      dispatch({
        type: 'CART_ACTION_TOGGLE_COUPON_AUTO_APPLY',
        payload: true
      });

      if (user.discardAfterOrderSuccess) {
        dispatch({
          type: 'USER_ACTION_CLEAR_CONTACT_DETAILS'
        });
      }

      //Reseting used points to 0 after order is placed successfully
      dispatch({
        type: 'SET_RESTAURANT_USED_POINTS',
        payload: 0
      });
      if (currentUser && currentUser.isAnonymous) {
        firebase?.signOut().then(() => {
          dispatch({
            type: 'USER_ACTION_CLEAR_CONTACT_DETAILS'
          });
        });
      }
    }
  }, [currentUser, dispatch, firebase, paymentSuccess, user.discardAfterOrderSuccess]);

  useEffect(() => {
    if (paymentSuccess === 'true') {
      const orderState = JSON.parse(getLocalStorage('orderState') || '{}');

      if (orderState.isRestaurnatClosed === true) {
        setRestaurantClosed(true);
      }

      if (orderState.show !== true) {
        setShowSuccessPage(false);
      }

      if (orderState.subtotalAmount && orderState.issueRewardPoint) {
        const subtotal = orderState.subtotalAmount;

        const pointsEarned = calculateRewardPoints(subtotal);

        setTotalPoints(pointsEarned);
      }

      if (orderState.restaurantId) {
        setRestaurantId(orderState.restaurantId);
      }

      if (orderState.googleReviewLink) {
        setGoogleReviewLink(orderState.googleReviewLink);
      }

      if (orderState.earnedCC && orderState.earnedCC.consumerReward && orderState.earnedCC.cryptoUnit) {
        setConsumerReward(convertToCCTokens(orderState.earnedCC.consumerReward, orderState.earnedCC.cryptoUnit));
      }
      if (orderState.orderType) {
        setOrderType(orderState.orderType);
      }

      removeLocalStorage('orderState');
      removeLocalStorage('cartState');
    }
  }, [paymentSuccess]);

  const classes = useStyles();

  const history = useHistory();

  const theme = useTheme();

  const onBack = () => {
    history.push(`/restaurants/${restaurantId}`);
  };
  const renderRewardPointsEarned = () => {
    if (totalPoints && currentUser && !currentUser.isAnonymous) {
      return (
        <Box>
          <Box paddingY={2}>
            <Divider variant="middle" />
          </Box>
          {consumerReward !== '' ? (
            <>
              <Box width="100%" textAlign="center" paddingTop={1}>
                <Typography className={classes.surfaceText} variant="subtitle1">
                  Congratulations, You will earn <span className={classes.boldText}>{consumerReward}</span> after your order is {orderType === 'DELIVERY' ? 'delivered' : 'picked up'}.
                  <br />{' '}
                  <a href="https://biz.maxcashback.ai/cashbacktoken" target="_blank" rel="noopener noreferrer">
                    Learn More.
                  </a>
                </Typography>
              </Box>
              {/* <Box width="100%" textAlign="center" paddingTop={1}>
                <Typography variant="subtitle1">
                  View your CBT in{' '}
                  <u>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push(`/wallet`);
                      }}>
                      Wallet
                    </span>
                  </u>
                </Typography>
              </Box> */}
            </>
          ) : (
            <>
              <Box width="100%" textAlign="center" paddingTop={1}>
                <img src={PointsIcon} alt="" />
              </Box>
              <Box width="100%" textAlign="center" paddingTop={1}>
                <Typography className={classes.surfaceText} variant="subtitle1">
                  You have earned <span className={classes.boldText}>{totalPoints}</span> reward points.
                </Typography>
              </Box>
              <Box width="100%" textAlign="center" paddingTop={1}>
                <Typography className={classes.boldText} variant="body2">
                  Points will be available for redeem in 24 hours.
                </Typography>
              </Box>
            </>
          )}
        </Box>
      );
    }

    return '';
  };

  const renderGoogleReviewLink = () => {
    if (googleReviewLink) {
      return (
        <Box>
          <Box paddingY={2}>
            <Divider variant="middle" />
          </Box>
          <Button
            onClick={() => {
              window.open(googleReviewLink, '_blank');
            }}
            variant="contained"
            color="secondary"
            className={classes.googleReviewButton}
            fullWidth={true}>
            Write Google Review
          </Button>
        </Box>
      );
    }

    return '';
  };

  if (paymentSuccess !== 'true') {
    return <Redirect to="/" />;
  }

  if (!showSuccessPage) {
    return <Redirect to="/" />;
  }

  if (showSuccessPage) {
    return (
      <Box height="100%" display="flex" bgcolor="#fff" flexDirection="column">
        <Box flex={1} display="flex" justifyContent="center" flexDirection="column" alignItems="center" paddingX={2}>
          <Paper elevation={8} className={classes.surface}>
            <Box className={classes.container}>
              <Box>
                <Box width="100%" textAlign="center" paddingTop={1}>
                  <CheckCircleOutlineIcon className={classes.checkIcon} />
                </Box>
                <Box width="100%" textAlign="center" paddingTop={1}>
                  <Typography variant="h6">Thank you</Typography>
                </Box>
                <Box width="100%" textAlign="center" paddingTop={1}>
                  <Typography variant="h6">Order Placed Successfully</Typography>
                </Box>
                {restaurantClosed && (
                  <Box width="100%" textAlign="center" paddingTop={1}>
                    <Typography className={classes.surfaceText} variant="subtitle1">
                      Order will be confirmed after restaurant is opened
                    </Typography>
                  </Box>
                )}
                <Box width="100%" textAlign="center" paddingTop={1}>
                  <Typography className={classes.surfaceText} variant="subtitle1">
                    We will keep you posted about the status of the order via SMS and Email.
                  </Typography>
                </Box>
              </Box>

              {currentUser ? (
                renderRewardPointsEarned()
              ) : consumerReward !== '' ? (
                <>
                  <Box paddingY={2}>
                    <Divider variant="middle" />
                  </Box>
                  <Box width="100%" textAlign="center" paddingTop={1}>
                    <Typography className={classes.surfaceText} variant="subtitle1">
                      Congratulations, You will earn <span className={classes.boldText}>{consumerReward}</span> after your order is {orderType === 'DELIVERY' ? 'delivered' : 'picked up'}.
                      <br />{' '}
                      <a href="https://biz.maxcashback.ai/cashbacktoken" target="_blank" rel="noopener noreferrer">
                        Learn More.
                      </a>
                    </Typography>
                  </Box>
                  {/* <Box width="100%" textAlign="center" paddingTop={1}>
                    <Typography variant="subtitle1">
                      View your CBT in{' '}
                      <u>
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            history.push(`/wallet`);
                          }}>
                          Wallet
                        </span>
                      </u>
                    </Typography>
                  </Box> */}
                </>
              ) : null}

              {renderGoogleReviewLink()}
            </Box>
          </Paper>
          {currentUser && !currentUser.isAnonymous && (
            <Box marginTop={2} display="flex" alignItems="center" justifyContent="center" flexDirection="column" color="#fff" padding={1} borderRadius={5} bgcolor={theme.palette.secondary.dark}>
              <Typography variant="body1" align="center">
                Rate you dish & provide feedback to the restaurant!
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography variant="body1">Visit</Typography>
                <Box paddingX={1}>
                  <LocalMallIcon fontSize="inherit" />
                </Box>
                <Typography variant="body1">"My Orders"</Typography>
              </Box>
            </Box>
          )}
        </Box>

        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6} lg={5}>
            <Box margin={2}>
              <Button onClick={onBack} variant="contained" color="primary" fullWidth={true} disableElevation={true}>
                Go Back
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return null;
};

export default OrderSuccessPage;

import React from 'react';
import { useMediaQuery, Dialog } from '@material-ui/core';
import Transition from 'components/Transition';
import Content from './Content';
import { useStyles } from './style';
import GroceryStoreItemModal from 'models/GroceryItem';

interface IProps {
  groceryStoreItem: GroceryStoreItemModal | null;
  isOpen: boolean;
  handleClose: () => void;
  currency?: 'USD' | 'POINT';
  quantity: number;
  onQuantityChange: (qty: number) => Promise<void>;
}

const GroceryItemDialog: React.FC<IProps> = ({ groceryStoreItem, isOpen, handleClose, currency = 'USD', quantity, onQuantityChange }) => {
  const classes = useStyles();

  const fullScreen = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));

  return (
    <Dialog className={classes.dialog} open={isOpen} fullScreen={fullScreen} fullWidth={true} maxWidth="xs" TransitionComponent={Transition} onClose={handleClose}>
      <Content groceryStoreItem={groceryStoreItem} handleClose={handleClose} currency={currency} quantity={quantity} onQuantityChange={onQuantityChange} />
    </Dialog>
  );
};

export default GroceryItemDialog;

import { Backdrop, Box, Button, ButtonGroup, Divider, Grow, TextField, Typography } from '@material-ui/core';
import { useGetGroceryStoreById, useGetGroceryStoreCart } from 'graphql/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useStyles } from './style';
import { GroceryBizHour, GroceryDeliveryProviders, GroceryOrderType, GroceryServiceType } from 'generated/types';
import { filter } from 'lodash';
import { useStore } from 'store';
import DateTimePicker from 'components/DateTimePicker';
import AddressField from 'components/AddressModal/InputField';
import { IAddress } from 'components/AddressModal/types';
import ApartmentIcon from '@material-ui/icons/Apartment';
import moment from 'moment';
import Big from 'big.js';
import { useAlert } from 'hooks';
import geoDistance from 'util/geoDistance';
import { getTimeData } from 'components/DateTimePicker/utils';
import { isFutureDate } from 'util/date';
import { useGroceryDoordashDeliveryCostEstimateMutation } from 'graphql/hooks/groceryDelivery.hooks';
import useGroceryStorePause from 'hooks/groceryStore/useGroceryStorePause';
import useActiveGroceryStore from 'hooks/groceryStore/useActiveGroceryStore';
import { isStoreClosed } from 'pages/GroceryStore/GroceryCart/util';
import GroceryTodayBizHour from './GroceryTodayBizHour';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import { red } from '@material-ui/core/colors';

const ErrorComponent = ({ text }: { text: string }) => {
  return (
    <Box padding={2}>
      <Box display="flex" alignContent="center" padding={1} borderRadius={5} bgcolor={red[50]}>
        <Box paddingRight={1} display="flex" alignContent="center" justifyContent="center">
          <LabelImportantIcon fontSize="small" color="error" />
        </Box>
        <Typography variant="body1" color="error">
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

interface IProps {
  storeId: string | number;
}

const GrocerySelectOrderType = ({ storeId }: IProps) => {
  const classes = useStyles();

  const { groceryStoreId } = useActiveGroceryStore();

  const { groceryStore } = useGetGroceryStoreById(storeId);

  const { groceryCartItems } = useGetGroceryStoreCart(groceryStoreId);

  const { getGroceryDoordashEstimate } = useGroceryDoordashDeliveryCostEstimateMutation();

  const { lokoAlert } = useAlert();

  const {
    state: { grocery_deliveryTime, grocery_orderType, grocery_deliveryAddress, grocery_apartmentNumber, grocery_selectedPickerDate, grocery_selectedPickerTime },
    dispatch
  } = useStore();

  const setOrderType = useCallback(
    (payload: GroceryOrderType | null) => {
      dispatch({
        type: 'GROCERY_CART_ACTION_SET_ORDER_TYPE',
        payload
      });
    },
    [dispatch]
  );

  const setApartmentNumber = (payload: string) => {
    dispatch({
      type: 'GROCERY_CART_ACTION_SET_APARTMENT_NUMBER',
      payload
    });
  };

  const setDeliveryAddress = (payload: IAddress | null) => {
    dispatch({
      type: 'GROCERY_CART_ACTION_SET_DELIVERY_ADDRESS',
      payload
    });
  };

  const setDeliveryMiles = (payload: Big) => {
    dispatch({
      type: 'GROCERY_CART_ACTION_SET_DELIVERY_MILES',
      payload
    });

    dispatch({
      type: 'GROCERY_CART_ACTION_EVALUATE_CHARGES',
      payload: {
        groceryStore,
        groceryCartItems
      }
    });
  };
  const bizHours = groceryStore?.bizHours;

  const takeoutWaitMinutes = groceryStore?.takeoutWaitMinutes;

  const isTakeoutDisabled = !(groceryStore?.takeoutPaymentSetting?.onlinePaymentTypes || groceryStore?.takeoutPaymentSetting?.onsitePaymentTypes) || !groceryStore.enableTakeoutOrder;

  const isDeliveryDisabled = !(groceryStore?.deliveryPaymentSetting?.onlinePaymentTypes || groceryStore?.deliveryPaymentSetting?.onsitePaymentTypes) || !groceryStore.enableDeliveryOrder;

  const takeoutBizHours: GroceryBizHour[] = bizHours ? filter(bizHours, ({ serviceType }) => serviceType === GroceryServiceType.GroceryTakeout) : [];

  const deliveryBizHours = bizHours ? filter(bizHours, ({ serviceType }) => serviceType === GroceryServiceType.GroceryDelivery) : [];

  const deliveryRadius = groceryStore?.deliverySetting?.deliveryRadius || 0;

  const deliveryTimeMins = groceryStore?.deliverySetting?.waitMinutes || 40;

  const { deliveryPause, takeOutPause } = useGroceryStorePause({ groceryStore });

  const [storeClosed, setStoreClosed] = useState(false);

  const [nextSlotStr, setNextSlotStr] = useState('');

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const noOrderTypesSupported = isTakeoutDisabled && isDeliveryDisabled;

  const groceryStoreCoords = {
    lat: groceryStore?.address?.lat || 37.543862,
    lng: groceryStore?.address?.lng || -121.986923
  };

  const getDeliveryEstimateText = () => {
    if (groceryStore?.deliveryProvider === 'DOORDASH_CLASSIC') {
      return '(ASAP)';
    } else {
      return `(${deliveryTimeMins} mins)`;
    }
  };

  const onAddressClear = () => {
    // if (grocery_orderType === GroceryOrderType.Delivery && groceryStore?.deliveryProvider === GroceryDeliveryProviders.DoordashClassic) {
    //   setGroceryDoordashDeliveryEstimate({ deliveryMiles: Big(0), deliveryCharge: Big(0), doordashQuoteId: null });
    // }
  };

  const onAddressSelect = async (selectedAddress: IAddress) => {
    const { text, country, state, city, zipcode, lat, lng, street } = selectedAddress;

    setDeliveryAddress({
      city,
      country,
      lat,
      lng,
      state,
      street,
      text,
      zipcode
    });

    if (!groceryStore?.deliveryProvider || groceryStore?.deliveryProvider === GroceryDeliveryProviders.Self) {
      const miles = geoDistance(groceryStoreCoords.lat, groceryStoreCoords.lng, selectedAddress.lat, selectedAddress.lng, 'M');
      setDeliveryMiles(Big(miles));
    }

    if (groceryStore?.deliveryProvider === GroceryDeliveryProviders.DoordashClassic) {
      const input = {
        doordashPickupAddress: {
          zip_code: groceryStore?.address?.zipcode,
          city: groceryStore?.address?.city,
          street: groceryStore?.address?.street,
          full_address: groceryStore?.address?.text
        },
        doordashDropoffAddress: {
          zip_code: selectedAddress.zipcode,
          city: selectedAddress.city,
          street: selectedAddress.street,
          full_address: selectedAddress.text
        },
        deliveryProvider: groceryStore.deliveryProvider,
        deliveryMode: groceryStore.deliveryMode,
        sellerStoreId: groceryStore.id,
        // We randomly pass 1 $ just to check if given address is in range
        // To calculate delivery charge we again call estimate api in cart flow
        doordashOrderValue: 100
      };

      const res = await getGroceryDoordashEstimate({
        variables: {
          input
        }
      });

      if (res && !res.errors) {
        // const deliveryEstimate: GroceryDeliveryEstimateOutput = res.data.groceryDeliveryEstimateRequest;
        // const deliveryCharge = deliveryEstimate && deliveryEstimate.doordashFee ? Big(deliveryEstimate.doordashFee) : Big('0');
        // const quoteId = deliveryEstimate && deliveryEstimate.quote_id ? deliveryEstimate.quote_id : '';
        // // setGroceryDoordashDeliveryEstimate({ deliveryMiles: Big(miles), deliveryCharge, doordashQuoteId: quoteId });
      } else {
        // setGroceryDoordashDeliveryEstimate({ deliveryMiles: Big(0), deliveryCharge: Big(0), doordashQuoteId: null });
        setDeliveryAddress(null);
        lokoAlert('Selected address is out of range.');
      }
    }
  };

  useEffect(() => {
    if (!isTakeoutDisabled && isDeliveryDisabled) {
      setOrderType(GroceryOrderType.Takeout);
    } else if (isTakeoutDisabled && !isDeliveryDisabled) {
      setOrderType(GroceryOrderType.Delivery);
    } else {
    }
  }, [isDeliveryDisabled, isTakeoutDisabled, setOrderType]);

  useEffect(() => {
    // Check in biz hours if store is closed right now
    if (grocery_orderType === GroceryOrderType.Takeout) {
      const restaurnatClosed = isStoreClosed(takeoutBizHours, grocery_orderType, groceryStore?.deliveryProvider);
      setStoreClosed(restaurnatClosed);
    }
    if (grocery_orderType === GroceryOrderType.Delivery) {
      const restaurnatClosed = isStoreClosed(deliveryBizHours, grocery_orderType, groceryStore?.deliveryProvider);
      setStoreClosed(restaurnatClosed);
    }
  }, [deliveryBizHours, groceryStore, grocery_orderType, takeoutBizHours]);

  useEffect(() => {
    if (grocery_orderType !== null) {
      let service = '';
      let waitMins = 0;

      if (grocery_orderType === GroceryOrderType.Takeout) {
        service = 'RESTAURANT_PICK_UP';
        waitMins = takeoutWaitMinutes || 10;
      }

      if (grocery_orderType === GroceryOrderType.Delivery) {
        service = 'RESTAURANT_DELIVERY';
        waitMins = groceryStore?.deliveryProvider === GroceryDeliveryProviders.DoordashClassic ? 60 : deliveryTimeMins;
      }

      const curHours = filter(bizHours, (hour) => hour.serviceType === service);

      const availableSlots = getTimeData(curHours, waitMins);

      if (availableSlots.length) {
        const firstSlot = availableSlots[0].availableSlots[0];

        if (grocery_orderType === GroceryOrderType.Delivery && groceryStore?.deliveryProvider === GroceryDeliveryProviders.DoordashClassic) {
          // To create slots we add wait time to start and end time
          // But for doordash we don't need to add to start time so subtracting here
          // We still pass it because we need to subtract from end time

          firstSlot.subtract(60, 'm');
        }

        if (firstSlot) {
          const nextSlot = firstSlot.format('LT');

          setNextSlotStr(nextSlot);
        }
      }
    }
  }, [bizHours, deliveryTimeMins, groceryStore, grocery_orderType, takeoutWaitMinutes]);

  useEffect(() => {
    if (grocery_orderType === null) {
      setOpenBackdrop(true);
    }
  }, [grocery_orderType]);

  const disabled = () => {
    if (grocery_orderType === null) {
      return true;
    }

    if (grocery_orderType === GroceryOrderType.Delivery) {
      const futureOrder = isFutureDate(grocery_deliveryTime);

      if (!deliveryBizHours.length) {
        return true;
      }

      if (!grocery_deliveryAddress) {
        return true;
      }

      if (deliveryPause && !futureOrder) {
        return true;
      }

      if (groceryStore?.deliveryProvider === GroceryDeliveryProviders.DoordashClassic) {
        // if (!grocery_doordashQuoteId) {
        //   return true;
        // }
        if (storeClosed) {
          return true;
        }
      }
    }

    if (grocery_orderType === GroceryOrderType.Takeout) {
      const futureOrder = isFutureDate(grocery_deliveryTime);
      if (!takeoutBizHours.length) {
        return true;
      }

      if (takeOutPause && !futureOrder) {
        return true;
      }
    }

    return false;
  };

  return (
    <Box className={classes.container}>
      <Box padding={1} onClick={() => setOpenBackdrop(true)}>
        {grocery_orderType === null && noOrderTypesSupported && <Typography variant="body1"> Orders not supported by the restaurant</Typography>}

        {grocery_orderType === null && !noOrderTypesSupported && <Typography variant="body1"> Select order type</Typography>}

        {grocery_orderType === GroceryOrderType.Takeout && (
          <Box>
            <Typography className={classes.orderType} variant="subtitle1">
              TAKEOUT
            </Typography>
            <Typography variant="subtitle2">{moment(grocery_deliveryTime).format('DD MMM, dddd, LT')}</Typography>
          </Box>
        )}

        {grocery_orderType === GroceryOrderType.Delivery && (
          <Box>
            <Typography className={classes.orderType} variant="subtitle1">
              DELIVERY
            </Typography>
            <Typography variant="subtitle2">{grocery_deliveryAddress?.text.replace(', USA', '').replace('USA', '')}</Typography>
          </Box>
        )}
      </Box>
      <Backdrop open={openBackdrop} className={classes.backdrop}>
        <Box height="max-content" className={classes.content}>
          <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
            <Box width="100%">
              <ButtonGroup fullWidth={true} color="secondary">
                {!isTakeoutDisabled && (
                  <Button
                    size="small"
                    disableElevation={true}
                    variant={grocery_orderType === GroceryOrderType.Takeout ? 'contained' : 'outlined'}
                    onClick={() => setOrderType(GroceryOrderType.Takeout)}>
                    Takeout ({takeoutWaitMinutes} mins)
                  </Button>
                )}

                {!isDeliveryDisabled && (
                  <Button
                    size="small"
                    disableElevation={true}
                    variant={grocery_orderType === GroceryOrderType.Delivery ? 'contained' : 'outlined'}
                    onClick={() => setOrderType(GroceryOrderType.Delivery)}>
                    Delivery {getDeliveryEstimateText()}
                  </Button>
                )}
              </ButtonGroup>
              <Grow in={grocery_orderType !== null}>
                <Box paddingTop={2}>
                  {grocery_orderType === GroceryOrderType.Takeout && (
                    <>
                      <DateTimePicker
                        selectedPickerDate={grocery_selectedPickerDate}
                        selectedPickerTime={grocery_selectedPickerTime}
                        orgType="GROCERY"
                        onChange={() => {}}
                        businessHours={bizHours ? bizHours : []}
                        waitingTime={takeoutWaitMinutes}
                        orderType="TAKEOUT"
                        label="Takeout time"
                      />
                      <GroceryTodayBizHour bizHours={takeoutBizHours} />
                    </>
                  )}

                  {grocery_orderType === GroceryOrderType.Delivery && (
                    <>
                      {groceryStore?.deliveryProvider === 'SELF' && (
                        <DateTimePicker
                          selectedPickerDate={grocery_selectedPickerDate}
                          selectedPickerTime={grocery_selectedPickerTime}
                          orgType="GROCERY"
                          onChange={() => {}}
                          businessHours={bizHours ? bizHours : []}
                          waitingTime={deliveryTimeMins}
                          orderType="DELIVERY"
                          label="Delivery time"
                        />
                      )}
                      <Box padding={1}>
                        <AddressField
                          defaultValue={grocery_deliveryAddress ? grocery_deliveryAddress.text : ''}
                          onChange={() => {
                            if (grocery_deliveryAddress) {
                              setDeliveryAddress(null);
                            }
                          }}
                          onAddressClear={onAddressClear}
                          onAddressSelect={onAddressSelect}
                          variant="outlined"
                          restaurantLatitude={groceryStoreCoords.lat}
                          restaurantLongitude={groceryStoreCoords.lng}
                          checkRange={true}
                          deliveryRadius={deliveryRadius}
                        />
                      </Box>
                      <Box padding={1}>
                        <TextField
                          placeholder="Apartment Number"
                          fullWidth={true}
                          value={grocery_apartmentNumber}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setApartmentNumber(e.target.value);
                          }}
                          InputProps={{
                            startAdornment: <ApartmentIcon className={classes.icon} />
                          }}
                          variant="outlined"
                        />
                      </Box>
                      <GroceryTodayBizHour bizHours={deliveryBizHours} />
                    </>
                  )}
                </Box>
              </Grow>

              <Box paddingY={1}>
                {storeClosed &&
                  (grocery_orderType === GroceryOrderType.Takeout || (grocery_orderType === GroceryOrderType.Delivery && groceryStore?.deliveryProvider === GroceryDeliveryProviders.Self)) && (
                    <ErrorComponent text="Store closed right now, but you can place future order." />
                  )}

                {storeClosed && grocery_orderType === GroceryOrderType.Delivery && groceryStore?.deliveryProvider !== GroceryDeliveryProviders.Self && (
                  <ErrorComponent text={`Store closed right now.${nextSlotStr !== '' ? `You can place order at ${nextSlotStr}` : ''}`} />
                )}

                {grocery_orderType === GroceryOrderType.Delivery && grocery_deliveryAddress === null && <ErrorComponent text="Please select delivery address." />}

                {grocery_orderType === GroceryOrderType.Takeout && takeOutPause && <ErrorComponent text="Ordering paused right now.Future orders can be placed." />}

                {grocery_orderType === GroceryOrderType.Delivery && deliveryPause && <ErrorComponent text="Ordering paused right now." />}

                <Box paddingTop={1}>
                  <Button size="small" fullWidth={true} color="primary" variant="contained" disableElevation={true} onClick={() => setOpenBackdrop(false)} disabled={disabled()}>
                    Use above service
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box width="100%">
              <Box padding={1}>
                <Divider className={classes.divider} />
              </Box>
              <Box paddingY={1}>
                <Button size="small" fullWidth={true} color="secondary" variant="contained" disableElevation={true} onClick={() => setOpenBackdrop(false)}>
                  Only view items
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Backdrop>
    </Box>
  );
};

export default GrocerySelectOrderType;

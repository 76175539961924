import React from 'react';
import { makeStyles, StepConnector, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

export const CustomConnectorStyle = withStyles({
  alternativeLabel: {},
  active: {
    '& $line': {
      borderLeft: '2px solid #388E3C'
    }
  },
  completed: {
    '& $line': {
      borderLeft: '2px solid #388E3C'
    }
  },
  line: {
    height: 1,
    border: 0,
    borderLeft: '2px dotted #ccc'
  },
  vertical: {
    transform: 'translate(-1%, 0)',
    padding: 0
  },
  lineVertical: {
    transform: 'scale(1, 2)'
  }
})(StepConnector);

const useCustomStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#D6D6D6',
    zIndex: 1,
    color: '#fff',
    width: 20,
    height: 20,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    backgroundColor: '#388E3C'
  },
  completed: {
    backgroundColor: '#388E3C'
  }
});

export const CustomStepIcon = (props: { icon: String; active: boolean; completed: boolean }) => {
  const classes = useCustomStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}>
      <FiberManualRecordIcon fontSize="inherit" />
    </div>
  );
};

import { Badge, Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import { useStyles } from '../style';
import GroceryStoreItemModal from 'models/GroceryItem';
import { grey } from '@material-ui/core/colors';
import CancelIcon from '@material-ui/icons/Cancel';

interface IProps {
  isDesktop: boolean;
  category: any;
  onSearchChange: (s: string) => void;
  searchQuery: string;
  brandFilter: Array<string>;
  groceryItemsList: Array<GroceryStoreItemModal>;
  onBack: () => void;
  setOpen: (b: boolean) => void;
  clearFilters: () => void;
}

const Header: React.FC<IProps> = ({ isDesktop, setOpen, category, groceryItemsList, onBack, searchQuery, onSearchChange, brandFilter, clearFilters }) => {
  const classes = useStyles();
  return (
    <>
      <Box id="header" paddingY={1} paddingX={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Box display={'flex'} alignItems={'center'}>
          <Box display={'flex'} marginRight={1}>
            <ArrowBack fontSize="small" onClick={onBack} />
          </Box>
          <Typography variant="h6">{category && category.title}</Typography>
        </Box>
        <Box>
          {isDesktop ? (
            <Box display={'flex'} justifyContent={'space-between'}>
              <Box height={'100%'} display={'flex'} alignItems={'center'}>
                <TextField
                  InputProps={{
                    startAdornment: <SearchIcon style={{ opacity: '0.5' }} />,
                    endAdornment:
                      searchQuery !== '' ? (
                        <ClearIcon
                          onClick={() => {
                            onSearchChange('');
                          }}
                        />
                      ) : null
                  }}
                  placeholder="Search items..."
                  fullWidth={true}
                  size="small"
                  variant="outlined"
                  value={searchQuery}
                  className={classes.searchBox}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSearchChange(e.target.value)}
                />
              </Box>
              <Box display={'flex'} marginLeft={1} alignItems={'center'}>
                <Badge badgeContent={brandFilter.length} color="secondary">
                  <Button variant="contained" color="primary" className={classes.filterBtn} size="small" onClick={() => setOpen(true)} disableElevation={true}>
                    <FilterListIcon fontSize="small" />
                    <Box marginLeft={1}>
                      <Typography variant="body1">Filter</Typography>
                    </Box>
                  </Button>
                </Badge>
                <Box marginLeft={1}>
                  <Button variant="contained" color="primary" className={classes.filterBtn} size="small" onClick={clearFilters} disableElevation={true}>
                    <CancelIcon fontSize="small" />
                    <Box marginLeft={1}>
                      <Typography variant="body1">Clear filters</Typography>
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : (
            <Button variant="contained" color="primary" className={classes.filterBtn} size="small" onClick={clearFilters} disableElevation={true}>
              <CancelIcon fontSize="small" />
              <Box marginLeft={1}>
                <Typography variant="body1">Clear filters</Typography>
              </Box>
            </Button>
          )}
        </Box>
      </Box>
      {!isDesktop && (
        <Box bgcolor={grey[50]} display={'flex'} alignItems={'center'} paddingY={1} paddingX={2}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <Box height={'100%'} display={'flex'} alignItems={'center'}>
                <TextField
                  InputProps={{
                    startAdornment: <SearchIcon style={{ opacity: '0.5' }} />,
                    endAdornment:
                      searchQuery !== '' ? (
                        <ClearIcon
                          onClick={() => {
                            onSearchChange('');
                          }}
                        />
                      ) : null
                  }}
                  placeholder="Search items..."
                  fullWidth={true}
                  size="small"
                  variant="outlined"
                  value={searchQuery}
                  className={classes.searchBox}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSearchChange(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={3} className={classes.displayFlex}>
              <Badge badgeContent={brandFilter.length} color="secondary">
                <Box display={'flex'} alignItems={'center'}>
                  <Button variant="contained" color="primary" className={classes.filterBtn} size="small" onClick={() => setOpen(true)} disableElevation={true}>
                    <FilterListIcon fontSize="small" />
                    <Box marginLeft={1}>
                      <Typography variant="body1">Filter</Typography>
                    </Box>
                  </Button>
                </Box>
              </Badge>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Header;

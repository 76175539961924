import { Box, Button, Typography } from '@material-ui/core';
import S3Image from 'components/S3Image';
import React from 'react';
import { useStyles } from './style';
import groceryEmptyIcon from 'assets/img/grocery-category-empty-icon.png';
import { Stepper } from '@lokobee/lokobee-ui';
import { convertPriceTo } from 'util/number';
import { useAlert } from 'hooks';

interface IProps {
  title: string;
  price: number;
  size: string;
  imagePreview: string | null;
  brand: string;
  quantity: number;
  onQuantityChange: (qty: number) => void;
  currency?: 'USD' | 'POINT';
  rewardPoints?: number;
  isAddPossible?: boolean;
  allowAddToCart?: boolean;
}
const GroceryItemCard: React.FC<IProps> = ({
  title,
  size,
  price,
  brand,
  imagePreview,
  quantity,
  onQuantityChange,
  currency = 'USD',
  rewardPoints = 0,
  isAddPossible = true,
  allowAddToCart = true
}) => {
  const classes = useStyles();

  const addDisabled = currency === 'POINT' && !isAddPossible ? true : false;

  const { lokoAlert } = useAlert();

  return (
    <Box bgcolor={'#fff'}>
      <Box className={classes.root} boxShadow={1}>
        <Box marginBottom={2} style={addDisabled ? { opacity: '0.5' } : {}}>
          <S3Image squareImage={true} src={imagePreview} defaultImage={groceryEmptyIcon} />
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Box marginBottom={1} width={'100%'}>
            <Typography variant="body1" className={classes.itemTitle}>
              {title} ({brand})
            </Typography>
          </Box>
          <Box display={'flex'} marginBottom={1} alignItems={'center'}>
            <Typography variant="body1"> {currency === 'POINT' ? `${rewardPoints} PTS` : `$${convertPriceTo(price, 'DOLLAR')}`} </Typography>
            <Typography variant="body1" className={classes.priceCaption}>
              {size !== '' ? <>({size})</> : <>(each)</>}
            </Typography>
          </Box>
          {allowAddToCart && (
            <Box padding={1}>
              {quantity === 0 ? (
                <Button
                  className={classes.addBtn}
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    onQuantityChange(1);
                  }}
                  disableElevation={true}
                  disabled={addDisabled}>
                  Add
                </Button>
              ) : (
                <Box>
                  <Stepper
                    value={quantity}
                    onChange={(val: number, e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();

                      if (val < quantity) {
                        onQuantityChange(val);
                      } else {
                        if (addDisabled) {
                          lokoAlert('Not enough points to add one more item');
                        } else {
                          onQuantityChange(val);
                        }
                      }
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default GroceryItemCard;

import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, Box, Button, Hidden } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartSharp';
import { useStore } from 'store';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { AuthContext } from 'fbase/authContext';
import { useGetGroceryStoreById, useGetGroceryStoreCart } from 'graphql/hooks';
import logo from 'assets/img/icon-1024.png';
import S3Image from 'components/S3Image';
import { useStyles } from '../style';
import UserLocation from '../UserLocation';
import { useUserLogout } from 'hooks';
import useActiveGroceryStore from 'hooks/groceryStore/useActiveGroceryStore';
import GrocerySelectOrderType from './GrocerySelectOrderType';

const GroceryHeader = () => {
  const classes = useStyles();

  const history = useHistory();

  const { currentUser } = useContext(AuthContext);

  const { pathname } = useLocation();

  const { groceryStoreId } = useActiveGroceryStore();

  const { groceryStore } = useGetGroceryStoreById(groceryStoreId);

  const { groceryCartItems, groceryCartRewardItems } = useGetGroceryStoreCart(groceryStoreId);

  const groceryStoreLogo = groceryStore?.getLogo || null;

  const groceryStoreName = groceryStore?.name || '';

  const { dispatch } = useStore();

  const { logOut } = useUserLogout();

  const match = useRouteMatch({
    path: '/',
    strict: true,
    sensitive: true
  });

  const shouldShowLocationComponent = (!!match && match.isExact) || false;

  const openLoginDialog = () => {
    dispatch({
      type: 'UI_ACTION_LOGIN_POPUP',
      payload: true
    });
  };

  const toggleCartDisplay = () => {
    if (groceryCartItems.length || groceryCartRewardItems.length) {
      history.push(`/grocery/${groceryStoreId}/cart`, {
        from: {
          pathname
        }
      });
    }
  };

  const signOut = async () => {
    logOut();
  };

  let totalItemInCart = 0;

  groceryCartItems.forEach((item) => {
    totalItemInCart += item.quantity;
  });

  groceryCartRewardItems.forEach((item) => {
    totalItemInCart += item.quantity;
  });

  return (
    <Box className={classes.root} id="header">
      <AppBar position="sticky" className={classes.appBar}>
        {!shouldShowLocationComponent && groceryStoreId && <GrocerySelectOrderType storeId={groceryStoreId} />}
        <Toolbar disableGutters={true} className={classes.toolbar}>
          {shouldShowLocationComponent && (
            <Box component="span">
              <UserLocation />
            </Box>
          )}

          {groceryStoreLogo && (
            <Box className={classes.restaurantLogoBox} overflow="hidden">
              <S3Image src={groceryStoreLogo} />
            </Box>
          )}

          <Box textAlign="left" className={classes.titleContainer} flexGrow={1}>
            <Typography variant="h6" className={classes.title}>
              {groceryStoreName}
            </Typography>
          </Box>
          <Hidden xsDown={true}>
            <Box onClick={() => history.push('/')}>
              <img className={classes.logo} src={logo} alt="LOKOBEE" />
            </Box>
            <Button className={classes.headerBtn} onClick={() => history.push('/myaccount')}>
              {currentUser ? 'My Account' : 'About'}
            </Button>
            {currentUser && (
              <Button className={classes.headerBtn} onClick={() => history.push('/orders')}>
                My orders
              </Button>
            )}
            {!currentUser ? (
              <Button className={classes.headerBtn} onClick={openLoginDialog}>
                Sign In
              </Button>
            ) : (
              <Button className={classes.headerBtn} onClick={signOut}>
                Sign out
              </Button>
            )}
          </Hidden>
          <Box className={classes.boxCart} onClick={toggleCartDisplay}>
            <ShoppingCartIcon />
            {totalItemInCart > 0 && (
              <Box m={0.5} pb={0.5}>
                <Typography> {totalItemInCart}</Typography>
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default GroceryHeader;

import React, { useContext, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './style';
import Content from './Content';
import { useParams, useHistory } from 'react-router-dom';
import { AuthContext } from 'fbase/authContext';
import DialogHeader from 'components/DialogHeader';
import { useStore } from 'store';

const GroceryOrderDetails: React.FC = () => {
  const classes = useStyles();

  const { currentUser, isAuthStateChanged } = useContext(AuthContext);

  const { dispatch } = useStore();

  const { id } = useParams();

  const buyerId = currentUser?.uid;

  const history = useHistory();

  const onBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (!!!currentUser && isAuthStateChanged) {
      dispatch({
        type: 'UI_ACTION_LOGIN_POPUP',
        payload: true
      });
    } else {
      dispatch({
        type: 'UI_ACTION_LOGIN_POPUP',
        payload: false
      });
    }
  }, [currentUser, dispatch, isAuthStateChanged]);

  return (
    <Box className={classes.dialog}>
      <Box className={classes.dialogContent}>
        <DialogHeader text="Orders Detail" onBack={onBack} />
        {id && buyerId && <Content orderId={id} buyerId={buyerId} />}
      </Box>
    </Box>
  );
};

export default GroceryOrderDetails;

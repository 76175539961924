import React, { useContext, useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import GroceryStoreItemModal from 'models/GroceryItem';
import GroceryItemCard from 'components/GroceryItemCard';
import { GroceryStoreCartItem } from 'generated/types';
import { groupBy } from 'lodash';
import { AuthContext } from 'fbase/authContext';
import { useStore } from 'store';
import GroceryItemDialog from 'components/GroceryItemDialog';
import useGroceryReward from 'hooks/groceryStore/useGroceryReward';
import Big from 'big.js';
import useActiveGroceryStore from 'hooks/groceryStore/useActiveGroceryStore';
import { useAddToGroceryStoreCart } from 'graphql/hooks';
import { useSnackbar } from 'notistack';

interface IProps {
  items: GroceryStoreItemModal[];
  groceryCartRewardItems: GroceryStoreCartItem[];
}

const itemsPerPage = 10;

const Menu = ({ items, groceryCartRewardItems }: IProps) => {
  const { groceryStoreId } = useActiveGroceryStore();

  const { addToGroceryStoreCart } = useAddToGroceryStoreCart(groceryStoreId);

  const { currentUser } = useContext(AuthContext);

  const [isItemDialogOpen, setItemDialogOpen] = useState(false);

  const [activeItem, setActiveItem] = useState<null | GroceryStoreItemModal>(null);

  const { dispatch } = useStore();

  const snackbar = useSnackbar();

  const [pageSize, setPageSize] = useState(itemsPerPage);

  const { remainingPoints, isRedeemDisabled } = useGroceryReward();

  const openLoginDialog = () => {
    dispatch({
      type: 'UI_ACTION_LOGIN_POPUP',
      payload: true
    });
  };

  const onQuantityChange = async (item: GroceryStoreItemModal, qty: number) => {
    if (currentUser) {
      const response = await addToGroceryStoreCart(item, qty, true);

      if (response && response.data && response.data.AddToGroceryStoreCart) {
      } else {
        snackbar.enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    } else {
      openLoginDialog();
    }
  };

  const loadMoreItems = () => {
    setPageSize((oldVal) => {
      return oldVal + itemsPerPage;
    });
  };

  const onItemSelect = (groceryItem: GroceryStoreItemModal) => {
    setActiveItem(groceryItem);
    setItemDialogOpen(true);
  };

  const cartItems = groupBy(groceryCartRewardItems, 'item.id');

  return (
    <Box paddingX={2} paddingY={1} bgcolor={grey[50]}>
      <Grid container={true} spacing={2}>
        {items.slice(0, pageSize).map((item) => {
          const isItemEligible = Big(remainingPoints).gte(item.rewardPoints || 0) && !isRedeemDisabled;

          return (
            <Grid
              item={true}
              onClick={() => {
                if (isItemEligible) {
                  onItemSelect(item);
                }
              }}
              key={item.id}
              xs={6}
              sm={6}
              md={2}>
              <GroceryItemCard
                imagePreview={(item.images && item.images[0].preview.url) || null || undefined}
                brand={item.brand}
                price={item.price}
                title={item.title}
                size={item.size}
                quantity={cartItems[item.id] ? cartItems[item.id][0].quantity : 0}
                onQuantityChange={(qty: number) => onQuantityChange(item, qty)}
                currency="POINT"
                rewardPoints={item.rewardPoints}
                isAddPossible={isItemEligible}
              />
            </Grid>
          );
        })}
      </Grid>

      {pageSize < items.length && (
        <Box paddingY={1} display={'flex'} justifyContent={'center'}>
          <Button variant="contained" onClick={loadMoreItems} color="primary" size="small" disableElevation={true}>
            <Typography variant="body1">Load more</Typography>
          </Button>
        </Box>
      )}
      {activeItem && (
        <GroceryItemDialog
          isOpen={isItemDialogOpen}
          handleClose={() => setItemDialogOpen(false)}
          groceryStoreItem={activeItem}
          quantity={cartItems[activeItem.id] ? cartItems[activeItem.id][0].quantity : 0}
          onQuantityChange={(qty: number) => onQuantityChange(activeItem, qty)}
          currency="POINT"
        />
      )}
    </Box>
  );
};

export default Menu;

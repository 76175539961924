import { Tab, Tabs } from '@material-ui/core';
import { GroceryStoreCategory } from 'generated/types';
import GroceryStoreModel from 'models/GroceryStore';
import React from 'react';
import { useStyles } from '../style';

interface IProps {
  isDesktop: boolean;
  groceryStore: GroceryStoreModel;
  activeSubCategoryId: string;
  category: GroceryStoreCategory | undefined;
  onTabClickHandler: (id: string) => void;
}

const SubCategoryTabs: React.FC<IProps> = ({ isDesktop, category, groceryStore, activeSubCategoryId, onTabClickHandler }) => {
  const classes = useStyles();

  if (groceryStore.categories && groceryStore.categories.length > 0) {
    if (category) {
      const selectedCategory = category;

      if (selectedCategory.subCategories && selectedCategory.subCategories.length > 0) {
        return (
          <Tabs value={activeSubCategoryId} classes={{ indicator: classes.tabIndicator, root: classes.tabsContainer }} variant="scrollable" scrollButtons={isDesktop ? 'auto' : 'off'}>
            <Tab disableRipple value="" classes={{ root: classes.customTab, selected: classes.selectedTab }} selected={activeSubCategoryId === ''} label="All" onClick={() => onTabClickHandler('')} />
            {selectedCategory.subCategories.map((subItem: any, index: number) => {
              return (
                <Tab
                  value={subItem.id}
                  disableRipple
                  classes={{ root: classes.customTab, selected: classes.selectedTab }}
                  key={index + 1}
                  selected={subItem.id === activeSubCategoryId}
                  label={subItem.title}
                  onClick={() => onTabClickHandler(subItem.id)}
                />
              );
            })}
          </Tabs>
        );
      }
    }
  }

  return null;
};

export default SubCategoryTabs;

import React, { useState, useContext } from 'react';
import OrderProcessingScreen from 'pages/Payment/OrderProcessingScreen';
import { Box, Button, Typography } from '@material-ui/core';
import { useStore } from 'store';
import { CreateGroceryOrderInput, GroceryDeliveryProviders, GroceryOrderLineItemInput, GroceryOrderType, GroceryStoreCartItem, PaymentType } from 'generated/types';
import { useStyles } from './style';
import { AuthContext } from 'fbase/authContext';
import SecurePayments from 'assets/img/secure-payments.svg';
import ContactDetails from '../ContactDetails';
import CheckoutSummary from '../CheckoutSummary';
import Logger from 'util/logger';
import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { CreditCard } from '@material-ui/icons';
import { useAlert } from 'hooks';

import GroceryStoreModel from 'models/GroceryStore';
import { createGroceryOrderMutation } from 'graphql/mutations';
import useGroceryStorePause from 'hooks/groceryStore/useGroceryStorePause';
import { setLocalStorage } from 'util/storage';

interface IProps {
  groceryStore: GroceryStoreModel;
  groceryCartItems: GroceryStoreCartItem[];
  groceryCartRewardItems: GroceryStoreCartItem[];
  redeemedPoints: number;
}

const PayByStripeCheckout = ({ groceryStore, groceryCartItems, groceryCartRewardItems, redeemedPoints }: IProps) => {
  const classes = useStyles();

  const { state } = useStore();

  const { grocery_orderType, grocery_deliveryAddress, grocery_deliveryTime } = state;

  const [loading, setLoading] = useState(false);

  const { lokobeeUser } = useContext(AuthContext);

  const [isSubmitBlocked, setSubmitBlocked] = useState(false);

  const { lokoAlert } = useAlert();

  const { deliveryPause, takeOutPause } = useGroceryStorePause({ groceryStore });

  const isPaused = (grocery_orderType === GroceryOrderType.Takeout && takeOutPause) || (grocery_orderType === GroceryOrderType.Delivery && deliveryPause);

  /* ------------- CREATE ORDER MUTATION ---------------- */
  const [createOrder] = useMutation<any, any>(createGroceryOrderMutation, {
    onError: (err: ApolloError) => {
      if (err.graphQLErrors && err.graphQLErrors.length) {
        let errMsg = err.graphQLErrors[0].message || 'Failed to create order';

        let errMsgLower = errMsg.toLowerCase();

        if (errMsgLower.startsWith('incorrect total') || errMsgLower.startsWith('invalid input') || errMsgLower.startsWith('invalid sellerbizid') || errMsgLower.startsWith('incorrect redeempoints')) {
          errMsg = 'Failed to create order';
        }

        lokoAlert(errMsg);

        Logger.log('ORDER ERROR', {
          errorShownToUser: errMsg,
          error: err
        });
      } else {
        lokoAlert('Failed to create order');

        Logger.log('ORDER ERROR', {
          errorShownToUser: 'Failed to create order',
          error: err
        });
      }
    }
  });

  /* ------------- CREATE ORDER REQUEST ---------------- */

  const createOrderRequest = async () => {
    if (!lokobeeUser) {
      lokoAlert('Please login to continue.');
      return null;
    }

    let email = null;

    if (state.user && state.user.email) {
      email = state.user.email;
    }

    if (!email) {
      lokoAlert('Email does not exists');
      return null;
    }

    let orderPhoneNumber = null;

    if (state.user && state.user.orderPhoneNumber) {
      orderPhoneNumber = state.user.orderPhoneNumber;
    }

    if (!orderPhoneNumber) {
      lokoAlert('Phone number does not exists');
      return null;
    }

    if (groceryCartItems && groceryCartItems.length > 0) {
      const {
        grocery_tip,
        grocery_finalPrice,
        grocery_deliveryTime,
        grocery_orderNote,
        grocery_deliveryAddress,
        grocery_deliveryNote,
        grocery_orderType,
        grocery_serviceFee,
        grocery_couponToken,
        grocery_doordashQuoteId,
        grocery_dropOffType,
        grocery_apartmentNumber
      } = state;

      if (!grocery_finalPrice) {
        lokoAlert('Total price cannot be null');
        return null;
      }

      const cartItems: GroceryOrderLineItemInput[] = [];

      groceryCartItems.forEach((item) => {
        cartItems.push({
          itemId: item.item.id,
          categoryId: item.item.categoryId,
          count: item.quantity
        });
      });

      groceryCartRewardItems.forEach((item) => {
        cartItems.push({
          itemId: item.item.id,
          categoryId: item.item.categoryId,
          count: item.quantity,
          isReward: true
        });
      });

      const createOrderInput: CreateGroceryOrderInput = {
        type: grocery_orderType ? grocery_orderType : GroceryOrderType.Takeout,
        sellerStoreId: groceryStore.id || '',
        items: cartItems,
        note: grocery_orderNote.trim() !== '' ? grocery_orderNote : null,
        expectTime: grocery_deliveryTime,
        paymentType: PaymentType.OnlineStripe,
        tip: parseInt(grocery_tip.valueOf()),
        lokobeeFee: groceryStore?.lokobeeFee ? parseInt(grocery_serviceFee?.valueOf() || '0') : null,
        total: parseInt(grocery_finalPrice.valueOf()),
        email,
        orderPhoneNumber,
        smsNotification: true,
        couponToken: grocery_couponToken,
        isAlcohol: false,
        isDiscounted: false,
        redeemPoints: redeemedPoints
      };

      if (grocery_orderType === GroceryOrderType.Delivery) {
        createOrderInput.deliveryAddress = grocery_deliveryAddress;
        createOrderInput.deliveryNote = grocery_deliveryNote;
        createOrderInput.deliveryProvider = groceryStore.deliveryProvider;
        createOrderInput.deliveryMode = groceryStore.deliveryMode;

        if (groceryStore?.deliveryProvider === GroceryDeliveryProviders.DoordashClassic) {
          createOrderInput.doordashQuoteId = grocery_doordashQuoteId;
          createOrderInput.dropOffInstruction = grocery_dropOffType;
          createOrderInput.apartmentNumber = grocery_apartmentNumber;
        }
      }

      const response = await createOrder({
        variables: {
          input: createOrderInput
        }
      });

      if (response && response.data) {
        const {
          data: {
            createGroceryOrder: { clientSecret }
          }
        } = response;

        return { response, clientSecret };
      } else {
        const { errors } = response || {};

        if (errors && errors.length) {
          let errMsg = errors[0].message || 'Failed to create order';

          let errMsgLower = errMsg.toLowerCase();

          if (
            errMsgLower.startsWith('incorrect total') ||
            errMsgLower.startsWith('invalid input') ||
            errMsgLower.startsWith('invalid sellerstoreid') ||
            errMsgLower.startsWith('incorrect redeempoints')
          ) {
            errMsg = 'Failed to create order';
          }

          Logger.log('GROCERY ORDER ERROR', {
            errorShownToUser: errMsg,
            error: errors
          });
          throw new Error(errMsg);
        } else {
          Logger.log('GROCERY ORDER ERROR', {
            errorShownToUser: 'Failed to create order',
            error: errors
          });
          throw new Error('Failed to create order');
        }
      }
    } else {
      Logger.log('GROCERY CART ERROR', state);
      throw new Error('GROCERY Cart error');
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!isSubmitBlocked) {
      setSubmitBlocked(true);

      setLoading(true);

      try {
        const { response } = (await createOrderRequest()) || {};

        if (response && response.data && response.data.createGroceryOrder) {
          if (response.data.createGroceryOrder.redirectUrl) {
            const { grocery_subtotalAfterDiscount, grocery_subtotal, grocery_isDiscountApplied } = state;

            setLocalStorage(
              'orderState',
              JSON.stringify({
                show: true,
                subtotalAmount: grocery_isDiscountApplied ? grocery_subtotalAfterDiscount.valueOf() : grocery_subtotal.valueOf(),
                issueRewardPoint: groceryStore?.issueRewardPoint ? 'true' : 'false'
              })
            );
            window.open(response.data.createGroceryOrder.redirectUrl, '_self');
          }
        }
      } catch (err) {
        setSubmitBlocked(false);

        setLoading(false);
        // if ((err as any).message !== '') {
        //   lokoAlert((err as any).message);
        // }
      }
    }
  };

  const isDisabled = isSubmitBlocked || isPaused;

  if (groceryStore.hasStripeAccount) {
    return (
      <>
        {loading && <OrderProcessingScreen />}
        <Box paddingX={1} flex={1} overflow="scroll" className={classes.payment} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
          <Box paddingY={1}>
            <ContactDetails />
            <CheckoutSummary
              storeName={groceryStore?.name || ''}
              storeAddress={groceryStore?.address}
              orderType={grocery_orderType || GroceryOrderType.Takeout}
              deliveryAddress={grocery_deliveryAddress}
              eta={grocery_deliveryTime}
            />
          </Box>
          <Box>
            <Box paddingY={2} display="flex" justifyContent="center">
              <a target="_blank" rel="noopener noreferrer" href="https://stripe.com/docs/security/stripe">
                <img src={SecurePayments} alt="Secure Payments" />
              </a>
            </Box>
            <Button type="submit" variant="contained" color="primary" fullWidth={true} startIcon={<CreditCard />} disabled={isDisabled} onClick={handleSubmit}>
              Checkout
            </Button>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <Box textAlign="center">
      <Typography variant="body1" color="error">
        Online Payment Not Available
      </Typography>
    </Box>
  );
};

export default PayByStripeCheckout;

import { grey } from '@material-ui/core/colors';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& a': {
        textDecoration: 'none',
        color: 'inherit'
      },
      background: '#fff',
      padding: theme.spacing(2),
      margin: theme.spacing(0.5, 0)
    },
    itemLogoBox: {
      width: '100px',
      borderRadius: '3px'
    },
    itemTitle: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      padding: 0,
      margin: 0,
      width: '100%',
      textAlign: 'center'
    },

    priceCaption: {
      color: grey[500], //not sure which primary to use
      marginLeft: theme.spacing(1)
    },
    addBtn: {
      boxShadow: 'none'
    },
    groupBtns: {
      border: 'solid 1px #000',
      borderRadius: 0,
      minWidth: '30px',
      padding: 0
    },
    btnLeft: {
      border: 'solid 1px #000',
      borderRadius: '5px 0 0 5px',
      borderRight: 'none'
    },
    btnRight: {
      border: 'solid 1px #000',

      borderLeft: 'none'
    },
    btnMiddle: {
      borderRight: 'none',
      borderLeft: 'none'
    }
  })
);

import { useContext, useEffect, useState } from 'react';
import { GetGroceryStoresNearbyInput, GroceryStoreCartItem, GroceryStoreCartItemInput } from 'generated/types';
import { GroceryStore } from 'models';
import { useLazyQueryWithLoader, useMutationWithLoader, useQueryWithLoader } from 'hooks/loader';
import { getGroceryStoreByLocation, getGroceryStore, getGroceryItemsByCategoryId, getGroceryStoreCart, getGroceryRewardItems, queryGrocerySearch, getGroceryDiscountedItems } from 'graphql/query';
import { groupBy, map } from 'lodash';
import { plainToClass } from 'class-transformer';
import geoDistance from 'util/geoDistance';
import { addToGroceryStoreCart as addToGroceryStoreCartQuery } from 'graphql/mutations';
import GroceryStoreItemModal from 'models/GroceryItem';
import { AuthContext } from 'fbase/authContext';

export const useGetGroceryStoresByLocation = ({ lat, lng, radiusInMeter }: GetGroceryStoresNearbyInput) => {
  const [groceryStoreList, setGroceryStoreList] = useState<GroceryStore[]>([]);

  const { data, loading, error } = useQueryWithLoader(getGroceryStoreByLocation, {
    variables: {
      input: {
        lat,
        lng,
        radiusInMeter
      }
    }
  });

  useEffect(() => {
    if (data && data.getGroceryStoresByLocation) {
      const { edges } = data.getGroceryStoresByLocation;

      let groceryStores = map(edges, ({ node }) => {
        const groceryStore = plainToClass(GroceryStore, node);

        return groceryStore;
      });

      if (lat !== 0 && lng !== 0) {
        groceryStores = groceryStores.sort((a: GroceryStore, b: GroceryStore) => {
          const aLat = a.getLatLng?.lat || undefined;
          const aLng = a.getLatLng?.lng || undefined;

          const bLat = b.getLatLng?.lat || undefined;
          const bLng = b.getLatLng?.lng || undefined;

          if (aLat && aLng && bLat && bLng) {
            const aMiles = geoDistance(lat, lng, aLat, aLng, 'M');

            const bMiles = geoDistance(lat, lng, bLat, bLng, 'M');

            if (aMiles < bMiles) {
              return -1;
            } else if (aMiles > bMiles) {
              return 1;
            } else {
              return 0;
            }
          }
          return 0;
        });
      }

      setGroceryStoreList(groceryStores);
    } else {
      setGroceryStoreList([]);
    }
  }, [data, lat, lng]);

  return { groceryStoreList, loading, error };
};

export const useGetGroceryStoreById = (id: string | number | null) => {
  const { loading, data } = useQueryWithLoader(getGroceryStore, {
    skip: !id,
    variables: {
      input: {
        storeId: id
      }
    }
  });

  if (data && data.getGroceryStore) {
    const groceryStore = plainToClass(GroceryStore, data.getGroceryStore);

    return {
      loading,
      groceryStore
    };
  }

  return {
    loading,
    groceryStore: null
  };
};

export const useGetGroceryItemsByCategoryId = (storeId: string | number | null, categoryId: string) => {
  const { loading, data } = useQueryWithLoader(getGroceryItemsByCategoryId, {
    skip: !storeId || !categoryId,
    variables: {
      input: {
        storeId: storeId,
        categoryId: categoryId
      }
    }
  });

  if (data && data.getGroceryItemsByCategoryId) {
    // const groceryItem = plainToClass(GroceryStoreItem,data.getGroceryItemsByCategoryId);

    const groceryItems = data.getGroceryItemsByCategoryId;

    return {
      loading,
      groceryItems
    };
  }

  return {
    loading,
    groceryStore: null
  };
};

export const useGetGroceryStoreCart = (storeId: string | number | null) => {
  const { currentUser } = useContext(AuthContext);

  const [groceryCartItems, setGroceryCartItems] = useState<GroceryStoreCartItem[]>([]);

  const [groceryCartRewardItems, setGroceryCartRewardItems] = useState<GroceryStoreCartItem[]>([]);

  const [redeemedPoints, setRedeemedPoints] = useState<number>(0);

  const [cartLoading, setCartLoading] = useState(true);

  const { data, loading, error } = useQueryWithLoader(getGroceryStoreCart, {
    skip: !storeId || !currentUser,
    variables: {
      input: {
        storeId: storeId
      }
    }
  });

  useEffect(() => {
    //console.log('api changed');

    /**
     * Need to pass this array in useEffect hook and useEffect does not update for nested array updates
     * So creating new array on every update using map
     **/
    if (data && data.getGroceryStoreCart) {
      if (data.getGroceryStoreCart.items && data.getGroceryStoreCart.items.length) {
        setGroceryCartItems(
          data.getGroceryStoreCart.items.map((item: any) => {
            return { ...item };
          })
        );
      } else {
        setGroceryCartItems([]);
      }

      if (data.getGroceryStoreCart.rewardItems && data.getGroceryStoreCart.rewardItems.length) {
        setGroceryCartRewardItems(
          data.getGroceryStoreCart.rewardItems.map((item: any) => {
            return { ...item };
          })
        );
      } else {
        setGroceryCartRewardItems([]);
      }

      setRedeemedPoints(data.getGroceryStoreCart.redeemedPoints || 0);

      setCartLoading(false);
    } else {
      if (data !== undefined || error || !currentUser) {
        setCartLoading(false);
      }
    }
  }, [currentUser, data, error]);

  return {
    data,
    groceryCartItems,
    groceryCartRewardItems,
    redeemedPoints,
    cartLoading,
    loading
  };
};

export const useAddToGroceryStoreCart = (storeId: string | null) => {
  const { groceryCartItems, groceryCartRewardItems } = useGetGroceryStoreCart(storeId);

  const [addToGroceryStoreCart, { data }] = useMutationWithLoader(addToGroceryStoreCartQuery, {}, true);

  const updateCaller = async (item: GroceryStoreItemModal, qty: number, isReward: boolean) => {
    if (storeId) {
      const currentCart = groupBy(groceryCartItems, 'item.id');

      const currentCartRewards = groupBy(groceryCartRewardItems, 'item.id');

      if (isReward) {
        if (qty === 0) {
          delete currentCartRewards[item.id];
        } else {
          if (currentCartRewards[item.id]) {
            currentCartRewards[item.id][0].quantity = qty;
          } else {
            currentCartRewards[item.id] = [
              {
                item,
                quantity: qty
              }
            ];
          }
        }
      } else {
        if (qty === 0) {
          delete currentCart[item.id];
        } else {
          if (currentCart[item.id]) {
            currentCart[item.id][0].quantity = qty;
          } else {
            currentCart[item.id] = [
              {
                item,
                quantity: qty
              }
            ];
          }
        }
      }

      const itemsInput: GroceryStoreCartItemInput[] = [];

      for (const key in currentCart) {
        const item = currentCart[key][0];
        itemsInput.push({
          categoryId: item.item.categoryId,
          itemId: item.item.id,
          quantity: item.quantity
        });
      }

      for (const key in currentCartRewards) {
        const item = currentCartRewards[key][0];
        itemsInput.push({
          categoryId: item.item.categoryId,
          itemId: item.item.id,
          quantity: item.quantity,
          isReward: true,
          rewardPoints: (item.item.rewardPoints || 0) * item.quantity
        });
      }

      const response = await addToGroceryStoreCart({
        variables: {
          input: {
            storeId,
            items: itemsInput
          }
        },
        update: (cache, { data: newData }) => {
          const { AddToGroceryStoreCart } = newData || { AddToGroceryStoreCart: null };

          if (AddToGroceryStoreCart) {
            cache.writeQuery({
              data: { getGroceryStoreCart: { ...AddToGroceryStoreCart } },
              query: getGroceryStoreCart,
              variables: {
                input: {
                  storeId
                }
              }
            });
          }
        }
      });

      return response;
    }
  };

  return {
    addToGroceryStoreCart: updateCaller,
    data
  };
};

export const useClearGroceryStoreCart = (storeId: string | null) => {
  const [addToGroceryStoreCart, { data }] = useMutationWithLoader(addToGroceryStoreCartQuery, {}, true);

  const updateCaller = async () => {
    if (storeId) {
      const response = await addToGroceryStoreCart({
        variables: {
          input: {
            storeId,
            items: []
          }
        },
        update: (cache, { data: newData }) => {
          const { AddToGroceryStoreCart } = newData || { AddToGroceryStoreCart: null };

          if (AddToGroceryStoreCart) {
            cache.writeQuery({
              data: { getGroceryStoreCart: { ...AddToGroceryStoreCart } },
              query: getGroceryStoreCart,
              variables: {
                input: {
                  storeId
                }
              }
            });
          }
        }
      });

      return response;
    }
  };

  return {
    clearGroceryStoreCart: updateCaller,
    data
  };
};

export const useGetGroceryRewardItems = (storeId: string | number | null) => {
  const { loading, data } = useQueryWithLoader(getGroceryRewardItems, {
    skip: !storeId,
    variables: {
      input: {
        storeId: storeId
      }
    }
  });

  if (data && data.getGroceryRewardItems) {
    const items: any[] = data.getGroceryRewardItems;
    const count = data.getGroceryRewardItems.length;
    const rewardItems: GroceryStoreItemModal[] = plainToClass(GroceryStoreItemModal, items);

    return {
      loading,
      count,
      rewardItems
    };
  }

  return {
    loading,
    count: 0,
    rewardItems: null
  };
};

export const useGetGroceryDiscountedItems = (storeId: string | number | null) => {
  const { loading, data, called } = useQueryWithLoader(getGroceryDiscountedItems, {
    skip: !storeId,
    variables: {
      input: {
        storeId: storeId
      }
    }
  });

  if (data && data.getGroceryDiscountedItems) {
    const items: any[] = data.getGroceryDiscountedItems;
    const count = data.getGroceryDiscountedItems.length;
    const discountedItems: GroceryStoreItemModal[] = plainToClass(GroceryStoreItemModal, items);

    return {
      loading,
      count,
      discountedItems,
      called
    };
  }

  return {
    loading,
    count: 0,
    discountedItems: null
  };
};

export const useQueryGrocerySearch = () => {
  const [querySearch, { data, loading }] = useLazyQueryWithLoader(queryGrocerySearch, {}, true);

  return {
    querySearch,
    data,
    loading
  };
};

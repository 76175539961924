import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useStore } from 'store';
import { useStyles } from '../style';
import { useGetLokobeeUser } from 'graphql/hooks/user.hooks';
import useActiveGroceryStore from 'hooks/groceryStore/useActiveGroceryStore';
import { AuthContext } from 'fbase/authContext';
import ContactDetailsFormForGuest from 'components/AccountModal/ContactDetailsFormForGuest';
import ContactDetailsFormForLokobeeUser from 'components/AccountModal/ContactDetailsFormForLokobeeUser';

interface IProps {
  total: string;
  tax: string;
}

const PlaceOrderBtn: React.FC<IProps> = ({ total, tax }) => {
  const classes = useStyles();

  const { groceryStoreId } = useActiveGroceryStore();

  const {
    dispatch,
    state: { user }
  } = useStore();

  const { currentUser } = useContext(AuthContext);

  const history = useHistory();

  const [openContactDetailsForm, setOpenContactDetailsForm] = useState(false);

  const [openGuestContactDetailsForm, setOpenGuestContactDetailsForm] = useState(false);

  const { data } = useGetLokobeeUser();

  const { orderEmail, orderPhoneNumber, email } = data && data.getLokobeeUser ? data.getLokobeeUser : { orderEmail: null, orderPhoneNumber: null, email: null };

  const setUserInStore = useCallback(
    (phoneNumber: string, email?: string, nickName?: string) => {
      const input: { phoneNumber: string; email?: string; nickName?: string } = {
        phoneNumber: phoneNumber,
        nickName: nickName
      };

      if (email) {
        input.email = email;
      }

      dispatch({
        type: 'USER_ACTION_SET_CONTACT_DETAILS',
        payload: input
      });
    },
    [dispatch]
  );

  useEffect(() => {
    let userEmail: any;

    if (orderEmail) {
      userEmail = orderEmail;
    } else if (email) {
      userEmail = email;
    } else if (user.email !== '') {
      userEmail = user.email;
    }

    let userPhoneNumber: any;

    if (orderPhoneNumber) {
      userPhoneNumber = orderPhoneNumber;
    } else if (user.orderPhoneNumber) {
      userPhoneNumber = user.orderPhoneNumber;
    }

    setUserInStore(userPhoneNumber, userEmail, user.nickName || '');
  }, [email, orderEmail, orderPhoneNumber, setUserInStore, user.email, user.nickName, user.orderPhoneNumber]);

  const onPlaceOrderClick = () => {
    if (currentUser) {
      // if (showAlcoholAgreement) {
      //   setOpenAlcoholAgreement(true);
      // } else {
      //   placeOrder();
      // }
      placeOrder();
    } else {
      openLoginPopup();
    }
  };

  const placeOrder = () => {
    if (currentUser) {
      if (currentUser.isAnonymous) {
        setOpenGuestContactDetailsForm(true);
      } else {
        if (user.email && user.orderPhoneNumber) {
          goToNextPage();
        } else {
          setOpenContactDetailsForm(true);
        }
      }
    } else {
      openLoginPopup();
    }
  };

  const goToNextPage = () => {
    history.push(`/grocery/${groceryStoreId}/cart/select-type`);
  };

  const openLoginPopup = () => {
    dispatch({
      type: 'UI_ACTION_LOGIN_POPUP',
      payload: {
        openLoginPopup: true,
        onLoginRoute: `/grocery/${groceryStoreId}/cart/select-type`,
        showContactFormForGuest: true,
        onGuestLoginRoute: `/grocery/${groceryStoreId}/cart/select-type`,
        showContactFormForLokobeeUser: true
      }
    });
  };

  const handleContactsDialogClose = (submitted: boolean) => {
    if (submitted) {
      goToNextPage();
    } else {
      setOpenContactDetailsForm(false);
      setOpenGuestContactDetailsForm(false);
    }
  };

  return (
    <>
      <Box margin={2} display="flex" boxShadow={1}>
        <Box flex="1 1 0" paddingY={1} paddingX={2}>
          <Typography variant="body1" component="div">
            <Box fontWeight="500">Total: ${total}</Box>
            {/* <Box fontWeight="500">Tax: ${tax}</Box> */}
          </Typography>
        </Box>
        <Button className={classes.paymentButton} variant="contained" disableElevation={true} color="primary" fullWidth={true} onClick={onPlaceOrderClick} disabled={parseInt(total) === 0}>
          Place Order
        </Button>
      </Box>
      {openGuestContactDetailsForm && <ContactDetailsFormForGuest open={openGuestContactDetailsForm} onHandleClose={handleContactsDialogClose} />}
      {openContactDetailsForm && <ContactDetailsFormForLokobeeUser open={openContactDetailsForm} onHandleClose={handleContactsDialogClose} />}
    </>
  );
};

export default PlaceOrderBtn;

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0
  },
  dishImgWrapper: {
    '& > img': {
      width: '100%'
    }
  },
  dialogContent: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    '&:first-child': {
      padding: 0
    }
  },
  dishDetails: {
    padding: theme.spacing(2)
  },
  dialog: {
    '& .MuiPaper-root': {
      height: '100%'
    }
  },
  dishImg: {
    transition: 'margin 0.3s ease-in-out'
  },
  dishContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    backgroundColor: '#fff'
  },
  priceItem: {
    padding: theme.spacing(0.5)
  },
  priceStriked: {
    color: '#7e818c',
    textDecoration: 'line-through'
  },
  closeIcon: {
    zIndex: 11
  },
  priceDiscount: {
    background: theme.palette.success.dark,
    color: 'white',
    padding: '0 4px',
    borderRadius: '2px',
    fontWeight: 500
  },
  ratings: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '0.5rem',
    fontWeight: 400
  },
  rateScore: {
    fontWeight: 700
  }
}));

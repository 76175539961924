import React, { useState } from 'react';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { CustomBottomDialog } from '@lokobee/lokobee-ui';
import CloseIcon from '@material-ui/icons/Close';
import Big from 'big.js';
import { useStore } from 'store';
import { useEffect } from 'react';
import { convertPriceTo } from 'util/number';

interface IProps {
  tax: Big;
  setServiceFee: (payload: Big) => void;
}

const GroceryServiceFeeDetailsDialog: React.FC<IProps> = ({ tax, setServiceFee }) => {
  const {
    state: { grocery_serviceFee }
  } = useStore();

  const [open, setOpen] = useState(false);

  const [value, setValue] = useState<string>(convertPriceTo(grocery_serviceFee?.valueOf() || 0, 'DOLLAR') || '');

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  const onSubmit = () => {
    if (value) {
      setServiceFee(Big(convertPriceTo(value, 'CENT')));
      setOpen((prev) => !prev);
    }
  };

  useEffect(() => {
    setValue(convertPriceTo(grocery_serviceFee?.valueOf() || 0, 'DOLLAR') || '');
  }, [grocery_serviceFee]);

  return (
    <>
      <InfoIcon fontSize="small" style={{ color: 'darkgrey', fontSize: '1em', marginLeft: '4px' }} onClick={toggleOpen} />
      <Box height={20} marginLeft={1}>
        <CustomBottomDialog open={open}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box display="flex" marginBottom={1}>
              <Box flex={1}>
                <Typography variant="h6">Tax and Fees</Typography>
              </Box>
              <Box>
                <CloseIcon onClick={toggleOpen} color="inherit" />
              </Box>
            </Box>
            <Box marginBottom={2}>
              <Divider />
            </Box>
            <Box flex={1}>
              <Box display="flex" marginBottom={1}>
                <Box flex={1}>
                  <Typography variant="body1" gutterBottom={true}>
                    Tax
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1" gutterBottom={true}>
                    ${convertPriceTo(tax.valueOf(), 'DOLLAR')}
                  </Typography>
                </Box>
              </Box>
              {grocery_serviceFee && (
                <Box display="flex" justifyItems="center" alignItems="center">
                  <Box flex={1}>Service fee:</Box>
                  <Typography variant="body1" gutterBottom={true}>
                    ${convertPriceTo(grocery_serviceFee.valueOf(), 'DOLLAR')}
                  </Typography>
                </Box>
              )}

              <Typography variant="caption">Your contribution helps Lokobee provide innovative & free service to local restaurants. Together let’s make our local community thrive!</Typography>
            </Box>
            <Button variant="contained" color="primary" fullWidth={true} onClick={onSubmit}>
              Ok
            </Button>
          </Box>
        </CustomBottomDialog>
      </Box>
    </>
  );
};

export default GroceryServiceFeeDetailsDialog;

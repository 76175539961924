import React, { useState, useContext, ChangeEvent, useEffect } from 'react';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import OrderProcessingScreen from 'pages/Payment/OrderProcessingScreen';
import { Box, Button, TextField, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useStore } from 'store';
import { CreateGroceryOrderInput, GroceryDeliveryProviders, GroceryOrderLineItemInput, GroceryOrderType, GroceryStoreCartItem, PaymentType } from 'generated/types';
import { useStyles } from './style';
import { AuthContext } from 'fbase/authContext';
import SecurePayments from 'assets/img/secure-payments.svg';
import ContactDetails from '../ContactDetails';
import CheckoutSummary from '../CheckoutSummary';
import Logger from 'util/logger';
import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { ExpandMore, CreditCard } from '@material-ui/icons';
import { useAlert } from 'hooks';
import { PaymentRequest } from '@stripe/stripe-js';
import GroceryStoreModel from 'models/GroceryStore';
import StripeInput from './StripeInput';
import { createGroceryOrderMutation } from 'graphql/mutations';
import PayByRequestButton from './PayByRequestButton';
import useGroceryStorePause from 'hooks/groceryStore/useGroceryStorePause';

interface IProps {
  groceryStore: GroceryStoreModel;
  groceryCartItems: GroceryStoreCartItem[];
  groceryCartRewardItems: GroceryStoreCartItem[];
  redeemedPoints: number;
}

const PayByCardUI = ({ groceryStore, groceryCartItems, groceryCartRewardItems, redeemedPoints }: IProps) => {
  const classes = useStyles();

  const history = useHistory();

  const { state } = useStore();

  const { grocery_orderType, grocery_deliveryAddress, grocery_deliveryTime } = state;

  const [loading, setLoading] = useState(false);

  const { lokobeeUser } = useContext(AuthContext);

  const [name, setName] = useState('');

  const [isSubmitBlocked, setSubmitBlocked] = useState(false);

  const [cardExpanded, setCardExpanded] = useState(false);

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);

  const { lokoAlert } = useAlert();

  useEffect(() => {
    if (paymentRequest) {
      setCardExpanded(false);
    } else {
      setCardExpanded(true);
    }
  }, [paymentRequest]);

  const { deliveryPause, takeOutPause } = useGroceryStorePause({ groceryStore });

  const isPaused = (grocery_orderType === GroceryOrderType.Takeout && takeOutPause) || (grocery_orderType === GroceryOrderType.Delivery && deliveryPause);

  /* ------------- CREATE ORDER MUTATION ---------------- */
  const [createOrder] = useMutation<any, any>(createGroceryOrderMutation, {
    onError: (err: ApolloError) => {
      if (err.graphQLErrors && err.graphQLErrors.length) {
        let errMsg = err.graphQLErrors[0].message || 'Failed to create order';

        let errMsgLower = errMsg.toLowerCase();

        if (errMsgLower.startsWith('incorrect total') || errMsgLower.startsWith('invalid input') || errMsgLower.startsWith('invalid sellerbizid') || errMsgLower.startsWith('incorrect redeempoints')) {
          errMsg = 'Failed to create order';
        }

        lokoAlert(errMsg);

        Logger.log('ORDER ERROR', {
          errorShownToUser: errMsg,
          error: err
        });
      } else {
        lokoAlert('Failed to create order');

        Logger.log('ORDER ERROR', {
          errorShownToUser: 'Failed to create order',
          error: err
        });
      }
    }
  });

  /* ------------- CREATE ORDER REQUEST ---------------- */

  const createOrderRequest = async () => {
    if (!lokobeeUser) {
      lokoAlert('Please login to continue.');
      return null;
    }

    let email = null;

    if (state.user && state.user.email) {
      email = state.user.email;
    }

    if (!email) {
      lokoAlert('Email does not exists');
      return null;
    }

    let orderPhoneNumber = null;

    if (state.user && state.user.orderPhoneNumber) {
      orderPhoneNumber = state.user.orderPhoneNumber;
    }

    if (!orderPhoneNumber) {
      lokoAlert('Phone number does not exists');
      return null;
    }

    if (groceryCartItems && groceryCartItems.length > 0) {
      const {
        grocery_tip,
        grocery_finalPrice,
        grocery_deliveryTime,
        grocery_orderNote,
        grocery_deliveryAddress,
        grocery_deliveryNote,
        grocery_orderType,
        grocery_serviceFee,
        grocery_couponToken,
        grocery_doordashQuoteId,
        grocery_dropOffType,
        grocery_apartmentNumber
      } = state;

      if (!grocery_finalPrice) {
        lokoAlert('Total price cannot be null');
        return null;
      }

      const cartItems: GroceryOrderLineItemInput[] = [];

      groceryCartItems.forEach((item) => {
        cartItems.push({
          itemId: item.item.id,
          categoryId: item.item.categoryId,
          count: item.quantity
        });
      });

      groceryCartRewardItems.forEach((item) => {
        cartItems.push({
          itemId: item.item.id,
          categoryId: item.item.categoryId,
          count: item.quantity,
          isReward: true
        });
      });

      const createOrderInput: CreateGroceryOrderInput = {
        type: grocery_orderType ? grocery_orderType : GroceryOrderType.Takeout,
        sellerStoreId: groceryStore.id || '',
        items: cartItems,
        note: grocery_orderNote.trim() !== '' ? grocery_orderNote : null,
        expectTime: grocery_deliveryTime,
        paymentType: PaymentType.OnlineStripe,
        tip: parseInt(grocery_tip.valueOf()),
        lokobeeFee: groceryStore?.lokobeeFee ? parseInt(grocery_serviceFee?.valueOf() || '0') : null,
        total: parseInt(grocery_finalPrice.valueOf()),
        email,
        orderPhoneNumber,
        smsNotification: true,
        couponToken: grocery_couponToken,
        isAlcohol: false,
        isDiscounted: false,
        redeemPoints: redeemedPoints
      };

      if (grocery_orderType === GroceryOrderType.Delivery) {
        createOrderInput.deliveryAddress = grocery_deliveryAddress;
        createOrderInput.deliveryNote = grocery_deliveryNote;
        createOrderInput.deliveryProvider = groceryStore.deliveryProvider;
        createOrderInput.deliveryMode = groceryStore.deliveryMode;

        if (groceryStore?.deliveryProvider === GroceryDeliveryProviders.DoordashClassic) {
          createOrderInput.doordashQuoteId = grocery_doordashQuoteId;
          createOrderInput.dropOffInstruction = grocery_dropOffType;
          createOrderInput.apartmentNumber = grocery_apartmentNumber;
        }
      }

      const response = await createOrder({
        variables: {
          input: createOrderInput
        }
      });

      if (response && response.data) {
        const {
          data: {
            createGroceryOrder: { clientSecret }
          }
        } = response;

        return { response, clientSecret };
      } else {
        const { errors } = response || {};

        if (errors && errors.length) {
          let errMsg = errors[0].message || 'Failed to create order';

          let errMsgLower = errMsg.toLowerCase();

          if (
            errMsgLower.startsWith('incorrect total') ||
            errMsgLower.startsWith('invalid input') ||
            errMsgLower.startsWith('invalid sellerstoreid') ||
            errMsgLower.startsWith('incorrect redeempoints')
          ) {
            errMsg = 'Failed to create order';
          }

          Logger.log('GROCERY ORDER ERROR', {
            errorShownToUser: errMsg,
            error: errors
          });
          throw new Error(errMsg);
        } else {
          Logger.log('GROCERY ORDER ERROR', {
            errorShownToUser: 'Failed to create order',
            error: errors
          });
          throw new Error('Failed to create order');
        }
      }
    } else {
      Logger.log('GROCERY CART ERROR', state);
      throw new Error('GROCERY Cart error');
    }
  };

  /* ------------- CARD PAYMENT SETUP ERRORS ---------------- */
  const [{ ccNumberError, ccExpiryError, ccCvcError }, setError] = useState<{
    ccNumberError: boolean;
    ccExpiryError: boolean;
    ccCvcError: boolean;
  }>({ ccNumberError: true, ccExpiryError: true, ccCvcError: true });

  // Reference to stripe object.
  const stripe = useStripe();

  const elements = useElements();

  /* ------------- APPLE/GOOGLE PAY SETUP ---------------- */
  useEffect(() => {
    const { grocery_finalPrice } = state;

    if (!grocery_finalPrice) {
      lokoAlert('Total price cannot be null');
      return;
    }

    if (stripe && grocery_finalPrice) {
      let totalAmount: number = parseInt(grocery_finalPrice.valueOf());

      if (!paymentRequest) {
        const pr = stripe.paymentRequest({
          country: 'US',
          currency: 'usd',
          total: {
            label: 'Total',
            amount: totalAmount
          }
        });

        // Check the availability of the Payment Request API.
        pr.canMakePayment().then((result: any) => {
          if (result) {
            setPaymentRequest(pr);
          }
        });
      }
    }
  }, [lokoAlert, paymentRequest, state, stripe]);

  const onCardElementChange = (type: 'ccnumber' | 'ccexpiry' | 'ccCvc') => (event: any) => {
    if (event?.complete) {
      switch (type) {
        case 'ccnumber':
          setError((prev) => ({ ...prev, ccNumberError: false }));
          break;
        case 'ccexpiry':
          setError((prev) => ({ ...prev, ccExpiryError: false }));
          break;
        case 'ccCvc':
          setError((prev) => ({ ...prev, ccCvcError: false }));
      }
    }

    if (event?.error && event.error?.code) {
      switch (type) {
        case 'ccnumber':
          setError((prev) => ({ ...prev, ccNumberError: true }));
          break;
        case 'ccexpiry':
          setError((prev) => ({ ...prev, ccExpiryError: true }));
          break;
        case 'ccCvc':
          setError((prev) => ({ ...prev, ccCvcError: true }));
      }
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!isSubmitBlocked) {
      setSubmitBlocked(true);

      if (!elements || !stripe) {
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);

      if (!cardElement) {
        return;
      }

      setLoading(true);

      try {
        const { response, clientSecret } = (await createOrderRequest()) || {};

        if (response && clientSecret) {
          const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              billing_details: {
                name
              },
              card: cardElement
            }
          });

          if (result.error) {
            Logger.log(`PAYMENT ERROR`, {
              createOrderResponse: response,
              stripeError: result
            });
            throw new Error(result.error.message ? result.error.message : 'Stripe error');
          } else {
            // The PayByCardUI has been processed!

            const { grocery_subtotalAfterDiscount, grocery_subtotal, grocery_isDiscountApplied } = state;

            if (result?.paymentIntent?.status === 'succeeded') {
              history.push(`/grocery/${groceryStore.id}/success`, {
                show: true,
                // restaurantId,
                subtotalAmount: grocery_isDiscountApplied ? grocery_subtotalAfterDiscount.valueOf() : grocery_subtotal.valueOf(),
                // googleReviewLink,
                issueRewardPoint: groceryStore?.issueRewardPoint
              });
            } else {
              Logger.log('PAYMENT INTENT ERROR', {
                createOrderResponse: response,
                stripeError: result
              });

              throw new Error('Payment failed');
            }
          }
        }
      } catch (err) {
        setSubmitBlocked(false);

        setLoading(false);
        // if ((err as any).message !== '') {
        //   lokoAlert((err as any).message);
        // }
      }
    }
  };

  const isDisabled = !stripe || !name || ccNumberError || ccExpiryError || ccCvcError || isSubmitBlocked || isPaused;

  return (
    <>
      {loading && <OrderProcessingScreen />}
      <Box paddingX={1} flex={1} overflow="scroll" className={classes.payment}>
        <ExpansionPanel className={classes.expansionPanel} expanded={cardExpanded} onClick={() => setCardExpanded(!cardExpanded)}>
          <ExpansionPanelSummary className={classes.expansionPanelSummary} expandIcon={<ExpandMore />} aria-controls="card-content" id="payment-content-card">
            <Box display="flex" justifyContent="space-around">
              <CreditCard />
              <Box marginX={1}>
                <Typography>Credit/Debit Card</Typography>
              </Box>
            </Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails} onClick={(event) => event.stopPropagation()}>
            <form className={classes.form} onSubmit={handleSubmit}>
              <Box display="flex" flex={1} paddingY={2} flexDirection="column" overflow="auto">
                <Box marginBottom={2}>
                  <TextField value={name} label="Name on card" onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setName(e.target.value)} fullWidth={true} variant="outlined" />
                </Box>
                <Box marginBottom={2}>
                  <TextField
                    label="Credit Card Number"
                    name="ccnumber"
                    variant="outlined"
                    required={true}
                    fullWidth={true}
                    onChange={onCardElementChange('ccnumber')}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: StripeInput,
                      inputProps: {
                        component: CardNumberElement
                      }
                    }}
                  />
                </Box>
                <Box display="flex">
                  <Box marginRight={1} flex={1}>
                    <TextField
                      label="Expiry date"
                      name="ccexpiry"
                      variant="outlined"
                      required={true}
                      onChange={onCardElementChange('ccexpiry')}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputComponent: StripeInput,
                        inputProps: {
                          component: CardExpiryElement
                        }
                      }}
                    />
                  </Box>
                  <Box flex={1}>
                    <TextField
                      label="CVC"
                      name="ccexpiry"
                      variant="outlined"
                      onChange={onCardElementChange('ccCvc')}
                      required={true}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputComponent: StripeInput,
                        inputProps: {
                          component: CardCvcElement
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Button type="submit" variant="contained" color="primary" fullWidth={true} disabled={isDisabled} startIcon={<CreditCard />}>
                  buy with card
                </Button>
              </Box>
            </form>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {paymentRequest && stripe && <PayByRequestButton paymentRequest={paymentRequest} groceryStoreId={groceryStore.id} createOrderRequest={createOrderRequest} stripe={stripe} />}
        <Box paddingY={2} display="flex" justifyContent="center">
          <a target="_blank" rel="noopener noreferrer" href="https://stripe.com/docs/security/stripe">
            <img src={SecurePayments} alt="Secure Payments" />
          </a>
        </Box>
        <Box paddingY={1}>
          <ContactDetails />
          <CheckoutSummary
            storeName={groceryStore?.name || ''}
            storeAddress={groceryStore?.address}
            orderType={grocery_orderType || GroceryOrderType.Takeout}
            deliveryAddress={grocery_deliveryAddress}
            eta={grocery_deliveryTime}
          />
        </Box>
      </Box>
    </>
  );
};

export default PayByCardUI;

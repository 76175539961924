import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PayByCardUI from './PayByCardUI';
import { Typography, Box } from '@material-ui/core';
import ErrorBoundary from 'components/ErrorBoundary';
import GroceryStoreModel from 'models/GroceryStore';
import { GroceryStoreCartItem } from 'generated/types';

interface IProps {
  groceryStore: GroceryStoreModel;
  groceryCartItems: GroceryStoreCartItem[];
  groceryCartRewardItems: GroceryStoreCartItem[];
  redeemedPoints: number;
}

const PayByCard = ({ groceryStore, groceryCartItems, groceryCartRewardItems, redeemedPoints }: IProps) => {
  const [stripeObject, setStripeObject] = useState<any>(null);

  const stripeAccount = groceryStore.stripeAccount;

  // This function will re-run if the accountId prop changes.
  useEffect(() => {
    const fetchStripeObject = async () => {
      // If there is no accountId, do not run the loadStripe function.
      if (stripeAccount) {
        const res = await loadStripe(process.env.REACT_APP_STRIPE_KEY!, {
          stripeAccount: stripeAccount
        });
        // When we have got the Stripe object, pass it into our useState.
        setStripeObject(res);
      }
    };
    fetchStripeObject();
  }, [stripeAccount]);

  if (stripeAccount) {
    return (
      <ErrorBoundary>
        <Elements stripe={stripeObject} key={stripeAccount}>
          <PayByCardUI groceryStore={groceryStore} groceryCartItems={groceryCartItems} groceryCartRewardItems={groceryCartRewardItems} redeemedPoints={redeemedPoints} />
        </Elements>
      </ErrorBoundary>
    );
  }

  return (
    <Box textAlign="center">
      <Typography variant="body1" color="error">
        Online Payment Not Available
      </Typography>
    </Box>
  );
};

export default PayByCard;

import React from 'react';
import { Box, Typography, makeStyles, Theme } from '@material-ui/core';
import useGroceryStorePause from 'hooks/groceryStore/useGroceryStorePause';
import GroceryStoreModel from 'models/GroceryStore';

interface IProps {
  groceryStore: GroceryStoreModel | null;
}

const GroceryStorePause = ({ groceryStore }: IProps) => {
  const classes = makeStyles((theme: Theme) => ({
    text: {
      fontWeight: 600
    }
  }))();

  const { pauseData, deliveryPause, takeOutPause } = useGroceryStorePause({ groceryStore });

  if (pauseData) {
    const orderTypes = [];

    if (takeOutPause) orderTypes.push('TAKEOUT');

    if (deliveryPause) orderTypes.push('DELIVERY');

    if (orderTypes.length) {
      return (
        <Box padding={1}>
          <Typography variant="body1" color="error" align="justify" className={classes.text}>
            NOT TAKING ORDERS FOR {orderTypes.join(', ')} FOR TODAY. FUTURE ORDERS CAN BE PLACED.
          </Typography>
        </Box>
      );
    }
  }
  return null;
};

export default GroceryStorePause;

import React, { useContext, useEffect, useState } from 'react';
import DialogHeader from 'components/DialogHeader';
import { AuthContext } from 'fbase/authContext';
import { useHistory, useParams } from 'react-router-dom';
import { PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import { useGroceryGetOrderQuery } from 'graphql/hooks';
import moment from 'moment-timezone';
import { Box, Button, StepLabel, Stepper, Typography, useTheme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { GroceryDoordashDeliveryStatus, GroceryOrderStatus } from 'generated/types';
import { IDeliveryEvent, IEventState, MapStatusToNumber } from './types';
import { CustomConnectorStyle, CustomStepIcon } from './customStepper';
import { orderTrackingCognitoConfig } from 'util/configureAmplifyCognito';

const defaultTimezone = 'us/pacific';

PubSub.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: process.env.REACT_APP_AWS_PUBSUB_REGION,
    aws_pubsub_endpoint: process.env.REACT_APP_AWS_PUBSUB_ENDPOINT
  })
);

const GroceryOrderTracking = () => {
  const history = useHistory();

  const { id } = useParams<any>();

  const { currentUser } = useContext(AuthContext);

  const buyerId = currentUser?.uid;

  const { order, error, loading } = useGroceryGetOrderQuery(id, buyerId);

  const [orderStatus, setOrderStatus] = useState(0);

  const [sellerTimezone, setSellerTimezone] = useState<string>(defaultTimezone);

  const theme = useTheme();

  const [eventState, setEventState] = useState<IEventState>({
    type: GroceryDoordashDeliveryStatus.Scheduled,
    isCancelled: false,
    reason: '',
    eventTime: ''
  });

  const [timeStamps, setTimeStamps] = useState({
    accepted: '',
    tripCreated: '',
    inProgress: '',
    completed: '',
    assigned: '',
    picked_up: '',
    delivered: ''
  });

  const getDeliveryTimeStamp = (status: GroceryOrderStatus) => {
    if (!loading && !error && order && order.timeLines) {
      const timeline = order.timeLines.filter((line) => line?.status === status);
      if (timeline[0]) {
        let createdAtTime = timeline[0].createdAt;
        let convertedTime = moment.tz(createdAtTime, sellerTimezone).format('LT');
        return convertedTime;
      } else {
        return null;
      }
    }
  };

  const getDoordashDeliveryTimeStamp = (status: GroceryDoordashDeliveryStatus) => {
    if (!loading && !error && order && order.doordashDeliveryTimeline) {
      const timeline = order.doordashDeliveryTimeline.filter((line) => line?.status === status);
      if (timeline[0]) {
        let createdAtTime = timeline[0].createdAt;
        let convertedTime = moment.tz(createdAtTime, sellerTimezone).format('LT');
        return convertedTime;
      } else {
        return null;
      }
    }
  };

  /*--------------------- USEEFFECTs --------------------------- */

  useEffect(() => {
    orderTrackingCognitoConfig();
  }, []);

  //setting seller timezone
  useEffect(() => {
    if (order) {
      const { seller } = order;
      const timezone = seller ? seller.timezone || 'us/pacific' : 'us/pacific';
      setSellerTimezone(timezone);
    }
  }, [order]);

  useEffect(() => {
    //Subscribe to topic (orderId)

    const sub = PubSub.subscribe(`grocery/${id}`).subscribe({
      next: (data: any) => {
        if (data && data.value && data.value.deliveryEvent) {
          const event: IDeliveryEvent = { ...data.value.deliveryEvent };

          const deliveryStatus = event.deliveryStatusDoordashClassic;

          let eventTime = event.created_on;

          const eventTimeStr = moment.tz(eventTime, sellerTimezone).format('LT');

          setOrderStatus(MapStatusToNumber[deliveryStatus]);

          switch (deliveryStatus) {
            case GroceryDoordashDeliveryStatus.Cancelled:
              setEventState({ isCancelled: true, type: GroceryDoordashDeliveryStatus.Cancelled, reason: event.cancelledReason ? event.cancelledReason : null, eventTime: eventTimeStr });
              break;

            case GroceryOrderStatus.Accepted.toLowerCase():
              setTimeStamps((prevState) => ({
                ...prevState,
                accepted: eventTimeStr
              }));
              break;

            case GroceryDoordashDeliveryStatus.Assigned:
              setTimeStamps((prevState) => ({
                ...prevState,
                assigned: eventTimeStr
              }));
              break;
            case GroceryDoordashDeliveryStatus.PickedUp:
              setTimeStamps((prevState) => ({
                ...prevState,
                picked_up: eventTimeStr
              }));
              break;
            case GroceryDoordashDeliveryStatus.Delivered:
              setTimeStamps((prevState) => ({
                ...prevState,
                delivered: eventTimeStr
              }));
              break;

            default:
              break;
          }
        }
      },
      error: (error: unknown) => console.error(error),
      complete: () => console.log('Order complete')
    });

    return () => {
      sub.unsubscribe();
    };
  }, [id, sellerTimezone]);

  /*--------------------- UI METHODS --------------------------- */

  const displayKeyValuePair = (key: string, value: string) => (
    <Box display="flex" flexDirection="row" justifyContent="flex-start">
      <Typography style={{ fontWeight: 'bolder' }} variant="caption">
        {key}
      </Typography>
      <Box padding={0.5} />
      <Typography variant="body2">{value}</Typography>
    </Box>
  );

  const orderInfo = () => {
    if (order && !error) {
      const { orderNumber, seller, expectTime } = order;

      const timezone = seller ? seller.timezone || 'us/pacific' : 'us/pacific';

      const expectTimeMoment = moment.tz(expectTime, timezone);

      const expectTimeString = `${expectTimeMoment.format('hh:mm A')}`;

      return (
        <Box bgcolor={grey[200]} display="flex" flexDirection="column" padding={2}>
          <Box borderRadius={5} paddingY={2} width="100%" bgcolor={grey[50]}>
            <Box paddingX={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              {seller && <Typography>{seller.name}</Typography>}
              {displayKeyValuePair('Arriving at:', expectTimeString)}
            </Box>

            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Box paddingX={2}>
                <Box padding={1} />
                {orderNumber && displayKeyValuePair('Order:', orderNumber)}
                {seller && seller.phone && displayKeyValuePair('Phone:', seller.phone)}
              </Box>
              <Box paddingX={2}>
                <Button onClick={onReceiptView} color="primary" variant="contained" size="small" disableElevation={true}>
                  Receipt View
                </Button>
              </Box>
            </Box>
          </Box>

          {eventState.isCancelled && (
            <Box borderRadius={5} paddingY={2} width="100%" bgcolor={theme.palette.error.main} marginTop={2}>
              <Box paddingX={2} color="#FFF" display="flex" flexDirection="column" alignItems="flex-start" justifyContent="space-between">
                <Typography style={{ fontWeight: 'bolder' }}>Delivery has been cancelled {eventState.reason ? eventState.reason : ''}</Typography>
              </Box>
            </Box>
          )}
        </Box>
      );
    }

    return null;
  };

  const onBack = () => {
    history.replace('/orders', {
      from: `/orders/grocery/${id}/tracking`
    });
  };

  const onReceiptView = () => {
    history.replace(`/orders/grocery/${id}`, {
      from: `/orders/grocery/${id}/tracking`
    });
  };

  return (
    <>
      <DialogHeader text="Order Tracking" onBack={onBack} />
      {orderInfo()}
      <Box paddingX={1}>
        <Box paddingX={1} paddingTop={2}>
          <Typography variant="h5">Order Status</Typography>
        </Box>
        <Box style={{ background: '#fff' }}>
          <Stepper style={{ maxWidth: 'fit-content' }} activeStep={orderStatus} orientation="vertical" connector={<CustomConnectorStyle />}>
            <StepLabel StepIconComponent={CustomStepIcon}>
              <Box paddingLeft={2}>
                <Typography variant="caption">Order placed successfully</Typography>
                <br />
                <Typography variant="body2">{getDeliveryTimeStamp(GroceryOrderStatus.Placed)}</Typography>
              </Box>
            </StepLabel>

            <StepLabel StepIconComponent={CustomStepIcon}>
              <Box paddingLeft={2}>
                <Typography variant="caption">Order confirmed</Typography>
                <br />

                <Typography variant="body2">{getDeliveryTimeStamp(GroceryOrderStatus.Accepted) ? getDeliveryTimeStamp(GroceryOrderStatus.Accepted) : timeStamps['accepted']}</Typography>
              </Box>
            </StepLabel>

            {!eventState.isCancelled && (
              <StepLabel StepIconComponent={CustomStepIcon}>
                <Box paddingLeft={2}>
                  <Typography variant="caption">Your order is assigned to a dasher</Typography>
                  <br />

                  <Typography variant="body2">
                    {/* First we check if order have given timeline then we fetch timestamp from order data  */}
                    {/* Else we check event data for timestamp  */}
                    {getDoordashDeliveryTimeStamp(GroceryDoordashDeliveryStatus.Assigned) ? getDoordashDeliveryTimeStamp(GroceryDoordashDeliveryStatus.Assigned) : timeStamps['assigned']}
                  </Typography>
                </Box>
              </StepLabel>
            )}

            {!eventState.isCancelled && (
              <StepLabel StepIconComponent={CustomStepIcon}>
                <Box paddingLeft={2}>
                  <Typography variant="caption">The dasher is enroute for delivery</Typography>
                  <br />
                  <Typography variant="body2">
                    {getDoordashDeliveryTimeStamp(GroceryDoordashDeliveryStatus.PickedUp) ? getDoordashDeliveryTimeStamp(GroceryDoordashDeliveryStatus.PickedUp) : timeStamps['picked_up']}
                  </Typography>
                </Box>
              </StepLabel>
            )}

            {!eventState.isCancelled && (
              <StepLabel StepIconComponent={CustomStepIcon}>
                <Box paddingLeft={2}>
                  <Typography variant="caption">Order delivered successfully</Typography>
                  <br />
                  <Typography variant="body2">
                    {getDoordashDeliveryTimeStamp(GroceryDoordashDeliveryStatus.Delivered) ? getDoordashDeliveryTimeStamp(GroceryDoordashDeliveryStatus.Delivered) : timeStamps['delivered']}
                  </Typography>
                </Box>
              </StepLabel>
            )}

            {eventState.isCancelled && (
              <StepLabel error={true} StepIconComponent={CustomStepIcon}>
                <Box paddingLeft={2}>
                  <Typography variant="caption">Sorry Delivery was cancelled</Typography>
                  <br />
                  <Typography variant="body2">{eventState.eventTime}</Typography>
                </Box>
              </StepLabel>
            )}
          </Stepper>
        </Box>
      </Box>
    </>
  );
};

export default GroceryOrderTracking;

import React from 'react';
import { Box, Typography, Grid, useMediaQuery } from '@material-ui/core';
import Moment from 'moment';
import { join } from 'lodash';
import { useHistory } from 'react-router-dom';
import { GroceryCoupon } from 'generated/types';
import { useStyles } from './style';

interface IProps {
  coupons: GroceryCoupon[];
  storeId?: string;
  clickableCoupons: boolean;
}

export const mapTypes: any = {
  TAKEOUT: 'Takeout',
  DELIVERY: 'Delivery'
};

const GroceryCoupons = ({ coupons, storeId, clickableCoupons }: IProps) => {
  const classes = useStyles();

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  const history = useHistory();

  const onClick = () => {
    storeId && history.push(`/grocery/${storeId}`);
  };

  return (
    <Grid container={true} alignItems="stretch" spacing={1}>
      {coupons.map((coupon, index) => {
        let weekDays = coupon.dayOfWeek?.map((day) => {
          return Moment()
            .day(day as number)
            .format('ddd');
        });

        let orderTypes: string[] = [];
        if (coupon.orderType && coupon.orderType.length) {
          coupon.orderType.forEach((order) => {
            if (order && !['ALL'].includes(order)) {
              orderTypes.push(mapTypes[order]);
            }
          });
        }

        return (
          <Grid item={true} xs={12} sm={6} md={6} lg={4} zeroMinWidth={true} key={index}>
            <Box className={`${classes.couponContainer} ${clickableCoupons ? classes.clickableCoupons : undefined}`} minHeight={isDesktop ? 135 : 0} padding={1} onClick={onClick}>
              <Box paddingBottom={0.5}>
                <Typography variant="h6">{coupon.name}</Typography>
              </Box>
              <Box paddingBottom={1}>
                <Typography variant="body1">{coupon.description}</Typography>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <Box display="flex" flexDirection="column">
                  {orderTypes.length === 7 && (
                    <Box display="flex" alignItems="center">
                      <Typography variant="caption">Valid Days:&nbsp;</Typography>
                      <Typography variant="body1">{join(weekDays, ', ')}</Typography>
                    </Box>
                  )}

                  <Box display="flex" alignItems="center">
                    <Typography variant="caption">Expires:&nbsp;</Typography>
                    <Typography variant="body1">{Moment(coupon.endDate).format('MMM/DD/yyyy')}</Typography>
                  </Box>
                </Box>
                <Box className={classes.couponCode} paddingLeft={1}>
                  <Typography variant="body1">{coupon.code}</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default GroceryCoupons;

import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: any) => ({
  tabIndicator: {
    display: 'none'
  },
  tabsContainer: {
    minHeight: 'auto'
  },
  customTab: {
    backgroundColor: grey[300],
    borderRadius: '6px',
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1, 1),

    textTransform: 'capitalize',
    minHeight: 'auto',
    lineHeight: 1
  },
  selectedTab: {
    backgroundColor: theme.palette.primary.main
  }
}));

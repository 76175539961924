import React, { useState } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { Stepper } from '@lokobee/lokobee-ui';
import GroceryStoreItemModal from 'models/GroceryItem';

import { convertPriceTo } from 'util/number';
import useGroceryReward from 'hooks/groceryStore/useGroceryReward';
import { useAlert } from 'hooks';

interface IProps {
  groceryStoreItem: GroceryStoreItemModal | null;
  currency?: 'USD' | 'POINT';
  quantity: number;
  onQuantityChange: (qty: number) => Promise<void>;
  handleClose: () => void;
}

const ItemActions: React.FC<IProps> = ({ groceryStoreItem, quantity, onQuantityChange, handleClose, currency }) => {
  const [qty, setQty] = useState(quantity ? quantity : 1);

  const { remainingPoints, isAddPossible } = useGroceryReward();

  const { lokoAlert } = useAlert();

  const onQtyChange = (val: number) => {
    if (currency === 'POINT') {
      const { isPossible, message } = isAddPossible({
        itemQty: val,
        itemRewardPoints: groceryStoreItem?.rewardPoints || 0,
        itemTitle: groceryStoreItem?.title || '',
        remainingPoints
      });

      if (isPossible) {
        setQty(val);
      } else {
        lokoAlert(message);
      }
    } else {
      setQty(val);
    }
  };

  if (groceryStoreItem) {
    const price = currency === 'POINT' ? `${(groceryStoreItem.rewardPoints || 0) * qty} PTS` : `$${convertPriceTo(groceryStoreItem.price * qty, 'DOLLAR')}`;

    return (
      <Box paddingX={2} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="body1">{price}</Typography>
          <Stepper value={qty} onChange={(val: number) => onQtyChange(val)} />
        </Box>

        <Button
          variant="contained"
          color="primary"
          fullWidth={true}
          onClick={async () => {
            if (qty !== quantity) {
              await onQuantityChange(qty);
            }
            handleClose();
          }}
          disableElevation={true}>
          Add to cart
        </Button>
      </Box>
    );
  }

  return null;
};

export default ItemActions;

import { useContext } from 'react';
import { IPauseDetails, defaultTimeZone } from 'generated/custom';
import { FirebaseContext } from 'fbase';
import moment from 'moment-timezone';
import GroceryStoreModel from 'models/GroceryStore';
import { useObject } from 'react-firebase-hooks/database';

interface IProps {
  groceryStore: GroceryStoreModel | null;
}

export default ({ groceryStore }: IProps) => {
  const { firebase } = useContext(FirebaseContext);

  const [snapshot] = useObject(firebase?.getRealtimeDB().ref(`/groceryOrderPause/${groceryStore?.getId}/`));

  const pauseData: IPauseDetails | null | undefined = snapshot?.val();

  const now = moment.tz(groceryStore?.getTimezone || defaultTimeZone).format('YYYY-MM-DD');

  const takeOutPause = pauseData && pauseData.TAKEOUT ? pauseData.TAKEOUT?.status === 'pause' && now === pauseData.TAKEOUT?.pauseDate : false;

  const deliveryPause = pauseData && pauseData.DELIVERY ? pauseData.DELIVERY?.status === 'pause' && now === pauseData.DELIVERY?.pauseDate : false;

  return {
    pauseData,
    takeOutPause,
    deliveryPause
  };
};

import { gql } from 'apollo-boost';
import { CryptoCoupon } from 'graphql/fragments/cryptoCoupon.fragments';

export const getCryptoCouponsByBizId = gql`
  query getCryptoCouponsByBizId($input: GetCryptoCouponsByBizIdInput!) {
    getCryptoCouponsByBizId(input: $input) {
      ...CryptoCoupon
    }
  }
  ${CryptoCoupon}
`;

export const getCouponByCouponId = gql`
  query getCouponByCouponId($input: GetCouponByCouponIdInput!) {
    getCouponByCouponId(input: $input) {
      ...CryptoCoupon
    }
  }
  ${CryptoCoupon}
`;

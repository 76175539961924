import { Box, Button, Divider, Grid, Paper, Typography } from '@material-ui/core';
import useActiveGroceryStore from 'hooks/groceryStore/useActiveGroceryStore';
import React, { useContext, useEffect, useState } from 'react';
import { useStyles } from './style';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { getLocalStorage, removeLocalStorage } from 'util/storage';
import PointsIcon from 'assets/img/points_icon.svg';
import { AuthContext } from 'fbase/authContext';

const OrderSuccessPage = () => {
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const paymentSuccess = query.get('success');

  const classes = useStyles();

  const { groceryStoreId } = useActiveGroceryStore();

  const { currentUser } = useContext(AuthContext);

  const history = useHistory();

  const [totalPoints, setTotalPoints] = useState<null | string>(null);

  const [showSuccessPage, setShowSuccessPage] = useState(true);

  const onBack = () => {
    history.push(`/grocery/${groceryStoreId}`);
  };

  useEffect(() => {
    const orderState = JSON.parse(getLocalStorage('orderState') || '{}');

    if (orderState.show !== true) {
      setShowSuccessPage(false);
    }

    if (orderState.subtotalAmount && orderState.issueRewardPoint) {
      const subtotal = orderState.subtotalAmount;

      const pointsEarned = Math.round(parseInt(subtotal) / 100);

      setTotalPoints(pointsEarned.toString());
    }

    removeLocalStorage('orderState');
  }, []);

  if (paymentSuccess !== 'true') {
    return <Redirect to="/" />;
  }

  if (!showSuccessPage) {
    return <Redirect to="/" />;
  }

  return (
    <Box height="100%" display="flex" bgcolor="#fff" flexDirection="column">
      <Box flex={1} display="flex" justifyContent="center" flexDirection="column" alignItems="center" paddingX={2}>
        <Paper elevation={8} className={classes.surface}>
          <Box className={classes.container}>
            <Box>
              <Box width="100%" textAlign="center" paddingTop={1}>
                <CheckCircleOutlineIcon className={classes.checkIcon} />
              </Box>
              <Box width="100%" textAlign="center" paddingTop={1}>
                <Typography variant="h6">Thank you</Typography>
              </Box>
              <Box width="100%" textAlign="center" paddingTop={1}>
                <Typography variant="h6">Order Placed Successfully</Typography>
              </Box>
              <Box width="100%" textAlign="center" paddingTop={1}>
                <Typography className={classes.surfaceText} variant="subtitle1">
                  We will keep you posted about the status of the order via SMS and Email.
                </Typography>
              </Box>
            </Box>

            {totalPoints && currentUser && !currentUser.isAnonymous && (
              <Box>
                <Box paddingY={2}>
                  <Divider variant="middle" />
                </Box>
                <Box width="100%" textAlign="center" paddingTop={1}>
                  <img src={PointsIcon} alt="" />
                </Box>
                <Box width="100%" textAlign="center" paddingTop={1}>
                  <Typography className={classes.surfaceText} variant="subtitle1">
                    You have earned <span className={classes.boldText}>{totalPoints}</span> reward points.
                  </Typography>
                </Box>
                <Box width="100%" textAlign="center" paddingTop={1}>
                  <Typography className={classes.boldText} variant="body2">
                    Points will be available for redeem in 24 hours.
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>

      <Grid container justify="center">
        <Grid item xs={12} sm={8} md={6} lg={5}>
          <Box margin={2}>
            <Button onClick={onBack} variant="contained" color="primary" fullWidth={true} disableElevation={true}>
              Go Back
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderSuccessPage;

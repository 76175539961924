import gql from 'graphql-tag';

export const verifyCouponQuery = gql`
  query verifyCoupon($input: VerifyCouponInput) {
    verifyCoupon(input: $input) {
      success
      error
      coupon {
        id
        discountType
        discountValue
        taxMethod
        code
        subtotalThreshold
        maxDiscount
        isPrivate
        orderType
        dayOfWeek
        isSingleUse
        startDate
        endDate
      }
      token
    }
  }
`;

export const verifyGroceryCouponQuery = gql`
  query verifyGroceryCouponQuery($input: VerifyGroceryCouponInput) {
    verifyGroceryCoupon(input: $input) {
      success
      error
      coupon {
        id
        discountType
        discountValue
        code
        subtotalThreshold
        maxDiscount
        isPrivate
        orderType
        dayOfWeek
        startDate
        endDate
      }
      token
    }
  }
`;

import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    pointsBanner: {
      background: theme.palette.primary.main,
      padding: theme.spacing(2)
    },
    bold: {
      fontWeight: 'bold'
    },
    loginBtn: {
      cursor: 'pointer',
      fontWeight: 'bold'
    }
  };
});

import React, { useEffect } from 'react';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { Box, Typography, Divider, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Logger from 'util/logger';
import { useAlert } from 'hooks';
import { PaymentRequest, Stripe } from '@stripe/stripe-js';
import { isEmpty } from 'lodash';

interface IProps {
  paymentRequest: PaymentRequest;
  createOrderRequest: () => Promise<{
    response: any;
    clientSecret: any;
  } | null>;
  groceryStoreId: string;
  stripe: Stripe;
}

const PayByRequestButton = ({ paymentRequest, createOrderRequest, groceryStoreId, stripe }: IProps) => {
  const history = useHistory();

  const { lokoAlert } = useAlert();
  /**
   * Capturing payment for apple/google pay
   */
  useEffect(() => {
    if (stripe && paymentRequest) {
      paymentRequest.on('paymentmethod', async (ev: any) => {
        try {
        } catch (err) {
          if ((err as any).message !== '') {
            lokoAlert((err as any).message);
          }
        }

        const { response, clientSecret } = (await createOrderRequest()) || {};

        if (!isEmpty(response) && clientSecret) {
          // Confirm the PaymentIntent without handling potential next actions (yet).
          const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(clientSecret, { payment_method: ev.paymentMethod.id }, { handleActions: false });
          if (confirmError) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            ev.complete('fail');
            Logger.log(`[PAYMENT ERROR]`, {
              requires_action: false,
              stripeError: confirmError
            });
            lokoAlert(confirmError.message ? confirmError.message : 'Stripe error');
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete('success');
            // Check if the PaymentIntent requires any actions and if so let Stripe.js
            // handle the flow. If using an API version older than "2019-02-11"
            // instead check for: `paymentIntent.status === "requires_source_action"`.
            if (paymentIntent && paymentIntent.status === 'requires_action') {
              // Let Stripe.js handle the rest of the payment flow.
              const { error: confirmError2 } = await stripe.confirmCardPayment(clientSecret);
              if (confirmError2) {
                // The payment failed -- ask your customer for a new payment method.
                Logger.log(`[PAYMENT ERROR]`, {
                  requires_action: true,
                  stripeError: confirmError2
                });

                lokoAlert(confirmError2.message ? confirmError2.message : 'Stripe error');
              } else {
                // The payment has succeeded.
                history.push(`/grocery/${groceryStoreId}/success`, {
                  show: true
                  // restaurantId,
                  // subtotalAmount,
                  // googleReviewLink,
                  // issueRewardPoint
                });
              }
            } else {
              history.push('/success', {
                show: true
                // restaurantId,
                // subtotalAmount,
                // googleReviewLink,
                // issueRewardPoint
              });
            }
          }
        } else {
          //Failed due to Order Error
          ev.complete('fail');
        }
      });
    }
  }, [createOrderRequest, groceryStoreId, history, lokoAlert, paymentRequest, stripe]);

  return (
    <Box paddingY={2}>
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={5}>
          <Divider />
        </Grid>
        <Grid item xs={2}>
          <Box textAlign="center" fontWeight="500">
            <Typography variant="h6">OR</Typography>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Divider />
        </Grid>
      </Grid>

      <PaymentRequestButtonElement
        options={{
          paymentRequest,
          style: {
            paymentRequestButton: {
              type: 'buy'
              // One of 'default', 'book', 'buy', or 'donate'
              // Defaults to 'default'
            }
          }
        }}
      />
    </Box>
  );
};

export default PayByRequestButton;

import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '16px 32px'
  },
  checkIcon: {
    fontSize: '50px',
    color: theme.palette.success.main
  },
  boldText: {
    fontWeight: 'bold'
  },
  surface: {
    borderRadius: '8px'
  },
  surfaceText: {
    lineHeight: '1.2'
  },
  googleReviewButton: {
    textTransform: 'none'
  }
}));

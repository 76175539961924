import { Badge, Box, Button, Grid, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import GroceryItemCard from 'components/GroceryItemCard';
import GroceryItemDialog from 'components/GroceryItemDialog';
import GroceryItemsSearch from 'components/GroceryItemsSearch';
import { AuthContext } from 'fbase/authContext';
import { useAddToGroceryStoreCart, useGetGroceryStoreById, useGetGroceryStoreCart, useQueryGrocerySearch } from 'graphql/hooks';
import useActiveGroceryStore from 'hooks/groceryStore/useActiveGroceryStore';
import { filter, groupBy } from 'lodash';
import GroceryStoreItemModal from 'models/GroceryItem';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'store';
import BrandFilter from '../GroceryItems/BrandFilter';
import { useStyles } from './style';
import CancelIcon from '@material-ui/icons/Cancel';
import CategoryTabs, { ICategoryTab } from './CategoryTabs';
import { ArrowBack } from '@material-ui/icons';

const itemCount = 25;

const GrocerySearch = () => {
  const { querySearch, data } = useQueryGrocerySearch();

  const { groceryStoreId } = useActiveGroceryStore();

  const { groceryStore } = useGetGroceryStoreById(groceryStoreId);

  const { groceryCartItems } = useGetGroceryStoreCart(groceryStoreId);

  const [groceryItems, setGroceryItems] = useState<GroceryStoreItemModal[]>([]);

  const [displayItems, setDisplayItems] = useState<GroceryStoreItemModal[]>([]);

  const [activeItem, setActiveItem] = useState<null | GroceryStoreItemModal>(null);

  const [isItemDialogOpen, setItemDialogOpen] = useState(false);

  const cartItems = groupBy(groceryCartItems, 'item.id');

  const classes = useStyles();

  const history = useHistory();

  const { currentUser } = useContext(AuthContext);

  const { addToGroceryStoreCart } = useAddToGroceryStoreCart(groceryStoreId);

  const snackbar = useSnackbar();

  const { dispatch } = useStore();

  const [defaultValue, setDefaultValue] = useState<string | undefined>(undefined);

  const [brandFilter, setBrandFilter] = useState<string[]>([]);

  const [categoryFilter, setCategoryFilter] = useState<string>('');

  const [openBrandsDialog, setOpenBrandsDialog] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const query = params.get('query');

    if (query && query.trim() !== '') {
      querySearch({
        variables: {
          input: {
            storeId: groceryStoreId,
            query,
            itemCount
          }
        }
      });

      setDefaultValue(query);
    }
  }, [groceryStoreId, querySearch]);

  const callAPI = (query: string) => {
    if (query.trim() !== '') {
      clearFilters();
      querySearch({
        variables: {
          input: {
            storeId: groceryStoreId,
            query,
            itemCount
          }
        }
      });
      history.push(`/grocery/${groceryStoreId}/search?query=${query}`);
    }
  };

  useEffect(() => {
    if (data && data.queryGrocerySearch) {
      setGroceryItems(data.queryGrocerySearch);
    }
  }, [data]);

  const onItemSelect = (groceryItem: GroceryStoreItemModal) => {
    setActiveItem(groceryItem);
    setItemDialogOpen(true);
  };

  const handleDialogClose = () => {
    setItemDialogOpen(false);
  };

  const openLoginDialog = () => {
    dispatch({
      type: 'UI_ACTION_LOGIN_POPUP',
      payload: true
    });
  };

  const onQuantityChange = async (item: GroceryStoreItemModal, qty: number) => {
    if (currentUser) {
      const response = await addToGroceryStoreCart(item, qty, false);

      if (response && response.data && response.data.AddToGroceryStoreCart) {
      } else {
        snackbar.enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    } else {
      openLoginDialog();
    }
  };

  useEffect(() => {
    let itemsList = groceryItems;

    if (categoryFilter !== '' || brandFilter.length > 0) {
      itemsList = filter(groceryItems, (item: GroceryStoreItemModal) => {
        if (categoryFilter !== '' && item.categoryId !== categoryFilter) {
          return false;
        }

        if (brandFilter.length > 0 && !brandFilter.includes(item.brand)) {
          return false;
        }
        return true;
      });
    }

    setDisplayItems(itemsList);
  }, [brandFilter, categoryFilter, groceryItems]);

  const clearFilters = () => {
    setBrandFilter([]);
    setCategoryFilter('');
  };

  const onBack = () => {
    history.push('/grocery/' + groceryStoreId);
  };

  const categoriesFromItems: ICategoryTab[] = [];

  const categoriesGroupedById = groupBy(groceryStore?.categories || [], 'id');

  const categoriesAlreadyPresent: any = {};

  groceryItems.forEach((item) => {
    if (categoriesGroupedById[item.categoryId] && !categoriesAlreadyPresent[item.categoryId]) {
      categoriesFromItems.push({
        id: item.categoryId,
        title: categoriesGroupedById[item.categoryId][0].title || ''
      });

      categoriesAlreadyPresent[item.categoryId] = true;
    }
  });

  return (
    <Box padding={1} className={classes.groceryContainer}>
      <Box paddingY={1} display="flex" alignItems="center">
        <Box display="flex" alignItems="center" justifyContent="center">
          <ArrowBack fontSize="small" className={classes.backIcon} onClick={onBack} />
        </Box>
        <Box>
          <Typography variant="h5">Item search</Typography>
        </Box>
      </Box>
      <Box padding={1} display="flex" alignItems="center">
        <GroceryItemsSearch onSearch={callAPI} defaultValue={defaultValue} />
        <Box paddingX={0.5}>
          <Badge badgeContent={brandFilter.length} color="secondary">
            <Button variant="contained" color="primary" disableElevation={true} onClick={() => setOpenBrandsDialog(true)}>
              Filters
            </Button>
          </Badge>
        </Box>
        <Box paddingX={0.5} display="flex" alignItems="center" justifyContent="center">
          <CancelIcon fontSize="small" className={classes.cancelIcon} onClick={clearFilters} />
        </Box>
      </Box>
      {categoriesFromItems.length !== 0 && <CategoryTabs categories={categoriesFromItems} selectedCategoryId={categoryFilter} onSelect={(newCategory) => setCategoryFilter(newCategory)} />}
      {data && data.queryGrocerySearch && groceryItems.length === 0 ? (
        <Box paddingX={1}>
          <Typography variant="body1" color="secondary">
            No items found for given query.
          </Typography>
        </Box>
      ) : (
        <Box paddingX={2} paddingY={1} bgcolor={grey[50]}>
          <Box className={classes.groceryContainer}>
            <Grid container={true} spacing={2}>
              {displayItems.map((item: GroceryStoreItemModal, index: number) => {
                return (
                  <Grid item={true} onClick={() => onItemSelect(item)} key={item.id} xs={6} sm={4} md={2}>
                    <GroceryItemCard
                      imagePreview={item.images && item.images[0].preview.url}
                      brand={item.brand}
                      price={item.price}
                      title={item.title}
                      size={item.size}
                      quantity={cartItems[item.id] ? cartItems[item.id][0].quantity : 0}
                      onQuantityChange={(qty: number) => onQuantityChange(item, qty)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      )}

      {activeItem && (
        <GroceryItemDialog
          isOpen={isItemDialogOpen}
          handleClose={handleDialogClose}
          groceryStoreItem={activeItem}
          quantity={cartItems[activeItem.id] ? cartItems[activeItem.id][0].quantity : 0}
          onQuantityChange={(qty: number) => onQuantityChange(activeItem, qty)}
        />
      )}

      <BrandFilter open={openBrandsDialog} onClose={() => setOpenBrandsDialog(false)} groceryItemsList={groceryItems} brandFilter={brandFilter} setBrandFilter={setBrandFilter} />
    </Box>
  );
};

export default GrocerySearch;

import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: any) => ({
  deleteBtn: {
    margin: theme.spacing(0, 1),
    padding: theme.spacing(0.5),
    color: 'white',
    cursor: 'pointer',
    borderRadius: '5px',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  paymentButton: {
    borderRadius: 0,
    flex: '1 1 0'
  },
  loginBtn: {
    cursor: 'pointer',
    fontWeight: 'bold'
  },
  icon: {
    color: grey[500],
    marginRight: 10
  }
}));

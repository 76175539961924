import { makeStyles } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme) => ({
  textField: {
    height: 40
  },
  boldText: {
    fontWeight: 'bolder'
  },
  dialog: {
    '& .MuiPaper-root': {
      height: '100%'
    }
  },
  container: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    backgroundColor: '#fff'
  },

  couponContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(2),
    background: amber[100]
  },
  clickableCoupons: {
    cursor: 'pointer'
  },
  couponCode: {
    borderStyle: 'solid',
    borderColor: 'black',
    borderRadius: '5px',
    borderWidth: '1px',
    color: 'black',
    padding: theme.spacing(1)
  },
  paymentButton: {
    borderRadius: 0,
    flex: '1 1 0'
  },
  loader: {
    color: '#fff',
    width: '25px',
    height: '25px'
  },
  applyCode: {
    cursor: 'pointer'
  }
}));

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import GroceryStoreHome from './GroceryStoreHome';
import GroceryItems from './GroceryItems';
import useGroceryStore from 'hooks/groceryStore/useGroceryStore';
import { Box } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import GroceryCart from './GroceryCart';
import SelectOrderType from './GroceryCart/OrderType';
import PaymentRoutes from './Payment';
import SuccessPage from './Payment/Success';
import Rewards from './Reward';
import GrocerySearch from './GrocerySearch';
import GroceryDeals from './GroceryDeals';

const GroceryStoreRoutes: React.FC = () => {
  const { isValid } = useGroceryStore();

  if (isValid) {
    return (
      <Box height="100%" bgcolor={grey[50]}>
        <Switch>
          <Route path="/grocery/:groceryStoreId/rewards" component={Rewards} />
          <Route path="/grocery/:groceryStoreId/success" component={SuccessPage} />
          <Route path="/grocery/:groceryStoreId/payment" component={PaymentRoutes} />
          <Route path="/grocery/:groceryStoreId/search" component={GrocerySearch} />
          <Route path="/grocery/:groceryStoreId/deals" component={GroceryDeals} />
          <Route path="/grocery/:groceryStoreId/cart/select-type" component={SelectOrderType} />
          <Route path="/grocery/:groceryStoreId/cart" component={GroceryCart} />
          <Route path="/grocery/:groceryStoreId/category/:categoryId" component={GroceryItems} />
          <Route path="/grocery/:groceryStoreId" component={GroceryStoreHome} />
        </Switch>
      </Box>
    );
  }

  return null;
};

export default GroceryStoreRoutes;

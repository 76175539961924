import React, { useState, useEffect } from 'react';
import { GroceryDeliveryProviders, GroceryOrderType, PaymentType } from 'generated/types';
import useGroceryCart from 'hooks/groceryStore/useGroceryCart';
import { Box, Button, Typography, ButtonGroup } from '@material-ui/core';
import DialogHeader from 'components/DialogHeader';
import { useHistory } from 'react-router-dom';
import { grey } from '@material-ui/core/colors';
import PayByCashUI from './PayByCash/PayByCashUI';
import { convertPriceTo } from 'util/number';
import PayByStripeCheckout from './PayByStripeCheckout';
import { useStore } from 'store';
import GroceryStorePause from 'components/GroceryStorePause';
import PayByCash from './PayByCash';

const Payment = () => {
  const [selectedValue, setSelectedValue] = useState('PAYMENT_METHOD_CARD');

  const {
    state: { grocery_finalPrice, grocery_orderType }
  } = useStore();

  const { groceryStoreId, groceryStore, groceryCartItems, groceryCartRewardItems, redeemedPoints } = useGroceryCart();

  const [onsiteAvailablePaymentTypeIcons, setOnsiteAvailablePaymentTypeIcons] = useState<PaymentType[] | null>([]);

  const [onlineAvailablePaymentTypeIcons, setOnlineAvailablePaymentTypeIcons] = useState<PaymentType[] | null>([]);

  const isOfflineAllowed = grocery_orderType === GroceryOrderType.Takeout || (grocery_orderType === GroceryOrderType.Delivery && groceryStore?.deliveryProvider === GroceryDeliveryProviders.Self);

  const history = useHistory();

  useEffect(() => {
    if (groceryStore && grocery_orderType === GroceryOrderType.Takeout) {
      if (groceryStore?.takeoutPaymentSetting?.onsitePaymentTypes?.length) {
        setOnsiteAvailablePaymentTypeIcons(groceryStore?.takeoutPaymentSetting?.onsitePaymentTypes);
      }

      if (groceryStore?.takeoutPaymentSetting?.onlinePaymentTypes?.length) {
        setOnlineAvailablePaymentTypeIcons(groceryStore?.takeoutPaymentSetting?.onlinePaymentTypes);
      }

      if (groceryStore?.takeoutPaymentSetting?.onsitePaymentTypes?.length && !groceryStore?.takeoutPaymentSetting?.onlinePaymentTypes?.length) {
        setSelectedValue('PAYMENT_METHOD_CASH');
      }
    }

    if (groceryStore && grocery_orderType === 'DELIVERY') {
      if (groceryStore?.deliveryPaymentSetting?.onsitePaymentTypes?.length) {
        setOnsiteAvailablePaymentTypeIcons(groceryStore?.deliveryPaymentSetting?.onsitePaymentTypes);
      }

      if (groceryStore?.deliveryPaymentSetting?.onlinePaymentTypes?.length) {
        setOnlineAvailablePaymentTypeIcons(groceryStore?.deliveryPaymentSetting?.onlinePaymentTypes);
      }

      if (groceryStore?.deliveryPaymentSetting?.onsitePaymentTypes?.length && !groceryStore?.deliveryPaymentSetting?.onlinePaymentTypes?.length) {
        setSelectedValue('PAYMENT_METHOD_CASH');
      }
    }
  }, [groceryStore, grocery_orderType]);

  const onBack = () => {
    history.push(`/grocery/${groceryStoreId}/cart/select-type`);
  };

  return (
    <Box maxWidth={1200} margin="auto" display="flex" flexDirection="column" justifyContent="center" height="100%" bgcolor="#fff">
      <DialogHeader onBack={onBack} text="Select payment method" />
      <Box paddingY={1} flex={1} overflow="scroll" display="flex" flexDirection="column">
        <Box paddingX={1} marginBottom={2}>
          <ButtonGroup fullWidth={true} color="secondary">
            {!!onlineAvailablePaymentTypeIcons && !!onlineAvailablePaymentTypeIcons.length && (
              <Button size="small" disableElevation={true} variant={selectedValue === 'PAYMENT_METHOD_CARD' ? 'contained' : 'outlined'} onClick={() => setSelectedValue('PAYMENT_METHOD_CARD')}>
                Pay Online
              </Button>
            )}
            {!!onsiteAvailablePaymentTypeIcons && !!onsiteAvailablePaymentTypeIcons.length && isOfflineAllowed && (
              <Button size="small" disableElevation={true} variant={selectedValue === 'PAYMENT_METHOD_CASH' ? 'contained' : 'outlined'} onClick={() => setSelectedValue('PAYMENT_METHOD_CASH')}>
                Pay at Store
              </Button>
            )}
          </ButtonGroup>
        </Box>
        <Box paddingY={2} marginX={1} marginBottom={2} bgcolor={grey[200]} borderRadius={5} textAlign="center" fontWeight="bold">
          <Typography variant="body1">PAY AMOUNT:&nbsp;${convertPriceTo(grocery_finalPrice?.valueOf() || 0, 'DOLLAR')}</Typography>
        </Box>
        {selectedValue === 'PAYMENT_METHOD_CASH' && groceryStore && <PayByCashUI groceryStore={groceryStore} onsiteAvailablePaymentTypeIcons={onsiteAvailablePaymentTypeIcons} />}
        {selectedValue === 'PAYMENT_METHOD_CARD' && groceryStore && (
          <PayByStripeCheckout groceryStore={groceryStore} groceryCartItems={groceryCartItems} groceryCartRewardItems={groceryCartRewardItems} redeemedPoints={redeemedPoints} />
        )}
      </Box>
      {groceryStore && <GroceryStorePause groceryStore={groceryStore} />}
      {selectedValue === 'PAYMENT_METHOD_CASH' && isOfflineAllowed && <PayByCash />}
    </Box>
  );
};

export default Payment;

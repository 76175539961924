import { Box, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useStyles } from './style';

interface IProps {
  onSearch: (query: string) => void;
  defaultValue?: string;
}

const GroceryItemsSearch = ({ onSearch, defaultValue }: IProps) => {
  const [query, setQuery] = useState<string>('');

  const classes = useStyles();

  useEffect(() => {
    if (defaultValue) setQuery(defaultValue);
  }, [defaultValue]);

  return (
    <Box paddingY={1} display="flex" alignItems="center">
      <TextField
        variant="outlined"
        size="small"
        fullWidth={true}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        label="Search items"
        onKeyPress={(e: any) => {
          if (e.key === 'Enter' && e.target.value) {
            e.target.blur();
            onSearch(e.target.value);
          }
        }}
      />
      <Box paddingX={0.5} display="flex" alignItems="center" justifyContent="center">
        <SearchIcon
          fontSize="small"
          className={classes.searchIcon}
          onClick={() => {
            if (query.trim() !== '') {
              onSearch(query);
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default GroceryItemsSearch;

import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    fontSize: '10px'
  },
  dishTitle: {
    color: '#fff'
  },
  dishDescription: {
    color: '#fff',
    marginBottom: '0px'
  },
  dishContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    overflow: 'auto',
    backgroundColor: '#fff'
  },
  dishSmallIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    width: '60px',
    backgroundSize: 'cover',
    borderRadius: '5px',
    backgroundColor: '#fff'
  },
  smallIcon: {
    width: '24px'
  },
  alignTitle: {
    display: 'flex',
    // paddingLeft:theme.spacing(2),
    alignItems: 'center'
  },
  ratings: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '0.5rem',
    fontWeight: 400
  },
  rateScore: {
    fontWeight: 700,
    color: '#fff'
  },
  paddingL: {
    paddingLeft: theme.spacing(2)
  },
  colorBlack: {
    color: '#000'
  },
  swiper: {
    '& .swiper-container': {
      paddingBottom: theme.spacing(5)
    },
    '& .swiper-pagination-bullet-active': {
      background: theme.palette.secondary.main
    },
    '& .swiper-scrollbar': {
      height: '0.4rem',
      borderRadius: '0'
    },
    '& .swiper-scrollbar-drag': {
      background: '#000',
      borderRadius: '0'
    }
  },
  swiperBox: {
    // paddingTop: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    // paddingLeft: theme.spacing(2)
  },
  imgWrap: {
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer'
    // borderRadius: theme.spacing(2)
  },
  image: {
    width: '100%',
    height: '200px',
    objectFit: 'contain',
    overflow: 'hidden',
    top: '0',
    left: '0'
    // borderRadius: theme.spacing(1)
  }
}));

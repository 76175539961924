import { GroceryDeliveryProviders, GroceryDoordashDeliveryStatus, GroceryOrderStatus } from 'generated/types';

export interface IDeliveryEvent {
  deliveryProvider: GroceryDeliveryProviders;
  deliveryStatusDoordashClassic: GroceryDoordashDeliveryStatus | GroceryOrderStatus;
  created_on: number;
  cancelledReason?: string;
}

export interface IEventState {
  type: GroceryDoordashDeliveryStatus;
  isCancelled: boolean;
  reason: string | null;
  eventTime: string;
}

export const MapStatusToNumber: {
  [key: string]: number;
} = {
  // doordash
  scheduled: 0,
  accepted: 1,
  assigned: 2,
  enroute_to_pickup: 2,
  arrived_at_store: 2,
  picked_up: 3,
  arrived_at_consumer: 3,
  cancelled: 3,
  delivered: 4,
  pending_return: 5,
  could_not_deliver: 5
};

import { useStore } from 'store';
import { GroceryStore } from 'models';
import { useEffect, useState, useCallback } from 'react';
import { plainToClass } from 'class-transformer';
import Big from 'big.js';
import { useLazyQuery } from '@apollo/react-hooks';
import { GroceryStoreCartItem, GroceryCoupon } from 'generated/types';
import { IAddress } from 'components/AddressModal/types';
import { GroceryOrderType } from 'generated/types';
import { useGetGroceryStoreCart } from 'graphql/hooks';
import useActiveGroceryStore from 'hooks/groceryStore/useActiveGroceryStore';
import GroceryStoreModel from 'models/GroceryStore';
import { getGroceryStore as getGroceryStoreQuery } from 'graphql/query';
import { GroceryDropOffType, IGroceryDoordashDeliveryEstimate } from 'generated/custom';

export interface IReturnObject {
  // getters
  groceryStoreId: string | null;
  groceryCartItems: GroceryStoreCartItem[];
  groceryCartRewardItems: GroceryStoreCartItem[];
  groceryStore: GroceryStoreModel | null;
  subtotal: Big;
  totalPriceAfterTax: Big | null;
  tip: Big;
  serviceFee: Big | null;
  tax: Big | null;
  deliveryTime: number;
  orderNote: string;
  orderType: GroceryOrderType | null;
  deliveryAddress: IAddress | null;
  deliveryMiles: Big;
  deliveryCharge: Big;
  deliveryNote: string;
  finalPrice: Big;
  // coupon related
  isDiscountApplied: false;
  couponData: Partial<GroceryCoupon> | null;
  discount: Big | null;
  subtotalAfterDiscount: Big;
  // setters
  setDeliveryCharge: (payload: Big) => void;
  setDeliveryMiles: (payload: Big) => void;
  setDeliveryAddress: (payload: IAddress | null) => void;
  setDeliveryNote: (payload: string) => void;
  setOrderType: (payload: GroceryOrderType | null) => void;
  setTipAmount: (payload: Big) => void;
  setServiceFee: (payload: Big) => void;
  setDeliveryTime: (payload: number) => void;
  setOrderNote: (payload: string) => void;
  setCouponData: (payload: { coupon: Partial<GroceryCoupon>; token: string } | null) => void;
  redeemedPoints: number;
  cartLoading: boolean;
  setGroceryDoordashDeliveryEstimate: (payload: IGroceryDoordashDeliveryEstimate) => void;
  doordashQuoteId: string | null;
  dropOffType: GroceryDropOffType;
  apartmentNumber: string;
  setDropOffType: (payload: GroceryDropOffType) => void;
  setApartmentNumber: (payload: string) => void;
}

const useGroceryCart = (): IReturnObject => {
  const {
    dispatch,
    state: {
      grocery_subtotal,
      grocery_totalPriceAfterTax,
      grocery_tax,
      grocery_serviceFee,
      grocery_tip,
      grocery_deliveryTime,
      grocery_orderNote,
      grocery_orderType,
      grocery_deliveryAddress,
      grocery_deliveryMiles,
      grocery_deliveryCharge,
      grocery_deliveryNote,
      grocery_finalPrice,
      grocery_isDiscountApplied,
      grocery_couponData,
      grocery_discount,
      grocery_subtotalAfterDiscount,
      grocery_doordashQuoteId,
      grocery_apartmentNumber,
      grocery_dropOffType
    }
  } = useStore();

  const { groceryStoreId } = useActiveGroceryStore();

  // const { groceryStore } = useGetGroceryStoreById(groceryStoreId);

  const { groceryCartItems, groceryCartRewardItems, redeemedPoints, cartLoading } = useGetGroceryStoreCart(groceryStoreId);

  const [getGroceryStore, { loading, data: groceryStoreData }] = useLazyQuery(getGroceryStoreQuery);

  const [groceryStore, setGroceryStore] = useState<GroceryStore | null>(null);

  useEffect(() => {
    /*
     * Fetching grocery store
     * Using lazy query and useEffect to avoid infinite re render
     * This use effect will call getStore query only once
     */
    if (groceryStoreId) {
      getGroceryStore({
        variables: {
          input: {
            storeId: groceryStoreId
          }
        }
      });
    }
  }, [getGroceryStore, groceryStoreId]);

  useEffect(() => {
    if (!loading && groceryStoreData) {
      const storeObj = plainToClass(GroceryStore, groceryStoreData.getGroceryStore);
      setGroceryStore(storeObj);
    }
  }, [groceryStoreData, loading]);

  useEffect(() => {
    //Re evaluate changed when any of the depnedency changes value

    if (groceryCartItems && groceryCartItems.length && groceryStore) {
      dispatch({
        type: 'GROCERY_CART_ACTION_EVALUATE_CHARGES',
        payload: {
          groceryStore,
          groceryCartItems
        }
      });
    }
  }, [dispatch, groceryCartItems, groceryStore, grocery_deliveryAddress, grocery_tip, grocery_couponData, grocery_doordashQuoteId]);

  const setTipAmount = useCallback(
    (payload: Big) => {
      dispatch({
        type: 'GROCERY_CART_ACTION_TIP_AMOUNT',
        payload
      });
    },
    [dispatch]
  );

  const setDeliveryTime = useCallback(
    (payload: number) => {
      dispatch({
        type: 'GROCERY_CART_ACTION_SET_DELIVERY_TIME',
        payload: payload
      });
    },
    [dispatch]
  );

  const setOrderNote = (payload: string) => {
    dispatch({
      type: 'GROCERY_CART_ACTION_SET_ORDER_NOTE',
      payload
    });
  };

  const setOrderType = useCallback(
    (payload: GroceryOrderType | null) => {
      dispatch({
        type: 'GROCERY_CART_ACTION_SET_ORDER_TYPE',
        payload
      });
    },
    [dispatch]
  );

  const setDeliveryCharge = (payload: Big) => {
    dispatch({
      type: 'GROCERY_CART_ACTION_SET_DELIVERY_CHARGE',
      payload
    });
  };

  const setDeliveryMiles = (payload: Big) => {
    dispatch({
      type: 'GROCERY_CART_ACTION_SET_DELIVERY_MILES',
      payload
    });
  };

  const setDeliveryAddress = (payload: IAddress | null) => {
    dispatch({
      type: 'GROCERY_CART_ACTION_SET_DELIVERY_ADDRESS',
      payload
    });
  };

  const setDeliveryNote = (payload: string) => {
    dispatch({
      type: 'GROCERY_CART_ACTION_SET_DELIVERY_NOTE',
      payload
    });
  };

  // To manually set service fee
  const setServiceFee = (payload: Big) => {
    dispatch({
      type: 'GROCERY_CART_ACTION_SET_SERVICE_FEE',
      payload
    });
  };

  const setCouponData = useCallback(
    (payload: { coupon: Partial<GroceryCoupon>; token: string } | null) => {
      if (payload) {
        const { coupon, token } = payload;

        // Set coupon data in store.
        dispatch({
          type: 'GROCERY_CART_ACTION_SET_COUPON_DATA',
          payload: coupon
        });

        // Set coupon token in store.
        dispatch({
          type: 'GROCERY_CART_ACTION_SET_COUPON_TOKEN',
          payload: token
        });
      } else {
        dispatch({
          type: 'GROCERY_CART_ACTION_REMOVE_DISCOUNT'
        });
      }
    },
    [dispatch]
  );

  const setGroceryDoordashDeliveryEstimate = useCallback(
    (payload: IGroceryDoordashDeliveryEstimate) => {
      dispatch({
        type: 'GROCERY_CART_ACTION_SET_DOORDASH_DELIVERY_ESTIMATE',
        payload
      });
    },
    [dispatch]
  );

  const setDropOffType = (payload: GroceryDropOffType) => {
    dispatch({
      type: 'GROCERY_CART_ACTION_SET_DROP_OFF_INSTRUCTION',
      payload
    });
  };

  const setApartmentNumber = (payload: string) => {
    dispatch({
      type: 'GROCERY_CART_ACTION_SET_APARTMENT_NUMBER',
      payload
    });
  };

  return {
    groceryStoreId,
    groceryStore,
    groceryCartItems,
    groceryCartRewardItems,
    setTipAmount,
    setDeliveryTime,
    setOrderNote,
    setOrderType,
    setDeliveryCharge,
    setDeliveryMiles,
    setDeliveryAddress,
    setDeliveryNote,
    setServiceFee,
    deliveryAddress: grocery_deliveryAddress,
    deliveryCharge: grocery_deliveryCharge,
    deliveryMiles: grocery_deliveryMiles,
    deliveryNote: grocery_deliveryNote,
    deliveryTime: grocery_deliveryTime,
    orderNote: grocery_orderNote,
    orderType: grocery_orderType,
    serviceFee: grocery_serviceFee,
    subtotal: grocery_subtotal,
    tax: grocery_tax,
    tip: grocery_tip,
    totalPriceAfterTax: grocery_totalPriceAfterTax,
    finalPrice: grocery_finalPrice,
    isDiscountApplied: grocery_isDiscountApplied,
    couponData: grocery_couponData,
    discount: grocery_discount,
    subtotalAfterDiscount: grocery_subtotalAfterDiscount,
    setCouponData,
    redeemedPoints,
    cartLoading,
    setGroceryDoordashDeliveryEstimate,
    doordashQuoteId: grocery_doordashQuoteId,
    dropOffType: grocery_dropOffType,
    apartmentNumber: grocery_apartmentNumber,
    setDropOffType,
    setApartmentNumber
  };
};

export default useGroceryCart;

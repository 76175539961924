import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  groceryContainer: {
    maxWidth: '1200px',
    margin: '0px auto'
  },
  cancelIcon: {
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1),
    color: 'white',
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  backIcon: {
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1),
    color: 'white',
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  }
}));

import React from 'react';
import useActiveGroceryStore from 'hooks/groceryStore/useActiveGroceryStore';
import { useGetGroceryStoreById } from 'graphql/hooks';
import { Typography, Box, useMediaQuery } from '@material-ui/core';
import { useStyles } from './style';
import Categories from '../Categories';
import GroceryStoreInfo from '../GroceryStoreInfo';
import Announcement from 'components/Announcement';
import GroceryItemsSearch from 'components/GroceryItemsSearch';
import { useHistory } from 'react-router-dom';

const GroceryStoreHome = () => {
  const classes = useStyles();

  const { groceryStoreId } = useActiveGroceryStore();

  const { groceryStore } = useGetGroceryStoreById(groceryStoreId);

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const history = useHistory();

  if (groceryStore) {
    const announcement = groceryStore.announcement;

    return (
      <>
        <Box padding={1} marginBottom={2} bgcolor={'#fff'}>
          <Box className={classes.groceryContainer} padding={1}>
            {groceryStoreId && !isDesktop && (
              <GroceryItemsSearch
                onSearch={(query) => {
                  history.push(`/grocery/${groceryStoreId}/search?query=${query}`);
                }}
              />
            )}
            <GroceryStoreInfo groceryStore={groceryStore} />
            {announcement && (
              <Box marginTop={2}>
                <Announcement text={announcement} />
              </Box>
            )}
          </Box>
        </Box>
        <Box bgcolor={'#fff'}>
          <Box className={classes.groceryContainer} padding={1}>
            <Typography variant="h6">Shop By categories</Typography>
          </Box>
          <Box className={classes.groceryContainer}>
            <Categories groceryStore={groceryStore} />
          </Box>
        </Box>
      </>
    );
  }

  return null;
};

export default GroceryStoreHome;

import { Box, Typography } from '@material-ui/core';
import { GroceryBizHour } from 'generated/types';
import moment from 'moment';
import { ICombinedHour, lpadNumber } from 'pages/Restaurant/RestaurantHome/RestaurantInfo/BizHours';
import React, { useEffect, useState } from 'react';
import { combineDaysOfWeek } from 'util/convertBusinessHours';
import { Date } from 'util/date';

interface IProps {
  bizHours: GroceryBizHour[];
}

const GroceryTodayBizHour = ({ bizHours }: IProps) => {
  const [timings, setTimings] = useState<string>('');

  useEffect(() => {
    let newTimings: string = '';

    const curDate = moment();

    const todayDay = curDate.day() ? curDate.day() : 7;

    bizHours.forEach((bizHour: GroceryBizHour) => {
      const { hours: slots } = bizHour;

      const hours = combineDaysOfWeek(slots);

      hours.forEach((hour: ICombinedHour) => {
        const { openDay, openHour, openMinute, closeHour, closeMinute } = hour;

        let currentlyActive = false;

        if (openDay.length === 1) {
          if (openDay[0] === todayDay) {
            currentlyActive = true;
          }
        } else {
          if (openDay[0] <= todayDay && openDay[openDay.length - 1] >= todayDay) {
            currentlyActive = true;
          }
        }

        if (currentlyActive) {
          if (newTimings !== '') {
            newTimings += ' | ';
          }

          newTimings += Date.convertDateToMeridienFormat(openHour, lpadNumber(openMinute, 2)) + ' - ' + Date.convertDateToMeridienFormat(closeHour, lpadNumber(closeMinute, 2));
        }
      });
    });

    setTimings(newTimings);
  }, [bizHours]);

  return (
    <Box paddingX={1} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <Typography variant="h6">Timing today</Typography>
      <Typography variant="body1">{timings}</Typography>
    </Box>
  );
};

export default GroceryTodayBizHour;

import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { useStyles } from './style';
import { map } from 'lodash';
import S3Image from 'components/S3Image';
import grocery_category_empty_icon from 'assets/img/grocery-category-empty-icon.png';
import GroceryStoreModel from 'models/GroceryStore';
import { useHistory } from 'react-router-dom';

interface IProps {
  groceryStore: GroceryStoreModel;
}

const Categories = ({ groceryStore }: IProps) => {
  const classes = useStyles();

  const history = useHistory();

  const { categories } = groceryStore;

  if (categories && categories.length) {
    return (
      <Grid container={true} className={classes.container}>
        {map(categories, ({ id, title, image }) => {
          return (
            <Grid key={id} item={true} xs={3} md={2} onClick={() => history.push(`/grocery/${groceryStore.id}/category/${id}`)}>
              <Box marginY={1}>
                <Box padding={1}>
                  <S3Image
                    squareImage={true}
                    defaultImageClassName={classes.defaultCategoryImg}
                    className={classes.defaultCategoryImg}
                    src={image && image.preview ? image.preview.url : null}
                    defaultImage={grocery_category_empty_icon}
                  />
                </Box>
                <Box>
                  <Typography variant="body1" align="center">
                    {title}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    );
  } else {
    return <Typography variant="h6">No categories found.</Typography>;
  }
};

export default Categories;

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 1)
  },
  defaultCategoryImg: {
    borderRadius: '10px'
  },
  categoryImage: {
    borderRadius: '10px'
  }
}));

import gql from 'graphql-tag';

export const applyCryptoCouponMutation = gql`
  mutation applyCryptoCoupon($input: ApplyCryptoCouponInput!) {
    applyCryptoCoupon(input: $input) {
      success
      error
      token
      coupon {
        bizId
        code
        id
        createdAt
        name
        description
        discountType
        discountValue
        maxDiscount
        startDate
        endDate
        minSpending
        maxUse
        isPrivate
        isSingleUse
        orderType
        dayOfWeek
        consumerReward
        cryptoUnit
      }
    }
  }
`;

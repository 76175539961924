import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    '&:first-child': {
      padding: 0
    }
  },
  textField: {
    height: 40
  },
  boldText: {
    fontWeight: "bolder"
  },
  dialog: {
    '& .MuiPaper-root': {
      height: '100%'
    }
  },
  container:{
    display:"flex",
    position:"relative",
    flexDirection:"column",
    flex:1,
    overflow:"auto",
    backgroundColor:'#fff'
  },
  couponContainerSelected: {
    cursor: 'pointer',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(2),
    borderStyle: 'dashed',
    border: '2px',
    backgroundColor: 'rgba(255, 205, 89, 0.2)',
    borderColor: theme.palette.primary.main,
  },
  couponContainer: {
    cursor: 'pointer',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    borderColor: theme.palette.grey[300],
    borderStyle: 'dashed',
    border: '2px',
    '& .MuiButton-containedPrimary': {
      backgroundColor: theme.palette.grey[300]
    }
  },
  paymentButton: {
    borderRadius: 0,
    flex: '1 1 0',
  },
  couponGroup: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    background: '#fff',
    top: '-2px',
    color: '#000',
    zIndex: 1,
    position: 'sticky'
  },
  loader: {
    color: '#fff',
    width: '25px',
    height: '25px'
  },
  applyCode: {
    cursor: 'pointer'
  }
}));

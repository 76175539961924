import { groceryDoordashDeliveryCostEstimate } from 'graphql/mutations/groceryDelivery.mutation';
import { useMutationWithLoader } from 'hooks/loader';

export const useGroceryDoordashDeliveryCostEstimateMutation = () => {
  const [getGroceryDoordashEstimate, { data, loading, error }] = useMutationWithLoader(groceryDoordashDeliveryCostEstimate, {}, true);

  return {
    getGroceryDoordashEstimate,
    data,
    loading,
    error
  };
};

import { useGetGroceryRewardItems, useGetGroceryStoreById, useGetGroceryStoreCart, useGetUserRewardsByGroceryStore } from 'graphql/hooks';
import { calculateSubTotalAndTaxForGrocery } from 'util/priceCalculator';
import useActiveGroceryStore from './useActiveGroceryStore';
import Big from 'big.js';
import GroceryStoreItemModal from 'models/GroceryItem';
import { GroceryStoreCartItem } from 'generated/types';
import { convertPriceTo } from 'util/number';

type IsAddPossibleReturnType = {
  isPossible: Boolean;
  message: string;
};

type IsAddPossibleInput = {
  remainingPoints: number;
  itemRewardPoints: number;
  itemQty: number;
  itemTitle: string;
};

interface IRewardReturn {
  totalPoints: number;
  redeemablePoints: number;
  minimumPointsRequired: number;
  isMinimumPointsSatisfied: boolean;
  minimumPurchaseRequired: number;
  maximumRedeemablePoints: number;
  isRedeemDisabled: boolean;
  remainingPoints: number;
  usedPoints: number;
  errorMessage: string;
  pointsCutoff: boolean;
  rewardItems: GroceryStoreItemModal[] | null;
  groceryCartRewardItems: GroceryStoreCartItem[];
  isAddPossible: (input: IsAddPossibleInput) => IsAddPossibleReturnType;
}

const isAddPossible = ({ remainingPoints, itemRewardPoints, itemQty, itemTitle }: IsAddPossibleInput): IsAddPossibleReturnType => {
  const pointsToAdd = Big(itemRewardPoints).mul(itemQty);

  const isPossible = Big(remainingPoints).gte(pointsToAdd);

  let message = '';

  if (!isPossible) {
    const ptsRunningOutOf = Big(pointsToAdd)
      .sub(remainingPoints)
      .toString();

    message = `Can not add one more <b>${itemTitle}</b>, running short of <b>${ptsRunningOutOf}</b> PTS`;
  }

  return {
    isPossible,
    message
  };
};

const useGroceryReward = (): IRewardReturn => {
  const { groceryStoreId } = useActiveGroceryStore();

  const { groceryStore } = useGetGroceryStoreById(groceryStoreId);

  const { groceryCartItems, groceryCartRewardItems, redeemedPoints, loading: CartLoading } = useGetGroceryStoreCart(groceryStoreId);

  const { rewardPointsData } = useGetUserRewardsByGroceryStore(groceryStoreId, true);

  const { rewardItems } = useGetGroceryRewardItems(groceryStoreId);

  if (groceryStoreId && groceryStore && groceryStore.issueRewardPoint && groceryStore.rewardSetting && !CartLoading) {
    const { rewardSetting } = groceryStore;

    const totalPoints: number = rewardPointsData && rewardPointsData[groceryStoreId] ? rewardPointsData[groceryStoreId].activePoints || 0 : 0;

    const usedPoints = redeemedPoints;

    const { subtotal } = calculateSubTotalAndTaxForGrocery({ store: groceryStore, items: groceryCartItems });

    const minimumPurchaseRequired = rewardSetting.minRedeemPurchase || 0;

    const isMinimumPurchaseSatisfied = subtotal.gte(minimumPurchaseRequired);

    const maximumRedeemablePoints = rewardSetting.maxRedeemPointsPerUsage;

    const redeemablePoints = Math.min(maximumRedeemablePoints, totalPoints);

    const minimumPointsRequired = rewardSetting.minStartPoints;

    const isMinimumPointsSatisfied = Big(totalPoints).gte(minimumPointsRequired);

    const pointsCutoff = Big(totalPoints).gt(maximumRedeemablePoints);

    const remainingPoints = parseInt(
      Big(redeemablePoints)
        .minus(usedPoints)
        .valueOf()
    );

    let isRedeemDisabled = false;

    let errorMessage = '';

    if (!isMinimumPurchaseSatisfied) {
      isRedeemDisabled = true;

      if (groceryCartItems.length) {
        const requiredPurchase = convertPriceTo(
          Big(minimumPurchaseRequired)
            .minus(subtotal.toFixed(2))
            .toFixed(2),
          'DOLLAR'
        );

        errorMessage = `Spend $${requiredPurchase} more to redeem upto ${redeemablePoints} PTS`;
      } else {
        errorMessage = `Spend minimum $${convertPriceTo(minimumPurchaseRequired, 'DOLLAR')} to redeem upto ${redeemablePoints} PTS`;
      }
    }

    if (!isMinimumPointsSatisfied) {
      isRedeemDisabled = true;

      errorMessage = `Need atleast ${minimumPointsRequired} points to start redeeming`;
    }

    if (totalPoints === 0) {
      isRedeemDisabled = true;

      errorMessage = '';
    }

    if (remainingPoints === 0) {
      isRedeemDisabled = true;
    }

    return {
      totalPoints,
      redeemablePoints,
      minimumPointsRequired,
      isMinimumPointsSatisfied,
      minimumPurchaseRequired,
      maximumRedeemablePoints,
      isRedeemDisabled,
      remainingPoints,
      usedPoints,
      errorMessage,
      pointsCutoff,
      rewardItems,
      groceryCartRewardItems,
      isAddPossible
    };
  }

  return {
    totalPoints: 0,
    redeemablePoints: 0,
    minimumPointsRequired: 0,
    isMinimumPointsSatisfied: false,
    minimumPurchaseRequired: 0,
    maximumRedeemablePoints: 0,
    isRedeemDisabled: true,
    remainingPoints: 0,
    usedPoints: 0,
    errorMessage: '',
    pointsCutoff: false,
    rewardItems: null,
    groceryCartRewardItems: [],
    isAddPossible
  };

  // const [getGroceryStore, { loading, data: groceryStoreData }] = useLazyQuery(getGroceryStoreQuery);

  // const [groceryStore, setGroceryStore] = useState<GroceryStore | null>(null);

  // useEffect(() => {
  //   /*
  //    * Fetching grocery store
  //    * Using lazy query and useEffect to avoid infinite re render
  //    * This use effect will call getStore query only once
  //    */
  //   if (groceryStoreId) {
  //     getGroceryStore({
  //       variables: {
  //         input: {
  //           storeId: groceryStoreId
  //         }
  //       }
  //     });
  //   }
  // }, [getGroceryStore, groceryStoreId]);
};

export default useGroceryReward;

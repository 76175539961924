import { useEffect, useState } from 'react';
import { useStore } from 'store';

export default () => {
  const [groceryStoreId, setGroceryStoreId] = useState<string | null>(null);

  const { state } = useStore();

  useEffect(() => {
    const { groceryStoreId } = state;
    if (groceryStoreId) {
      setGroceryStoreId(groceryStoreId);
    } else {
      setGroceryStoreId(null);
    }
  }, [state]);

  return {
    groceryStoreId
  };
};

import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Divider } from '@material-ui/core';
import Moment from 'moment';
import { useStyles } from './style';
import { useGroceryGetOrderQuery } from 'graphql/hooks';
import OrderItems from './OrderItems';
import { convertPriceTo } from 'util/number';
import { orderType, paymentTypeDict } from './constants';
import { useAlert } from 'hooks';

interface IProps {
  orderId: string | null;
  buyerId: string;
}

const Content: React.FC<IProps> = ({ orderId, buyerId }) => {
  const classes = useStyles();

  const statusColor = {
    CREATED: classes.blue,
    PLACED: classes.blue,
    REJECTED: classes.red,
    CANCELLED: classes.red,
    ACCEPTED: classes.yellow,
    READY: classes.green,
    CLOSED: classes.grey
  };

  const [totalItemCount, setTotalItemCount] = useState(0);

  const { order, error } = useGroceryGetOrderQuery(orderId, buyerId);

  const { lokoAlert } = useAlert();

  /**
   * Setting item count
   */
  useEffect(() => {
    if (order && order.items) {
      let totalCount = 0;

      order.items.forEach((item: any) => {
        totalCount += item.count;
      });

      setTotalItemCount(totalCount);
    }
  }, [order]);

  useEffect(() => {
    if (error) {
      lokoAlert('Failed to fetch Order Details');
    }
  }, [error, lokoAlert]);

  const renderContent = () => {
    if (order) {
      const {
        orderNumber,
        subTotal,
        tip,
        totalTax,
        deliveryFee,
        couponDiscount,
        coupon,
        paymentType,
        type,
        createdAt,
        deliveryDistance,
        deliveryAddress,
        deliveryNote,
        note,
        lokobeeFee,
        adjustAmount,
        totalAfterAdjust,
        adjustReason,
        expectTime
      } = order;

      const logoUrl = order.seller.logo?.url;

      const total = order.getTotal;

      const storeName = order.getGroceryStoreName;

      const couponCode = coupon ? coupon.code : '';

      const { text: deliveryAddressText } = deliveryAddress || { text: '' };

      const paymentTypeString = paymentType && paymentTypeDict[paymentType];

      const lokobeeFeeNumber = lokobeeFee ? parseFloat(convertPriceTo(lokobeeFee, 'DOLLAR')) : 0.0;

      const taxNumber = totalTax ? parseFloat(convertPriceTo(totalTax, 'DOLLAR')) : 0;

      const orderStatus = order?.status;

      const totalPointsRedeemed = order?.redeemPoints;

      const orderEmail = order.orderEmail || 'NA';

      const orderPhoneNumber = order.orderPhoneNumber || 'NA';

      const expectTimeMoment = Moment(expectTime);

      return (
        <Box flex={1} boxShadow="none" width="100%">
          <Grid container={true} className={classes.container} alignItems="center">
            <Grid item={true} xs={2}>
              {!logoUrl || logoUrl.trim() === '' ? <p>No image</p> : <img alt="" className={classes.img} src={logoUrl} width="100%" />}
            </Grid>
            <Grid item={true} xs={7}>
              <Box paddingX={1}>
                <Typography className={classes.boldText} display="block" variant="body1">
                  {storeName}&nbsp;
                </Typography>
                <Typography variant="caption">{Moment(createdAt).format('DD MMM YYYY, hh:mm A')}</Typography>
              </Box>
            </Grid>
            <Grid item={true} xs={3}>
              <Box display="flex" justifyContent="flex-end">
                <Box>
                  <Box display="flex" justifyContent="flex-end" height="max-content" alignItems="flex-end">
                    {orderStatus && <Box className={statusColor[orderStatus]} padding="8px" margin={1} borderRadius="100%" />}
                    <Typography variant="overline">{orderStatus} </Typography>
                  </Box>
                  <Box display="flex" justifyContent="flex-end" marginTop={-1}>
                    <Typography variant="overline">{orderType[type]} </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Divider variant="middle" />
          <Box padding={2}>
            <Typography variant="body1">Contact details</Typography>

            <Box>
              <Typography variant="caption">Phone number:&nbsp;{orderPhoneNumber}</Typography>
            </Box>
            <Box>
              <Typography variant="caption">Email:&nbsp;{orderEmail}</Typography>
            </Box>
          </Box>
          <Divider variant="middle" />
          <Box padding={2}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" flexDirection="column">
                <Box>
                  <Typography variant="caption">Order #:&nbsp;</Typography>
                  <Typography className={classes.boldText} variant="caption">
                    {orderNumber}
                  </Typography>
                </Box>
                {(type === 'TAKEOUT' || type === 'DELIVERY') && (
                  <Box paddingTop={1}>
                    <Typography variant="caption">ETA:&nbsp;</Typography>
                    <Typography className={classes.boldText} variant="caption">
                      {`${expectTimeMoment.format('DD MMM').toUpperCase()}, ${expectTimeMoment.format('hh:mm A')}`}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box>
                {!!order.total && (
                  <>
                    <Typography variant="caption">Payment:&nbsp;</Typography>
                    <Typography className={classes.boldText} variant="caption">
                      {paymentTypeString}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
            {type === 'DELIVERY' && (
              <>
                <Box paddingTop={1}>
                  <Typography variant="caption">
                    Delivery Address&nbsp;(<span className={classes.boldTextNonLabel}>{deliveryDistance?.toFixed(2)} Miles</span>):
                  </Typography>
                  <Typography className={classes.boldText} display="block" variant="caption">
                    {deliveryAddressText}
                  </Typography>
                </Box>
                {deliveryNote && (
                  <Box paddingTop={1}>
                    <Typography variant="caption">Delivery Note:&nbsp;</Typography>
                    <Typography className={classes.boldText} variant="caption">
                      {deliveryNote}
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Box>
          <Divider variant="middle" />
          <OrderItems items={order.items} />
          {note && (
            <>
              <Divider variant="middle" />
              <Box paddingX={2} paddingY={1}>
                <Typography style={{ fontStyle: 'italic', textDecoration: 'underline dotted' }} variant="caption">
                  Note:&nbsp;
                </Typography>
                <Typography style={{ fontStyle: 'italic' }} variant="caption">
                  {note}
                </Typography>
              </Box>
            </>
          )}
          <Divider variant="middle" />
          <Box padding={1}>
            {adjustAmount && adjustReason && (
              <Box paddingX={1}>
                <Typography variant="caption">Ajust Reason:&nbsp;</Typography>
                <Typography variant="caption" className={classes.boldText}>
                  {adjustReason}
                </Typography>
              </Box>
            )}
            <Box padding={1} width="60%" marginLeft="auto">
              {!!subTotal && (
                <Box display="flex">
                  <Typography variant="caption">
                    Subtotal ({totalItemCount} {totalItemCount > 1 ? 'Items' : 'Item'}):
                  </Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={classes.boldText}>
                      ${convertPriceTo(subTotal ? subTotal : 0, 'DOLLAR')}
                    </Typography>
                  </Box>
                </Box>
              )}

              {couponDiscount && (
                <Box display="flex">
                  <Typography variant="caption" className={classes.greenTextColor}>
                    Discount: {`${couponCode !== '' ? `(${couponCode})` : ''}`}
                  </Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={`${classes.boldText} ${classes.greenTextColor}`}>
                      - ${convertPriceTo(couponDiscount ? couponDiscount : 0, 'DOLLAR')}
                    </Typography>
                  </Box>
                </Box>
              )}

              {type === 'DELIVERY' && deliveryFee && (
                <Box display="flex">
                  <Typography variant="caption">Delivery Charge:</Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={classes.boldText}>
                      ${convertPriceTo(deliveryFee ? deliveryFee : 0, 'DOLLAR')}
                    </Typography>
                  </Box>
                </Box>
              )}

              {totalTax !== 0 && lokobeeFeeNumber !== 0 && (
                <Box display="flex">
                  <Typography variant="caption">Tax & Fees:</Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={classes.boldText}>
                      ${(taxNumber + lokobeeFeeNumber).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              )}

              {totalTax !== 0 && lokobeeFeeNumber === 0 && (
                <Box display="flex">
                  <Typography variant="caption">Tax:</Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={classes.boldText}>
                      ${taxNumber.toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              )}

              {!!tip && (
                <Box display="flex">
                  <Typography variant="caption">Tip:</Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={classes.boldText}>
                      ${convertPriceTo(tip ? tip : 0, 'DOLLAR')}
                    </Typography>
                  </Box>
                </Box>
              )}

              {!!total && (
                <Box display="flex">
                  <Typography variant="caption">Total Amount:</Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={classes.boldText}>
                      ${total}
                    </Typography>
                  </Box>
                </Box>
              )}

              {adjustAmount && adjustReason && (
                <Box display="flex">
                  <Typography variant="caption">Adjust Amount:</Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={classes.boldText}>
                      ${convertPriceTo(adjustAmount ? adjustAmount : 0, 'DOLLAR')}
                    </Typography>
                  </Box>
                </Box>
              )}

              {totalAfterAdjust && (
                <Box display="flex">
                  <Typography variant="caption">Total After Adjust:</Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={classes.boldText}>
                      ${convertPriceTo(totalAfterAdjust ? totalAfterAdjust : 0, 'DOLLAR')}
                    </Typography>
                  </Box>
                </Box>
              )}

              {!!totalPointsRedeemed && (
                <Box display="flex">
                  <Typography variant="caption">PTS Redeemed:&nbsp;</Typography>
                  <Box textAlign="right" flexGrow={1}>
                    <Typography variant="caption" className={classes.boldText}>
                      {totalPointsRedeemed}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      );
    }

    return null;
  };

  return renderContent();
};

export default Content;

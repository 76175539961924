import { GroceryOrderType } from 'generated/types';
import { GroceryCoupon } from 'generated/types';

export interface ICoupon extends GroceryCoupon {
  isApplicable?: boolean;
  notApplicableReason?: string;
}

export interface IProps {
  storeId: string;
  coupons: GroceryCoupon[] | null | undefined;
  orderType: GroceryOrderType | null;
  subtotalToDiscount: string;
  subtotal: string;
  isDiscountApplied: boolean;
  appliedCoupon: Partial<GroceryCoupon> | null;
  isCartHavingPrediscountedItem: boolean;
  isPrediscountEnabled: boolean;
  deliveryTime: number;
  setCouponData: (payload: { coupon: Partial<GroceryCoupon>; token: string } | null) => void;
}

export interface ICouponGroups {
  applicable?: ICoupon[];
  available?: ICoupon[];
}

export enum ECouponGroups {
  applicable = 'Applicable Coupons for this Purchase',
  available = 'Coupons NOT Applicable to Current Purchase'
}

export const couponOrderTypeMap: any = {
  TAKEOUT: 'TAKEOUT',
  DELIVERY: 'DELIVERY'
};

export const mapTypes: any = {
  TAKEOUT: 'Takeout',
  DELIVERY: 'Delivery'
};

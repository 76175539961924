import { Backdrop, Divider, Typography, Box } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useStyles } from '../style';
import Close from '@material-ui/icons/Close';
import { map } from 'lodash';
interface IProps {
  groceryItemsList: Array<any>;
  open: boolean;
  brandFilter: Array<string>;
  setBrandFilter: (brand: Array<string>) => void;
  onClose: () => void;
}

const BrandFilter: React.FC<IProps> = ({ groceryItemsList, brandFilter, setBrandFilter, open, onClose }) => {
  const classes = useStyles();

  const brands: Array<string> = Array.from(new Set(map(groceryItemsList, (item: any) => item.brand)));

  const selectBrand = useCallback(
    (brandTitle: string) => {
      let selectedItems = [...brandFilter];
      if (!brandFilter.includes(brandTitle)) {
        selectedItems.push(brandTitle);
      } else {
        if (selectedItems.indexOf(brandTitle) > -1) {
          selectedItems.splice(selectedItems.indexOf(brandTitle), 1);
        }
      }
      setBrandFilter(selectedItems);
    },
    [brandFilter, setBrandFilter]
  );
  return (
    <>
      <Backdrop open={open} className={classes.backdrop}>
        <Box height="min-content" className={classes.customTipContainer} maxWidth={700}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box marginBottom={1} padding={2} className={classes.modalHeader}>
              <Typography variant="h6">Filter</Typography>
              <Close onClick={onClose} />
            </Box>
            <Divider />
            <Box paddingBottom={4} overflow={'auto'}>
              <Box marginBottom={1} padding={2} className={classes.modalHeader}>
                <Typography variant="h6">Brand</Typography>
              </Box>
              <Box display={'flex'} paddingX={2} flexWrap={'wrap'}>
                {brands.map((brand: string, index) => {
                  return (
                    <Box key={index} className={classes.brandBox + ' ' + (brandFilter.includes(brand) ? classes.selectedTab : '')} onClick={() => selectBrand(brand)}>
                      <Typography variant="body1" className={classes.brandTitle}>
                        {brand}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Backdrop>
    </>
  );
};

export default BrandFilter;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getGroceryStore as getGroceryStoreQuery } from 'graphql/query';
import { useStore } from 'store';

import moment from 'moment-timezone';
import { useLazyQueryWithLoader } from 'hooks/loader';

export default () => {
  const { groceryStoreId } = useParams<any>();

  const { dispatch } = useStore();

  const [isValid, setValid] = useState<boolean>(false);

  const [getGroceryStore, { data }] = useLazyQueryWithLoader(getGroceryStoreQuery);

  const [storeId, setStoreId] = useState(null);

  useEffect(() => {
    if (storeId && storeId === groceryStoreId) {
      return;
    } else {
      if (groceryStoreId) {
        getGroceryStore({
          variables: {
            input: {
              storeId: groceryStoreId
            }
          }
        });
      }
    }
  }, [getGroceryStore, groceryStoreId, storeId]);

  useEffect(() => {
    if (data && data.getGroceryStore) {
      const { id, timezone } = data.getGroceryStore;

      const timezoneLower = timezone ? timezone.toLowerCase() : "'us/pacific'";

      if (moment.tz) {
        moment.tz.setDefault(timezoneLower);
      }

      setStoreId(id);

      dispatch({
        type: 'SET_GROCERY_STORE',
        payload: id
      });

      setValid(true);
    }
  }, [data, dispatch]);

  useEffect(() => {
    return () => dispatch({ type: 'RESET_GROCERY_STORE' });
  }, [dispatch]);

  return {
    isValid: isValid,
    groceryStoreId: storeId
  };
};

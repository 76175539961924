import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { CustomBottomDialog } from '@lokobee/lokobee-ui';
import CloseIcon from '@material-ui/icons/Close';

import Big from 'big.js';
import { useStore } from 'store';

interface IProps {
  tax: Big;
  taxRate: string;
  setServiceFee: (payload: Big) => void;
}

const ServiceFeeDetailsDialog: React.FC<IProps> = ({ tax, taxRate, setServiceFee }) => {
  const {
    state: { serviceFee }
  } = useStore();

  const [open, setOpen] = useState(false);

  const [value, setValue] = useState<string>(serviceFee?.toFixed(2) || '');

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    if (serviceFee) {
      setValue(serviceFee.toFixed(2));
    }
  }, [serviceFee]);

  return (
    <>
      <InfoIcon fontSize="small" style={{ color: 'darkgrey', fontSize: '1em', marginLeft: '4px' }} onClick={toggleOpen} />
      <Box height={20} marginLeft={1}>
        <CustomBottomDialog open={open}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box display="flex" marginBottom={1}>
              <Box flex={1}>
                <Typography variant="h6">Tax and Fees</Typography>
              </Box>
              <Box>
                <CloseIcon onClick={toggleOpen} color="inherit" />
              </Box>
            </Box>
            <Box marginBottom={2}>
              <Divider />
            </Box>
            <Box flex={1}>
              <Box display="flex" marginBottom={1}>
                <Box flex={1}>
                  <Typography variant="body1" gutterBottom={true}>
                    Tax ({taxRate}%):
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1" gutterBottom={true}>
                    ${tax.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" marginBottom={1}>
                <Box flex={1}>
                  <Typography variant="body1" gutterBottom={true}>
                    Service fee:
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1" gutterBottom={true}>
                    ${value}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="caption">Your contribution helps Lokobee provide innovative & free service to local restaurants. Together let’s make our local community thrive!</Typography>
            </Box>
            <Button variant="contained" color="primary" fullWidth={true} onClick={toggleOpen}>
              Ok
            </Button>
          </Box>
        </CustomBottomDialog>
      </Box>
    </>
  );
};

export default ServiceFeeDetailsDialog;

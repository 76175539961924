import React, { useCallback, useEffect, useState } from 'react';
import { Box, useMediaQuery, Theme, Dialog } from '@material-ui/core';
import { IProps } from './types';
import { useStyles } from './style';
import ApplyCryptoPromoButton from './ApplyCryptoPromoButton';
import Transition from 'components/Transition';
import DialogHeader from 'components/DialogHeader';
import SelectCouponDialogContent from './SelectCouponDialogContent';
import { CryptoCoupon } from 'generated/types';
import { couponOrderTypeMap, getCheckedCouponFromList, getSortedCouponsbyDiscount } from 'util/cryptoCouponUtils';
import { useApplyCryptoCoupon, useResetCryptoCoupon } from 'graphql/hooks';
import { convertPriceTo } from 'util/number';
import Moment from 'moment';
import { useStore } from 'store';
import { useSnackbar } from 'notistack';

const CryptoPromo: React.FC<IProps> = ({
  restaurantId,
  coupons,
  orderType,
  subtotalToDiscount,
  subtotal,
  isDiscountApplied,
  appliedCoupon,
  setCouponData,
  isCartHavingPrediscountedItem,
  deliveryTime,
  isPrediscountEnabled,
  discount
}) => {
  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();

  const snackbar = useSnackbar();
  const [applicableCoupons, setApplicableCoupons] = useState<CryptoCoupon[]>([]);
  const [notApplicableCoupons, setNotApplicableCoupons] = useState<CryptoCoupon[]>([]);
  const [isOpen, setOpen] = useState(false);
  const {
    dispatch,
    state: {
      couponData,
      couponToken,
      isAutoApplyCouponAllowed,
      cartSessionId,
      user: { email }
    }
  } = useStore();
  const { applyCryptoCoupon, loading: isApplyingCoupon } = useApplyCryptoCoupon();
  const [applyCouponLoading, setApplyCouponLoading] = useState(false);
  const { resetCryptoCoupon, loading: isRemovingCoupon } = useResetCryptoCoupon();
  const [couponApplied, setCouponApplied] = useState<boolean>(false);

  useEffect(() => {
    if (coupons && coupons.length > 0) {
      const { applicableCouponsFiltered, notApplicableCouponsFiltered } = getCheckedCouponFromList(coupons, deliveryTime, subtotalToDiscount, orderType);
      setApplicableCoupons(applicableCouponsFiltered);
      setNotApplicableCoupons(notApplicableCouponsFiltered);
    }
  }, [coupons, deliveryTime, subtotalToDiscount, orderType]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  // Common function module to apply coupon weather it's auto or select
  const applyCoupon = useCallback(
    async (code: string) => {
      return new Promise(async (resolve, reject) => {
        setApplyCouponLoading(true);
        const response = await applyCryptoCoupon({
          variables: {
            input: {
              bizId: restaurantId,
              code: code,
              subtotal: parseInt(convertPriceTo(subtotalToDiscount, 'CENT'), 10),
              verifyDay: Moment(deliveryTime).format('YYYY-MM-DD'),
              orderType: couponOrderTypeMap[orderType],
              userEmail: email,
              sessionId: cartSessionId
            }
          }
        });
        if (response && response.data) {
          const { data: verifiedData } = response;
          if (verifiedData && verifiedData.applyCryptoCoupon && verifiedData.applyCryptoCoupon.success) {
            if (verifiedData.applyCryptoCoupon.coupon && verifiedData.applyCryptoCoupon.token) {
              setCouponData({
                coupon: verifiedData.applyCryptoCoupon.coupon,
                token: verifiedData.applyCryptoCoupon.token
              });
              resolve({ isSuccess: true, applyCryptoCouponData: verifiedData.applyCryptoCoupon });
              setApplyCouponLoading(false);
              if (isCartHavingPrediscountedItem && isPrediscountEnabled) {
                snackbar.enqueueSnackbar('Some Item(s) in the cart are already discounted. Coupons will not apply on them.', { variant: 'info', autoHideDuration: 4000 });
              }
            }
          } else {
            resolve({ isSuccess: false, applyCryptoCouponData: verifiedData.applyCryptoCoupon }); // triggering 'applycoupon' with async/await that's why resolving instead rejecting
            setApplyCouponLoading(false);
          }
        } else {
          resolve(null);
        }
      });
    },
    [applyCryptoCoupon, cartSessionId, deliveryTime, email, isCartHavingPrediscountedItem, isPrediscountEnabled, orderType, restaurantId, setCouponData, snackbar, subtotalToDiscount]
  );

  const removeDiscount = useCallback(async () => {
    const response = await resetCryptoCoupon({
      variables: {
        input: {
          token: couponToken,
          sessionId: cartSessionId
        }
      }
    });
    if (response && response.data) {
      dispatch({
        type: 'CART_ACTION_TOGGLE_COUPON_AUTO_APPLY',
        payload: false
      });
      setCouponApplied(false);
      setCouponData(null);
      snackbar.enqueueSnackbar('Coupon Removed!!', { variant: 'success' });
    } else {
      // dispatch({
      //   type: 'CART_ACTION_TOGGLE_COUPON_AUTO_APPLY',
      //   payload: false
      // });
      // setCouponApplied(false);
      // setCouponData(null);
      snackbar.enqueueSnackbar('Something went wrong!', { variant: 'error' });
    }
  }, [cartSessionId, couponToken, dispatch, resetCryptoCoupon, setCouponData, snackbar]);
  /**
   * If orderType changed remove applied coupon
   */
  useEffect(() => {
    if (couponData && couponData?.orderType && !couponData?.orderType.includes(couponOrderTypeMap[orderType]) && couponToken) {
      removeDiscount();
    }
  }, [couponData, couponToken, orderType, removeDiscount, setCouponData]);

  const pickAndApplyCoupons = useCallback(
    async (sortedCodes: string[]) => {
      if (sortedCodes.length > 0 && isAutoApplyCouponAllowed === true) {
        let tempSortedCodes = [...sortedCodes];
        let codeToApply = tempSortedCodes[0]; // first index coupon code will be maximum as array is sorted in decending manner
        const response: any = await applyCoupon(codeToApply);
        if (response && response.isSuccess) {
          setCouponApplied(true);
        } else {
          if (isAutoApplyCouponAllowed) {
            tempSortedCodes.shift(); // remove first index coupon code which got failed to apply
            pickAndApplyCoupons(tempSortedCodes);
          }
        }
      }
    },
    [applyCoupon, isAutoApplyCouponAllowed]
  );
  // Auto Apply coupon
  useEffect(() => {
    if (!applyCouponLoading && isAutoApplyCouponAllowed && applicableCoupons && applicableCoupons.length > 0 && !couponApplied && couponData === null) {
      /**  
            Prepare coupon codes list sorted by Maximum discount in decending manner.
            If any coupon triggers error try another(next maximum discounted coupon)
        */
      const sortedCodes = getSortedCouponsbyDiscount(applicableCoupons, subtotalToDiscount);
      pickAndApplyCoupons(sortedCodes);
    }
  }, [applyCouponLoading, applicableCoupons, applyCoupon, couponApplied, couponData, isAutoApplyCouponAllowed, pickAndApplyCoupons, subtotalToDiscount]);

  return (
    <Box>
      <ApplyCryptoPromoButton
        isApplyingCoupon={isApplyingCoupon}
        isRemovingCoupon={isRemovingCoupon}
        handleOpen={handleOpen}
        removeDiscount={removeDiscount}
        isDiscountApplied={isDiscountApplied}
        code={appliedCoupon?.code}
        isOpen={isOpen}
        couponData={couponData}
        discount={discount}
        setCouponData={setCouponData}
      />
      <Dialog className={classes.dialog} open={isOpen} fullScreen={fullScreen} fullWidth={true} maxWidth="xs" TransitionComponent={Transition} onClose={handleClose}>
        <DialogHeader text="Apply CashBack Promo" onBack={handleClose} />
        <SelectCouponDialogContent
          subtotalToDiscount={subtotalToDiscount}
          subtotal={subtotal}
          isCartHavingPrediscountedItem={isCartHavingPrediscountedItem}
          isPrediscountEnabled={isPrediscountEnabled}
          handleClose={handleClose}
          applicableCoupons={applicableCoupons}
          notApplicableCoupons={notApplicableCoupons}
          applyCoupon={applyCoupon}
        />
      </Dialog>
    </Box>
  );
};

export default CryptoPromo;

import React from 'react';
import { Box, DialogContent, Typography } from '@material-ui/core';
import { useStyles } from './style';
import ItemHeader from './ItemHeader';
import GroceryStoreItemModal from 'models/GroceryItem';
import ItemActions from './ItemActions';
import CloseIcon from '@material-ui/icons/Close';
import useGroceryReward from 'hooks/groceryStore/useGroceryReward';

interface IProps {
  groceryStoreItem: GroceryStoreItemModal | null;
  handleClose: () => void;
  currency?: 'USD' | 'POINT';
  quantity: number;
  onQuantityChange: (qty: number) => Promise<void>;
}
interface SwipeableHandlers {
  ref(element: HTMLElement | null): void;
  onMouseDown?(event: React.MouseEvent): void;
}
const Content: React.FC<IProps> = ({ groceryStoreItem, handleClose, quantity, onQuantityChange, currency = 'USD' }) => {
  const classes = useStyles();

  const images = groceryStoreItem?.images || [];

  const { remainingPoints } = useGroceryReward();

  const renderContent = () => {
    if (groceryStoreItem) {
      const itemTitle = groceryStoreItem.title || '';

      const itemDescription = groceryStoreItem.description || '';

      const itemSize = groceryStoreItem.size || '';

      const itemBrand = groceryStoreItem.brand || '';
      return (
        <DialogContent className={classes.dialogContent} id={'diaglogWrapper'}>
          <>
            {images ? (
              <Box position="absolute" className={classes.closeIcon} right={16} top={16} borderRadius={4} boxShadow={5} bgcolor="rgba(255,255,255,0.8)" padding={0.5}>
                <CloseIcon onClick={handleClose} color="inherit" style={{ cursor: 'pointer', display: 'flex' }} />
              </Box>
            ) : (
              <Box paddingX={1.5} paddingTop={1} display="flex" justifyContent="flex-end">
                <CloseIcon onClick={handleClose} color="inherit" style={{ cursor: 'pointer', display: 'flex' }} />
              </Box>
            )}
          </>
          <Box marginBottom={1}>
            <ItemHeader itemTitle={itemTitle} itemBrand={itemBrand} itemDescription={itemDescription} itemSize={itemSize} handleClose={handleClose} images={groceryStoreItem.images} />
          </Box>
          {groceryStoreItem.isReward && currency === 'POINT' && remainingPoints > 0 && (
            <Box paddingX={2} display="flex" justifyContent="center" alignContent="center">
              <Typography variant="body1" color="secondary">
                <strong>{remainingPoints}</strong> PTS available
              </Typography>
            </Box>
          )}
          <Box className={classes.dishContainer} paddingY={1}>
            <ItemActions groceryStoreItem={groceryStoreItem} quantity={quantity} onQuantityChange={onQuantityChange} handleClose={handleClose} currency={currency} />
          </Box>
        </DialogContent>
      );
    }

    return null;
  };

  return renderContent();
};

export default Content;

import React from 'react';
import { useStyles } from './style';
import { Box, Typography } from '@material-ui/core';
import S3Image from 'components/S3Image';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';

// install Swiper components
SwiperCore.use([Pagination, Autoplay]);
interface IProps {
  itemSize: string | null;
  itemTitle: string | null;
  itemDescription: string | null;
  handleClose: () => void;
  itemBrand: string | null;
  images: Array<any> | null;
}

const ItemHeader: React.FC<IProps> = ({ itemSize, itemTitle, itemDescription, itemBrand, images, handleClose }) => {
  const classes = useStyles();
  return (
    <>
      {images && (
        <Box className={classes.swiper}>
          <Swiper watchOverflow={true} autoHeight={true} spaceBetween={10} pagination={{ clickable: true }} slidesPerView={1}>
            {images &&
              images.map((imgSrc) => {
                return (
                  <SwiperSlide>
                    <Box height="100%" display="flex" flexDirection="column">
                      <Box height={200} flexGrow="1" boxShadow={5} className={classes.imgWrap} overflow="hidden">
                        {imgSrc.lg && <S3Image className={classes.image} src={imgSrc.lg.url} />}
                      </Box>
                    </Box>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </Box>
      )}

      <Box paddingX={2} paddingTop={!images ? 2 : 0}>
        <Box>
          <Typography variant="h6">
            <Box display="flex" alignItems="center" justifyItems="center">
              {itemTitle} ({itemBrand})
            </Box>
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            <Box display="flex" alignItems="center" justifyItems="center">
              {itemSize}
            </Box>
          </Typography>
        </Box>
      </Box>
    </>
  );
};
export default ItemHeader;

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& a': {
        textDecoration: 'none',
        color: 'inherit'
      },
      background: '#fff',
      borderRadius: 5,
      margin: theme.spacing(1, 0)
    },
    iconsWrapper: {
      position: 'absolute',
      bottom: 10,
      left: 5
    },
    miles: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: blue[900],
      borderRadius: '0.4rem',
      padding: theme.spacing(0.5),
      margin: theme.spacing(0, 2),
      background: blue[100]
    },
    restaurantLogoBox: {
      height: '20%',
      width: '15%',
      borderRadius: '3px',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(1)
    },
    restaurantDetail: {
      fontWeight: 600
    },
    restaurantDetailDot: {
      margin: theme.spacing(0, 0.5),
      fontSize: '6px'
    }
  })
);

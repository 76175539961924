import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography, Box, useMediaQuery, Button } from '@material-ui/core';
import InfoAndHours from './InfoAndHours';
import { AuthContext } from 'fbase/authContext';
import { useStyles } from './style';
import Loader from 'assets/img/dot-loader.svg';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GroceryStoreModel from 'models/GroceryStore';
import Deals from './Deals';
import { useGetGroceryRewardItems, useGetUserRewardsByGroceryStore } from 'graphql/hooks';
import { isNull } from 'lodash';
import GroceryItemsSearch from 'components/GroceryItemsSearch';

interface IProps {
  groceryStore: GroceryStoreModel;
}

const GroceryStoreInfo: React.FC<IProps> = ({ groceryStore }: IProps) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);
  const location = groceryStore.getLatLng;
  const storeId = groceryStore.getId;
  const phone = groceryStore.phone;

  const { data, loading: rewardPointsLoading } = useGetUserRewardsByGroceryStore(storeId);

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const isRewardAvailable = groceryStore.issueRewardPoint;
  const { count: rewardItemCount } = useGetGroceryRewardItems(storeId);

  const [rewardPoints, setRewardPoints] = useState<null | number>(null);

  useEffect(() => {
    if (data && data.getGroceryRewardPointsForConsumer) {
      const activePoints = data.getGroceryRewardPointsForConsumer[0] ? data.getGroceryRewardPointsForConsumer[0].activePoints : 0;
      setRewardPoints(activePoints);
    }
  }, [data]);

  const gotoRewards = () => {
    // If restaurant has reward dishes then redirect to rewards page else return
    if (rewardItemCount === 0 || isNull(groceryStore.rewardSetting)) {
      return;
    }

    history.push(`${groceryStore?.id}/rewards`, {
      from: pathname
    });
  };
  const getRewardPointsText = () => {
    if (currentUser) {
      if (rewardPointsLoading || isNull(rewardPoints)) {
        return <img src={Loader} alt="" />;
      } else {
        return ` ${rewardPoints} PTS`;
      }
    }

    return '';
  };
  return isDesktop ? (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      {storeId && (
        <GroceryItemsSearch
          onSearch={(query) => {
            history.push(`/grocery/${storeId}/search?query=${query}`);
          }}
        />
      )}
      <Box display="flex" alignItems="center" justifyContent="space-evenly">
        {isRewardAvailable && (
          <Button size="small" className={classes.rewardBtn} onClick={gotoRewards} variant="contained" fullWidth={false} color="primary" disableElevation>
            <Box display="flex" flex-direction="column" className={classes.rewardsText}>
              <Typography variant="caption">Rewards&nbsp;</Typography>
              <Typography variant="caption">{getRewardPointsText()}</Typography>
            </Box>
          </Button>
        )}
        <InfoAndHours groceryStore={groceryStore} />
        <Deals groceryStore={groceryStore} />
        {location && (
          <Box marginX={1}>
            <a className={classes.link} target="_blank" rel="noopener noreferrer" href={'https://maps.google.com?q=' + location?.lat + ',' + location?.lng + ''}>
              <LocationOnIcon fontSize="small" className={classes.locationIcon} />
            </a>
          </Box>
        )}
        {phone && (
          <Box marginX={1}>
            <a href={`tel:${phone}`} className={classes.link}>
              <PhoneIcon fontSize="small" className={classes.phoneIcon} />
            </a>
          </Box>
        )}
      </Box>
    </Box>
  ) : (
    <Box bgcolor="#fff" width="100%" display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
      <Box display="flex" justifyContent="start" flexWrap="wrap">
        {isRewardAvailable && (
          <Button size="small" className={classes.rewardBtn} onClick={gotoRewards} variant="contained" fullWidth={false} color="primary" disableElevation>
            <Box display="flex" flex-direction="column" className={classes.rewardsText}>
              <Typography variant="caption">Rewards&nbsp;</Typography>
              <Typography variant="caption">{getRewardPointsText()}</Typography>
            </Box>
          </Button>
        )}
        <InfoAndHours groceryStore={groceryStore} />
        <Deals groceryStore={groceryStore} />
      </Box>
      <Box display="flex" width="auto" alignItems="center" justifyContent="start">
        {location && (
          <Box mr={0.5}>
            <a className={classes.link} target="_blank" rel="noopener noreferrer" href={'https://maps.google.com?q=' + location?.lat + ',' + location?.lng + ''}>
              <LocationOnIcon fontSize="small" className={classes.locationIcon} />
            </a>
          </Box>
        )}
        {phone && (
          <Box component="span">
            <a href={`tel:${phone}`} className={classes.link}>
              <PhoneIcon fontSize="small" className={classes.phoneIcon} />
            </a>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GroceryStoreInfo;

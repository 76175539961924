import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { map } from 'lodash';
import { useStyles } from './style';
import { convertPriceTo } from 'util/number';

interface IProps {
  items: any;
}

const OrderItems = ({ items }: IProps) => {
  const classes = useStyles();

  const Row = ({ children }: any) => {
    return <Box padding={1}>{children}</Box>;
  };

  return (
    <Box paddingX={1} className={classes.root}>
      {map(items, (item: any, index) => {
        const { title, count, size, price } = item;
        return (
          <Row key={index}>
            <Box display="flex">
              <Box flexGrow={1} paddingRight={1}>
                <Typography className={classes.boldText} variant="body1" display="inline">
                  {count} x
                </Typography>
                <Typography className={classes.lightText} style={{ paddingLeft: '8px' }} variant="body1" display="inline">
                  {title}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Box marginRight={0.5}>
                    <Typography className={classes.lightText} variant="body1">
                      {size}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-end" alignItems="baseline">
                {!!price && (
                  <Box className={classes.priceItem}>
                    <Typography variant="body1" className={classes.priceItem} noWrap={true} align="right">
                      ${convertPriceTo(price * count, 'DOLLAR')}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Row>
        );
      })}
    </Box>
  );
};

export default OrderItems;

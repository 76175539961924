import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from 'fbase/authContext';
import useActiveGroceryStore from 'hooks/groceryStore/useActiveGroceryStore';
import { useStore } from 'store';
import { useAddToGroceryStoreCart, useGetGroceryItemsByCategoryId, useGetGroceryStoreById, useGetGroceryStoreCart } from 'graphql/hooks';
import { Grid, Typography, Box, Button, useMediaQuery } from '@material-ui/core';
import { useStyles } from './style';
import { filter, groupBy } from 'lodash';
import { grey } from '@material-ui/core/colors';
import { useHistory, useParams } from 'react-router-dom';
import GroceryItemCard from 'components/GroceryItemCard';
import Sticky from 'components/Sticky';
import Fuse from 'fuse.js';
import GroceryStoreItemModal from 'models/GroceryItem';
import Header from './Header';
import SubCategoryTabs from './SubCategoryTabs';
import BrandFilter from './BrandFilter';
import { useSnackbar } from 'notistack';
import GroceryItemDialog from 'components/GroceryItemDialog';

const itemsPerPage = 25;

const GroceryItems = () => {
  const classes = useStyles();

  const { currentUser } = useContext(AuthContext);

  const { dispatch } = useStore();

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  const [pageSize, setPageSize] = useState(itemsPerPage);

  const { categoryId } = useParams<any>();

  const { groceryStoreId } = useActiveGroceryStore();

  const history = useHistory();

  const [open, setOpen] = useState(false);

  const { groceryStore } = useGetGroceryStoreById(groceryStoreId);

  const { groceryItems } = useGetGroceryItemsByCategoryId(groceryStoreId, categoryId);

  const { groceryCartItems } = useGetGroceryStoreCart(groceryStoreId);

  const { addToGroceryStoreCart } = useAddToGroceryStoreCart(groceryStoreId);

  const [activeSubCategoryId, setActiveSubCategoryId] = useState<string>('');

  const [brandFilter, setBrandFilter] = useState<string[]>([]);

  const [groceryItemsList, setGroceryItemsList] = useState<GroceryStoreItemModal[]>([]);

  const [searchQuery, setSearchQuery] = useState<string>('');

  const [displayItems, setDisplayItems] = useState<GroceryStoreItemModal[]>([]);

  const cartItems = groupBy(groceryCartItems, 'item.id');

  const snackbar = useSnackbar();

  const [isItemDialogOpen, setItemDialogOpen] = useState(false);

  const [activeItem, setActiveItem] = useState<null | GroceryStoreItemModal>(null);

  const selectedCategory = groceryStore?.categories?.filter((category) => category.id === categoryId)[0];

  const onItemSelect = (groceryItem: GroceryStoreItemModal) => {
    setActiveItem(groceryItem);
    setItemDialogOpen(true);
  };

  const handleDialogClose = () => {
    setItemDialogOpen(false);
  };

  useEffect(() => {
    if (groceryItems && groceryItems.length > 0) {
      setGroceryItemsList(groceryItems);
    }
  }, [groceryItems]);

  const loadMoreItems = () => {
    setPageSize((oldVal) => {
      return oldVal + itemsPerPage;
    });
  };

  const onBack = () => {
    history.push('/grocery/' + groceryStoreId);
  };

  const clearFilters = () => {
    setActiveSubCategoryId('');
    setBrandFilter([]);
    setSearchQuery('');
  };

  const onQuantityChange = async (item: GroceryStoreItemModal, qty: number) => {
    if (currentUser) {
      const response = await addToGroceryStoreCart(item, qty, false);

      if (response && response.data && response.data.AddToGroceryStoreCart) {
      } else {
        snackbar.enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    } else {
      openLoginDialog();
    }
  };

  const openLoginDialog = () => {
    dispatch({
      type: 'UI_ACTION_LOGIN_POPUP',
      payload: true
    });
  };

  useEffect(() => {
    let itemsList = groceryItemsList;

    if (activeSubCategoryId !== '' || brandFilter.length > 0) {
      itemsList = filter(groceryItemsList, (item: GroceryStoreItemModal) => {
        if (activeSubCategoryId !== '' && item.subCategoryId !== activeSubCategoryId) {
          return false;
        }

        if (brandFilter.length > 0 && !brandFilter.includes(item.brand)) {
          return false;
        }
        return true;
      });
    }

    if (searchQuery.trim() !== '') {
      const fuse = new Fuse(itemsList, {
        keys: ['title'],
        isCaseSensitive: false,
        shouldSort: true,
        minMatchCharLength: 2
      });
      itemsList = fuse.search(searchQuery).map((res) => res.item);
    }
    setDisplayItems(itemsList);
    setPageSize(itemsPerPage);
  }, [activeSubCategoryId, brandFilter, groceryItemsList, searchQuery]);

  return (
    <Box>
      <Sticky>
        <Box bgcolor={'#fff'}>
          <Box className={classes.groceryContainer} paddingY={1.5}>
            <Header
              setOpen={setOpen}
              isDesktop={isDesktop}
              category={selectedCategory}
              groceryItemsList={groceryItemsList}
              onBack={onBack}
              searchQuery={searchQuery}
              onSearchChange={(q) => setSearchQuery(q)}
              brandFilter={brandFilter}
              clearFilters={clearFilters}
            />

            {groceryStore && (
              <Box bgcolor={isDesktop ? '#fff' : grey[50]} paddingY={1}>
                <SubCategoryTabs
                  isDesktop={isDesktop}
                  category={selectedCategory}
                  groceryStore={groceryStore}
                  activeSubCategoryId={activeSubCategoryId}
                  onTabClickHandler={(id) => setActiveSubCategoryId(id)}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Sticky>
      <Box paddingX={2} paddingY={1} bgcolor={grey[50]}>
        <Box className={classes.groceryContainer}>
          <Grid container={true} spacing={2}>
            {displayItems.slice(0, pageSize).map((item: GroceryStoreItemModal, index: number) => {
              return (
                <Grid item={true} onClick={() => onItemSelect(item)} key={item.id} xs={6} sm={4} md={2}>
                  <GroceryItemCard
                    imagePreview={item.images && item.images[0].preview.url}
                    brand={item.brand}
                    price={item.price}
                    title={item.title}
                    size={item.size}
                    quantity={cartItems[item.id] ? cartItems[item.id][0].quantity : 0}
                    onQuantityChange={(qty: number) => onQuantityChange(item, qty)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>

      {pageSize <= displayItems.length && (
        <Box paddingY={1} display={'flex'} justifyContent={'center'}>
          <Button variant="contained" onClick={loadMoreItems} color="primary" size="small">
            <Typography variant="body1">Load more</Typography>
          </Button>
        </Box>
      )}
      <BrandFilter open={open} onClose={() => setOpen(false)} groceryItemsList={groceryItemsList} brandFilter={brandFilter} setBrandFilter={setBrandFilter} />
      {activeItem && (
        <GroceryItemDialog
          isOpen={isItemDialogOpen}
          handleClose={handleDialogClose}
          groceryStoreItem={activeItem}
          quantity={cartItems[activeItem.id] ? cartItems[activeItem.id][0].quantity : 0}
          onQuantityChange={(qty: number) => onQuantityChange(activeItem, qty)}
        />
      )}
    </Box>
  );
};

export default GroceryItems;

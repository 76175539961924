import { Tab, Tabs, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { useStyles } from './style';

export interface ICategoryTab {
  title: string;
  id: string;
}

interface IProps {
  categories: ICategoryTab[];
  selectedCategoryId: String;
  onSelect: (selectedCategory: string) => void;
}

const CategoryTabs = ({ selectedCategoryId, categories, onSelect }: IProps) => {
  const classes = useStyles();

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  return (
    <Tabs value={selectedCategoryId} classes={{ indicator: classes.tabIndicator, root: classes.tabsContainer }} variant="scrollable" scrollButtons={isDesktop ? 'auto' : 'off'}>
      <Tab disableRipple value="" classes={{ root: classes.customTab, selected: classes.selectedTab }} selected={selectedCategoryId === ''} label="All" onClick={() => onSelect('')} />
      {categories.map((subItem: any, index: number) => {
        return (
          <Tab
            value={subItem.id}
            disableRipple
            classes={{ root: classes.customTab, selected: classes.selectedTab }}
            key={index + 1}
            selected={subItem.id === selectedCategoryId}
            label={subItem.title}
            onClick={() => onSelect(subItem.id)}
          />
        );
      })}
    </Tabs>
  );
};

export default CategoryTabs;

import React, { useState } from 'react';
import { Box, useMediaQuery, Theme, Dialog } from '@material-ui/core';
import Transition from 'components/Transition';
import DialogHeader from 'components/DialogHeader';
import { useStyles } from './style';
import ApplyPromoButton from './ApplyPromoButton';
import { IProps } from './types';
import Content from './Content';

const GroceryPromo: React.FC<IProps> = ({
  storeId,
  coupons,
  orderType,
  subtotalToDiscount,
  subtotal,
  isDiscountApplied,
  appliedCoupon,
  setCouponData,
  isCartHavingPrediscountedItem,
  deliveryTime,
  isPrediscountEnabled
}) => {
  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const classes = useStyles();

  const [isOpen, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box>
      <ApplyPromoButton handleOpen={handleOpen} isDiscountApplied={isDiscountApplied} code={appliedCoupon?.code} setCouponData={setCouponData} />
      <Dialog className={classes.dialog} open={isOpen} fullScreen={fullScreen} fullWidth={true} maxWidth="xs" TransitionComponent={Transition} onClose={handleClose}>
        <DialogHeader text="Apply Promo" onBack={handleClose} />
        <Content
          storeId={storeId}
          coupons={coupons}
          orderType={orderType}
          subtotalToDiscount={subtotalToDiscount}
          subtotal={subtotal}
          isDiscountApplied={isDiscountApplied}
          appliedCoupon={appliedCoupon}
          setCouponData={setCouponData}
          isCartHavingPrediscountedItem={isCartHavingPrediscountedItem}
          deliveryTime={deliveryTime}
          isPrediscountEnabled={isPrediscountEnabled}
          handleClose={handleClose}
        />
      </Dialog>
    </Box>
  );
};

export default GroceryPromo;

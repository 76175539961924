import React from 'react';
import { Box, Typography } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { ReactComponent as DiscountIcon } from 'assets/img/discount.svg';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green, grey } from '@material-ui/core/colors';
import { GroceryCoupon } from 'generated/types';
import { useStyles } from './style';

interface IProps {
  handleOpen: () => void;
  isDiscountApplied: boolean;
  code: string | undefined;
  setCouponData: (payload: { coupon: Partial<GroceryCoupon>; token: string } | null) => void;
}

const ApplyPromoButton: React.FC<IProps> = ({ handleOpen, isDiscountApplied, code, setCouponData }) => {
  const classes = useStyles();

  const removeDiscount = () => {
    setCouponData(null);
  };

  if (isDiscountApplied) {
    return (
      <Box paddingY={1} paddingX={2} display="flex" alignItems="center" marginY={2}>
        <Box marginRight={1} height={23}>
          <CheckCircleOutlineIcon htmlColor={green[400]} />
        </Box>
        <Box flex={1}>
          <Typography variant="subtitle2">Applied code {code}</Typography>
        </Box>
        <Box>
          <Typography variant="caption" component="div" color="error" onClick={removeDiscount}>
            Remove
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box paddingY={1} onClick={handleOpen} bgcolor={grey[100]} marginY={2}>
      <Box paddingY={2} paddingX={1} display="flex" alignItems="center" bgcolor="white" className={classes.applyCode}>
        <Box component="span" marginRight={1} height={20}>
          <DiscountIcon fill={green[300]} width={20} />
        </Box>
        <Box flex={1}>
          <Typography variant="body1" component="div">
            APPLY A PROMO CODE
          </Typography>
        </Box>
        <KeyboardArrowRightIcon />
      </Box>
    </Box>
  );
};

export default ApplyPromoButton;

import { useEffect, useState } from 'react';

import { useLazyQueryWithLoader, useMutationWithLoader, useQueryWithLoader } from 'hooks/loader';

import { CryptoCoupon } from 'generated/types';

import { applyCryptoCouponMutation } from 'graphql/mutations/cryptoCoupon.mutation';
import { resetCryptoCouponMutation } from 'graphql/mutations/resetCryptoCoupon.mutation';
import { getCouponByCouponId, getCryptoCouponsByBizId } from 'graphql/query/cryptoCoupons.query';

// Crypto Promotion Coupons

export const useGetCryptoCouponsByBizId = (bizId: string, email: string | null) => {
  const [coupons, setCoupons] = useState<CryptoCoupon[]>([]);

  const [getCryptoCouponsByBizIdCaller, { data, loading, error }] = useLazyQueryWithLoader(getCryptoCouponsByBizId, {}, true);

  useEffect(() => {
    if (bizId) {
      getCryptoCouponsByBizIdCaller({
        variables: {
          input: {
            bizId,
            email
          }
        }
      });
    }
  }, [bizId, email, getCryptoCouponsByBizIdCaller]);

  useEffect(() => {
    if (data && data.getCryptoCouponsByBizId) {
      const couponsData: CryptoCoupon[] = data.getCryptoCouponsByBizId;
      setCoupons(couponsData);
    }
  }, [data]);

  return {
    coupons,
    loading,
    error
  };
};

export const useApplyCryptoCoupon = () => {
  const [applyCryptoCoupon, { data, loading, error }] = useMutationWithLoader<any, any>(applyCryptoCouponMutation, {}, true);

  return {
    applyCryptoCoupon,
    data,
    loading,
    error
  };
};

export const useResetCryptoCoupon = () => {
  const [resetCryptoCoupon, { data, loading, error }] = useMutationWithLoader<any, any>(resetCryptoCouponMutation, {}, true);

  return {
    resetCryptoCoupon,
    data,
    loading,
    error
  };
};

export const useGetCryptoCouponByCouponId = (couponId: string | null) => {
  const { loading, data, error } = useQueryWithLoader(getCouponByCouponId, {
    skip: !couponId,
    variables: {
      input: {
        couponId
      }
    }
  });

  return {
    loading,
    data,
    error
  };
};

import { Box, Button, Typography } from '@material-ui/core';
import { AuthContext } from 'fbase/authContext';
import useGroceryReward from 'hooks/groceryStore/useGroceryReward';
import React, { useContext } from 'react';
import { useStyles } from './style';
import { useStore } from 'store';
import { grey } from '@material-ui/core/colors';
import Menu from './Menu';
import { useHistory, useLocation } from 'react-router-dom';
import useActiveGroceryStore from 'hooks/groceryStore/useActiveGroceryStore';

const GroceryRewards = () => {
  const classes = useStyles();

  const { dispatch } = useStore();

  const history = useHistory();

  const { groceryStoreId } = useActiveGroceryStore();

  const { state } = useLocation<{ from: string | undefined }>();

  const { totalPoints, redeemablePoints, usedPoints, errorMessage, isMinimumPointsSatisfied, pointsCutoff, isRedeemDisabled, rewardItems, groceryCartRewardItems } = useGroceryReward();

  const { currentUser, isAuthStateChanged } = useContext(AuthContext);

  const handleLoginClick = () => {
    dispatch({
      type: 'UI_ACTION_LOGIN_POPUP',
      payload: true
    });
  };

  const getPointsRow = (label: string, point: number) => {
    return (
      <Box display="flex" justifyContent="space-between">
        <Typography variant="subtitle1">{label}</Typography>
        <Typography className={classes.bold} variant="subtitle1">
          {point} PTS
        </Typography>
      </Box>
    );
  };

  const onDoneHandler = () => {
    if (history.length > 1) {
      if (state && state.from) {
        history.replace(state.from);
      } else {
        history.replace(`/grocery/${groceryStoreId}`);
      }
    } else {
      history.replace(`/grocery/${groceryStoreId}`);
    }
  };

  const header = () => {
    if (!isAuthStateChanged) {
      return '';
    }

    // In case user is not logged in
    if (!currentUser) {
      return (
        <Box textAlign="center" paddingY={1} bgcolor={grey[300]}>
          <Typography color="secondary" variant="body1">
            <u className={classes.loginBtn} onClick={handleLoginClick}>
              Login
            </u>
            &nbsp;to start redeeming points
          </Typography>
        </Box>
      );
    }

    return (
      <Box className={classes.pointsBanner}>
        {getPointsRow('Total Points', totalPoints)}
        {isMinimumPointsSatisfied && redeemablePoints > 0 && getPointsRow('Redeemable Points', redeemablePoints)}
        {usedPoints > 0 && totalPoints > 0 && getPointsRow('Used Points', usedPoints)}
        {pointsCutoff && redeemablePoints > 0 && (
          <Box paddingTop={1} fontStyle="italic">
            <Typography className={classes.bold} variant="caption">
              Max {redeemablePoints} PTS can be redeemed in this purchase
            </Typography>
          </Box>
        )}
        {!isRedeemDisabled && (
          <Box paddingTop={1} fontStyle="italic">
            <Typography className={classes.bold} variant="caption">
              Please choose items from the list below.
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box maxWidth={1200} margin="auto" display="flex" flexDirection="column" height="100%">
      {header()}
      {isRedeemDisabled && (
        <Box padding={1} textAlign="center">
          <Typography color="error" variant="body1">
            {errorMessage}
          </Typography>
        </Box>
      )}
      <Box style={{ overflow: 'scroll', flex: 1 }}>{rewardItems && <Menu items={rewardItems} groceryCartRewardItems={groceryCartRewardItems} />}</Box>
      <Box padding={2}>
        <Button variant="contained" disableElevation={true} color="primary" fullWidth={true} onClick={onDoneHandler}>
          Done
        </Button>
      </Box>
    </Box>
  );
};

export default GroceryRewards;

import React, { useState, useContext, useCallback } from 'react';
import { Box, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useStore } from 'store';
import { CreateGroceryOrderInput, GroceryDeliveryProviders, GroceryOrderLineItemInput, GroceryOrderType, PaymentType } from 'generated/types';
import OrderProcessingScreen from 'pages/Payment/OrderProcessingScreen';
import { AuthContext } from 'fbase/authContext';
import Logger from 'util/logger';
import { useMutation } from '@apollo/react-hooks';
import { useAlert } from 'hooks';
import useActiveGroceryStore from 'hooks/groceryStore/useActiveGroceryStore';
import { useGetGroceryStoreById, useGetGroceryStoreCart } from 'graphql/hooks';
import { createGroceryOrderMutation } from 'graphql/mutations';
import { setLocalStorage } from 'util/storage';
import useGroceryStorePause from 'hooks/groceryStore/useGroceryStorePause';

const PayByCash = () => {
  const history = useHistory();

  const { state } = useStore();

  const [loading, setLoading] = useState(false);

  const { lokobeeUser } = useContext(AuthContext);

  const { lokoAlert } = useAlert();

  const [createOrder] = useMutation(createGroceryOrderMutation);

  const { groceryStoreId } = useActiveGroceryStore();

  const { groceryStore } = useGetGroceryStoreById(groceryStoreId);

  const { groceryCartItems, groceryCartRewardItems, redeemedPoints } = useGetGroceryStoreCart(groceryStoreId);

  const handleSubmit = useCallback(async () => {
    const paymentRoute = `/grocery/${groceryStoreId}/payment`;

    if (!lokobeeUser) {
      history.replace(paymentRoute);

      lokoAlert('Please login to continue.');
      return;
    }

    let email = null;

    if (state.user && state.user.email) {
      email = state.user.email;
    }

    if (!email) {
      lokoAlert('No email provided');
      history.replace(paymentRoute);
      return;
    }

    let orderPhoneNumber = null;

    if (state.user && state.user.orderPhoneNumber) {
      orderPhoneNumber = state.user.orderPhoneNumber;
    }

    if (!orderPhoneNumber) {
      lokoAlert('No phone number provided');
      history.replace(paymentRoute);

      return;
    }

    try {
      if (groceryCartItems && groceryCartItems.length > 0) {
        const {
          grocery_tip,
          grocery_finalPrice,
          grocery_deliveryTime,
          grocery_orderNote,
          grocery_deliveryAddress,
          grocery_deliveryNote,
          grocery_orderType,
          grocery_serviceFee,
          grocery_couponToken,
          grocery_subtotalAfterDiscount,
          grocery_subtotal,
          grocery_isDiscountApplied,
          grocery_doordashQuoteId,
          grocery_dropOffType,
          grocery_apartmentNumber
        } = state;

        if (!grocery_finalPrice) {
          lokoAlert('Total price cannot be null');
          history.replace(paymentRoute);
          return;
        }
        setLoading(true);

        const cartItems: GroceryOrderLineItemInput[] = [];

        groceryCartItems.forEach((item) => {
          cartItems.push({
            itemId: item.item.id,
            categoryId: item.item.categoryId,
            count: item.quantity
          });
        });

        groceryCartRewardItems.forEach((item) => {
          cartItems.push({
            itemId: item.item.id,
            categoryId: item.item.categoryId,
            count: item.quantity,
            isReward: true
          });
        });

        const createOrderInput: CreateGroceryOrderInput = {
          type: grocery_orderType ? grocery_orderType : GroceryOrderType.Takeout,
          sellerStoreId: groceryStoreId || '',
          items: cartItems,
          note: grocery_orderNote.trim() !== '' ? grocery_orderNote : null,
          expectTime: grocery_deliveryTime,
          paymentType: PaymentType.Onsite,
          tip: parseInt(grocery_tip.valueOf()),
          lokobeeFee: groceryStore?.lokobeeFee ? parseInt(grocery_serviceFee?.valueOf() || '0') : null,
          total: parseInt(grocery_finalPrice.valueOf()),
          email,
          orderPhoneNumber,
          smsNotification: true,
          couponToken: grocery_couponToken,
          isAlcohol: false,
          isDiscounted: false,
          redeemPoints: redeemedPoints
        };

        if (grocery_orderType === GroceryOrderType.Delivery) {
          createOrderInput.deliveryAddress = grocery_deliveryAddress;
          createOrderInput.deliveryNote = grocery_deliveryNote;
          createOrderInput.deliveryProvider = groceryStore?.deliveryProvider;
          createOrderInput.deliveryMode = groceryStore?.deliveryMode;

          if (groceryStore?.deliveryProvider === GroceryDeliveryProviders.DoordashClassic) {
            createOrderInput.doordashQuoteId = grocery_doordashQuoteId;
            createOrderInput.dropOffInstruction = grocery_dropOffType;
            createOrderInput.apartmentNumber = grocery_apartmentNumber;
          }
        }

        const response = await createOrder({
          variables: {
            input: createOrderInput
          }
        });

        if (response && response.data) {
          const {
            data: {
              createGroceryOrder: { orderId }
            }
          } = response;

          if (orderId) {
            setLocalStorage(
              'orderState',
              JSON.stringify({
                show: true,
                subtotalAmount: grocery_isDiscountApplied ? grocery_subtotalAfterDiscount.valueOf() : grocery_subtotal.valueOf(),
                issueRewardPoint: groceryStore?.issueRewardPoint ? 'true' : 'false'
              })
            );

            history.push(`/grocery/${groceryStoreId}/success?success=true`);
          }
        } else {
          const { errors } = response;

          if (errors && errors.length) {
            let errMsg = errors[0].message || 'Failed to create order';

            let errMsgLower = errMsg.toLowerCase();

            if (
              errMsgLower.startsWith('incorrect total') ||
              errMsgLower.startsWith('invalid input') ||
              errMsgLower.startsWith('invalid sellerstoreid') ||
              errMsgLower.startsWith('incorrect redeempoints')
            ) {
              errMsg = 'Failed to create order';
            }

            Logger.log('GROCERY ORDER ERROR', {
              errorShownToUser: errMsg,
              error: errors
            });
            throw new Error(errMsg);
          } else {
            Logger.log('GROCERY ORDER ERROR', {
              errorShownToUser: 'Failed to create order',
              error: errors
            });
            throw new Error('Failed to create order');
          }
        }
      } else {
        Logger.log('GROCERY CART ERROR', state);
        throw new Error('GROCERY Cart error');
      }
    } catch (e) {
      console.log(e);
      setLoading(false);

      let errMsg: string = (e as any).message;

      if (errMsg.toLowerCase().startsWith('graphql error:')) {
        errMsg = errMsg.substring(15);
      }

      lokoAlert(errMsg);

      history.push(paymentRoute);
    }
  }, [createOrder, groceryCartItems, groceryCartRewardItems, groceryStore, groceryStoreId, history, lokoAlert, lokobeeUser, redeemedPoints, state]);

  const { deliveryPause, takeOutPause } = useGroceryStorePause({ groceryStore });

  const isPaused = (state.grocery_orderType === GroceryOrderType.Takeout && takeOutPause) || (state.grocery_orderType === GroceryOrderType.Delivery && deliveryPause);

  return (
    <>
      {loading && <OrderProcessingScreen />}
      <Box padding={1}>
        <Button
          variant="contained"
          color="primary"
          fullWidth={true}
          onClick={() => {
            handleSubmit();
          }}
          disabled={isPaused || loading}>
          place order
        </Button>
      </Box>
    </>
  );
};

export default PayByCash;

import React, { useEffect, useState, useContext } from 'react';
import { useGetGroceryStoresByLocation } from 'graphql/hooks';
import { Box, Grid, Typography, TextField } from '@material-ui/core';
import { map } from 'lodash';
import GroceryStoreCard from 'components/GroceryStoreCard';
import { useStore } from 'store';
import { grey } from '@material-ui/core/colors';
import GroceryStoreModel from 'models/GroceryStore';
import Fuse from 'fuse.js';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { useStyles } from '../style';
import { FirebaseContext } from 'fbase';
import { useObject } from 'react-firebase-hooks/database';
import { defaultTimeZone, IPauseDetails } from 'generated/custom';
import moment from 'moment';

interface IPauseData {
  [id: string]: IPauseDetails;
}
const GroceryHomePage = () => {
  const classes = useStyles();

  const {
    state: { user },
    dispatch
  } = useStore();

  const {
    coordinate: { latitude, longitude }
  } = user;

  /*
   * Get restaurant api.
   */
  const { groceryStoreList, loading } = useGetGroceryStoresByLocation({
    lat: latitude,
    lng: longitude,
    radiusInMeter: 500000
  });

  useEffect(() => {
    dispatch({
      type: 'RESET_LASTACTIVERESTAURANT'
    });
    dispatch({
      type: 'CART_ACTION_RESET_ORDER_TYPE_INFO'
    });
    dispatch({
      type: 'GROCERY_CART_ACTION_RESET_ORDER_TYPE_INFO'
    });
  }, [dispatch]);

  const [filteredGroceryStores, setFilteredGroceryStores] = useState<GroceryStoreModel[]>([]);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const { firebase } = useContext(FirebaseContext);

  const [snapshot] = useObject(firebase?.getRealtimeDB().ref(`/groceryOrderPause/`));

  const pauseData: IPauseData | null | undefined = snapshot?.val();

  useEffect(() => {
    setSearchQuery('');
    setFilteredGroceryStores(groceryStoreList);
  }, [groceryStoreList]);

  const onSearchChange = (q: string) => {
    if (q.trim() === '') {
      setFilteredGroceryStores(groceryStoreList);
    } else {
      const fuse = new Fuse(groceryStoreList, {
        keys: ['name'],
        isCaseSensitive: false,
        shouldSort: true,
        minMatchCharLength: 3
      });

      setFilteredGroceryStores(fuse.search(q).map((res) => res.item));
    }

    setSearchQuery(q);
  };

  const renderGroceryStoreList = () => {
    if (loading) {
      return null;
    }

    if (!loading && filteredGroceryStores && filteredGroceryStores.length) {
      return (
        <Grid className={classes.scrollingComponent} container={true} spacing={1}>
          {map(filteredGroceryStores, (groceryStore) => {
            const id = groceryStore.getId;

            const name = groceryStore.name || '';

            const logo = groceryStore.getLogo;

            const lat = groceryStore.getLatLng?.lat;

            const lng = groceryStore.getLatLng?.lng;

            const now = moment.tz(groceryStore?.getTimezone || defaultTimeZone).format('YYYY-MM-DD');

            const takeOutPause = pauseData && pauseData[id] && pauseData[id].TAKEOUT ? pauseData[id].TAKEOUT?.status === 'pause' && now === pauseData[id].TAKEOUT?.pauseDate : false;

            const deliveryPause = pauseData && pauseData[id] && pauseData[id].DELIVERY ? pauseData[id].DELIVERY?.status === 'pause' && now === pauseData[id].DELIVERY?.pauseDate : false;

            return (
              <Grid item={true} key={id} xs={12} sm={6} md={4}>
                <GroceryStoreCard
                  title={name}
                  logo={logo}
                  imgSrc={logo}
                  lat={lat}
                  lng={lng}
                  link={`grocery/${id}`}
                  takeOut={true}
                  dining={true}
                  delivery={true}
                  takeOutPause={takeOutPause}
                  deliveryPause={deliveryPause}
                />
              </Grid>
            );
          })}
        </Grid>
      );
    }

    if (!loading && filteredGroceryStores.length === 0 && filteredGroceryStores.length !== 0) {
      return (
        <Box paddingY={1} textAlign="center">
          <Typography>No matches for your search query.</Typography>
        </Box>
      );
    }

    if (!loading && filteredGroceryStores.length === 0) {
      return (
        <Box paddingY={1} textAlign="center">
          <Typography>No grocery store found in this region</Typography>
        </Box>
      );
    }
  };

  if (!loading) {
    return (
      <Box maxWidth={1200} margin="auto" bgcolor={grey[50]} paddingX={1.5} height="100%" display="flex" flexDirection="column" overflow="hidden">
        {groceryStoreList && groceryStoreList.length !== 0 && (
          <Box paddingY={0.5} paddingTop={1.5}>
            <TextField
              InputProps={{
                startAdornment: <SearchIcon />,
                endAdornment:
                  searchQuery !== '' ? (
                    <ClearIcon
                      onClick={() => {
                        onSearchChange('');
                      }}
                    />
                  ) : null
              }}
              placeholder="Search restaurants..."
              fullWidth={true}
              size="small"
              variant="outlined"
              value={searchQuery}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSearchChange(e.target.value)}
            />
          </Box>
        )}

        {renderGroceryStoreList()}
      </Box>
    );
  }

  return null;
};

export default GroceryHomePage;

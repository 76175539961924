import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: any) => ({
  tabIndicator: {
    display: 'none'
  },
  tabsContainer: {
    minHeight: 'auto'
  },
  customTab: {
    backgroundColor: grey[300],
    borderRadius: '6px',
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1, 1),

    textTransform: 'capitalize',
    minHeight: 'auto',
    lineHeight: 1
  },
  backdrop: {
    zIndex: 1450
  },
  selectedTab: {
    backgroundColor: theme.palette.primary.main + ' !important'
  },
  filterBtn: {
    minWidth: '10px',
    whiteSpace: 'pre'
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  searchBox: {
    border: 'none',
    outline: 'none',
    backgroundColor: '#fff'
  },
  customTipContainer: {
    backgroundColor: 'white',
    height: '50%',
    width: '100%',
    bottom: 0,
    position: 'absolute'
  },

  formControlLabel: {
    width: '100%'
  },
  displayFlex: {
    display: 'flex'
  },
  brandBox: {
    backgroundColor: grey[300],
    borderRadius: '6px',
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1, 1),
    textTransform: 'capitalize',
    minHeight: 'auto',
    lineHeight: 1,
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    marginBottom: theme.spacing(1.5)
  },
  brandTitle: {
    userSelect: 'none'
  },
  groceryContainer: {
    maxWidth: '1200px',
    margin: '0px auto'
  }
}));

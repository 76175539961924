import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { ICouponGroups, ICoupon, ECouponGroups, mapTypes } from './types';
import { useStyles } from './style';
import Moment from 'moment';
import { join, isEmpty } from 'lodash';

interface IProps {
  appliedCode?: string;
  couponGroups: ICouponGroups;
  selectCoupon: (code: string) => void;
}

type TGroups = keyof typeof ECouponGroups;

const Coupons: React.FC<IProps> = ({ couponGroups, appliedCode = '', selectCoupon }) => {
  const classes = useStyles();

  const getCoupons = () => {
    if (!isEmpty(couponGroups)) {
      return Object.keys(couponGroups).map((group: string) => {
        return (
          <Box key={`section-${group}`}>
            <Box className={classes.couponGroup}>
              <Box marginLeft={0.5} marginBottom={1}>
                <Typography variant="body1">{ECouponGroups[group as TGroups]}</Typography>
              </Box>
            </Box>
            <Box paddingRight={1}>
              {couponGroups[group as TGroups]?.map((coupon: ICoupon) => {
                const { code, name, description, orderType, endDate, isApplicable, notApplicableReason, dayOfWeek } = coupon;

                let weekDays = dayOfWeek?.map((day) => {
                  return Moment()
                    .day(day as number)
                    .format('dddd');
                });

                let orders: string[] = [];
                if (orderType && orderType.length) {
                  orderType.forEach((order) => {
                    if (order && !['ALL', 'DINING'].includes(order)) {
                      orders.push(mapTypes[order]);
                    }
                  });
                }

                return (
                  <Box
                    key={code}
                    onClick={() => {
                      if (isApplicable) {
                        selectCoupon(code);
                      }
                    }}
                    className={appliedCode === code && isApplicable ? classes.couponContainerSelected : classes.couponContainer}>
                    <Box display="flex" justifyContent="space-between" paddingBottom={0.5}>
                      <Typography variant="h6">{name}</Typography>
                      <Box alignSelf="flex-start">
                        <Button variant="contained" color="primary" disableElevation={true}>
                          {code}
                        </Button>
                      </Box>
                    </Box>
                    <Typography variant="body1">{description}</Typography>
                    {!!orders && <Typography variant="body1">Valid on: {join(orders, ', ')}</Typography>}
                    <Typography variant="body1">Expires: {Moment(endDate).format('MMM-DD-yyyy')}</Typography>
                    <Typography variant="body1">Valid Days: {join(weekDays, ', ')}</Typography>
                    <br></br>
                    <Typography color="error" variant="body1">
                      {notApplicableReason}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      });
    }
  };

  return <>{getCoupons()}</>;
};

export default Coupons;

import React, { useState } from 'react';
import { Box, Button, Typography, Backdrop, Divider } from '@material-ui/core';
import { useStyles } from './style';
import GroceryStoreModel from 'models/GroceryStore';
import GroceryDiscountedItems from './DiscountedItems';
import Close from '@material-ui/icons/Close';
import GroceryCoupons from './Coupons';

interface IProps {
  groceryStore: GroceryStoreModel;
}

const Deals = ({ groceryStore }: IProps) => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button className={classes.infoHoursBtn} variant="contained" color="primary" size="small" onClick={() => setOpen(true)} disableElevation={true}>
        Deals
      </Button>
      <Backdrop open={open} className={classes.backdrop}>
        <Box height="min-content" className={classes.customTipContainer} maxWidth={700}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box marginBottom={1} className={classes.modalHeader}>
              <Typography variant="h6" className={classes.modalTitle}>
                Coupons and Deals
              </Typography>
              <Close className={classes.modalIcon} onClick={handleClose} />
            </Box>
            <Divider />
            <Box overflow="scroll" flex={1} paddingTop={1}>
              {groceryStore.coupons && groceryStore.coupons.length && groceryStore.coupons.length > 0 && <GroceryCoupons coupons={groceryStore.coupons} clickableCoupons={false} />}
              {open && <GroceryDiscountedItems groceryStore={groceryStore} allowAddToCart={true} />}
            </Box>
          </Box>
        </Box>
      </Backdrop>
    </>
  );
};

export default Deals;

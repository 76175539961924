import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, TextField, Typography, Dialog, FormControl } from '@material-ui/core';
import { GroceryBizHour, GroceryDeliveryEstimateOutput, GroceryDeliveryProviders, GroceryServiceType } from 'generated/types';
import DialogHeader from 'components/DialogHeader';
import { useHistory } from 'react-router-dom';
import useGroceryCart from 'hooks/groceryStore/useGroceryCart';
import DateTimePicker from 'components/DateTimePicker';
import { filter } from 'lodash';
import { GroceryOrderType } from 'generated/types';
import Big from 'big.js';
import AddressField from 'components/AddressModal/InputField';
import { IAddress } from 'components/AddressModal/types';
import geoDistance from 'util/geoDistance';
import GroceryPromo from 'components/GroceryPromo';
import { isFutureDate } from 'util/date';
import { isStoreClosed } from '../util';
import { convertPriceTo } from 'util/number';
import Bill from '../Bill';
import { useGroceryDoordashDeliveryCostEstimateMutation } from 'graphql/hooks/groceryDelivery.hooks';
import { useAlert } from 'hooks';
import { getTimeData } from 'components/DateTimePicker/utils';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { useStyles } from '../style';
import { GroceryDropOffType } from 'generated/custom';
import GroceryStorePause from 'components/GroceryStorePause';
import useGroceryStorePause from 'hooks/groceryStore/useGroceryStorePause';
import { useStore } from 'store';

const SelectOrderType = () => {
  const history = useHistory();

  const [storeClosed, setStoreClosed] = useState(false);

  const [openFutureOrderPopup, setOpenFutureOrderPopup] = useState(false);

  // If address is set from store home page we call estimate api again in this page in useEffect
  // To prevent multiple API calls due to re rendering and useEffect being called again we use this state
  // we block estimate api call if one api is already in progress
  const [blockEstimateAPICall, setBlockEstimateAPICall] = useState(false);

  const {
    groceryStoreId,
    groceryStore,
    orderType,
    subtotal,
    setOrderType,
    deliveryNote,
    setDeliveryNote,
    deliveryAddress,
    setDeliveryAddress,
    setDeliveryMiles,
    tax,
    deliveryMiles,
    deliveryCharge,
    serviceFee,
    setServiceFee,
    setTipAmount,
    finalPrice,
    deliveryTime,
    isDiscountApplied,
    setCouponData,
    discount,
    couponData,
    subtotalAfterDiscount,
    redeemedPoints,
    tip,
    setGroceryDoordashDeliveryEstimate,
    doordashQuoteId,
    dropOffType,
    apartmentNumber,
    setApartmentNumber,
    setDropOffType
  } = useGroceryCart();

  const {
    state: { grocery_selectedPickerDate, grocery_selectedPickerTime }
  } = useStore();

  const classes = useStyles();

  const { getGroceryDoordashEstimate } = useGroceryDoordashDeliveryCostEstimateMutation();

  const { lokoAlert } = useAlert();

  const bizHours = groceryStore?.bizHours;

  const takeoutWaitMinutes = groceryStore?.takeoutWaitMinutes;

  const isTakeoutDisabled = !(groceryStore?.takeoutPaymentSetting?.onlinePaymentTypes || groceryStore?.takeoutPaymentSetting?.onsitePaymentTypes) || !groceryStore.enableTakeoutOrder;

  const isDeliveryDisabled = !(groceryStore?.deliveryPaymentSetting?.onlinePaymentTypes || groceryStore?.deliveryPaymentSetting?.onsitePaymentTypes) || !groceryStore.enableDeliveryOrder;

  const takeoutBizHours: GroceryBizHour[] = bizHours ? filter(bizHours, ({ serviceType }) => serviceType === GroceryServiceType.GroceryTakeout) : [];

  const deliveryBizHours = bizHours ? filter(bizHours, ({ serviceType }) => serviceType === GroceryServiceType.GroceryDelivery) : [];

  const deliveryRadius = groceryStore?.deliverySetting?.deliveryRadius || 0;

  const minOrderValue = groceryStore?.deliverySetting?.minDeliveryAmount || 0;

  const deliveryTimeMins = groceryStore?.deliverySetting?.waitMinutes || 40;

  const { deliveryPause, takeOutPause } = useGroceryStorePause({ groceryStore });

  const [nextSlotStr, setNextSlotStr] = useState('');

  const groceryStoreCoords = {
    lat: groceryStore?.address?.lat || 37.543862,
    lng: groceryStore?.address?.lng || -121.986923
  };

  useEffect(() => {
    // If only one type of service is enabled then select that by default
    if (!isTakeoutDisabled && isDeliveryDisabled) {
      setOrderType(GroceryOrderType.Takeout);
    } else if (isTakeoutDisabled && !isDeliveryDisabled) {
      setOrderType(GroceryOrderType.Delivery);
    } else {
    }
  }, [isDeliveryDisabled, isTakeoutDisabled, setOrderType]);

  useEffect(() => {
    // Check in biz hours if store is closed right now
    if (orderType === GroceryOrderType.Takeout) {
      const restaurnatClosed = isStoreClosed(takeoutBizHours, orderType, groceryStore?.deliveryProvider);
      setStoreClosed(restaurnatClosed);
    }
    if (orderType === GroceryOrderType.Delivery) {
      const restaurnatClosed = isStoreClosed(deliveryBizHours, orderType, groceryStore?.deliveryProvider);
      setStoreClosed(restaurnatClosed);
    }
  }, [deliveryBizHours, groceryStore, orderType, takeoutBizHours]);

  useEffect(() => {
    if (orderType !== null) {
      let service: GroceryServiceType;

      if (orderType === GroceryOrderType.Takeout) {
        service = GroceryServiceType.GroceryTakeout;
      }

      if (orderType === GroceryOrderType.Delivery) {
        service = GroceryServiceType.GroceryDelivery;
      }

      const curHours = filter(bizHours, (hour) => hour.serviceType === service);

      const availableSlots = getTimeData(curHours, 0);

      if (availableSlots.length) {
        const firstSlot = availableSlots[0].availableSlots[0];

        if (firstSlot) {
          const nextSlot = firstSlot.format('LT');

          setNextSlotStr(nextSlot);
        }
      }
    }
  }, [bizHours, orderType]);

  const disabled = () => {
    if (orderType === null) {
      return true;
    }

    if (orderType === GroceryOrderType.Delivery) {
      if (Big(subtotal).lt(Big(minOrderValue))) {
        return true;
      }
      if (!deliveryBizHours.length) {
        return true;
      }

      if (!deliveryAddress) {
        return true;
      }
      const futureOrder = isFutureDate(deliveryTime);
      if (deliveryPause && !futureOrder) {
        return true;
      }

      if (groceryStore?.deliveryProvider === GroceryDeliveryProviders.DoordashClassic) {
        if (!doordashQuoteId) {
          return true;
        }
        if (storeClosed) {
          return true;
        }
      }
    }

    if (orderType === GroceryOrderType.Takeout) {
      if (!takeoutBizHours.length) {
        return true;
      }

      const futureOrder = isFutureDate(deliveryTime);
      if (takeOutPause && !futureOrder) {
        return true;
      }
    }

    return false;
  };

  const onAddressSelect = async (selectedAddress: IAddress) => {
    if (!groceryStore?.deliveryProvider || groceryStore?.deliveryProvider === GroceryDeliveryProviders.Self) {
      const { text, country, state, city, zipcode, lat, lng, street } = selectedAddress;

      const miles = geoDistance(groceryStoreCoords.lat, groceryStoreCoords.lng, selectedAddress.lat, selectedAddress.lng, 'M');

      setDeliveryAddress({
        city,
        country,
        lat,
        lng,
        state,
        street,
        text,
        zipcode
      });

      setDeliveryMiles(Big(miles));
    }

    if (groceryStore?.deliveryProvider === GroceryDeliveryProviders.DoordashClassic) {
      await callDoordashEstimateAPI(selectedAddress);
    }
  };

  const callDoordashEstimateAPI = useCallback(
    async (selectedAddress: IAddress) => {
      if (!blockEstimateAPICall) {
        if (groceryStore) {
          setBlockEstimateAPICall(true);

          const { text, country, state, city, zipcode, lat, lng, street } = selectedAddress;

          const miles = geoDistance(groceryStoreCoords.lat, groceryStoreCoords.lng, selectedAddress.lat, selectedAddress.lng, 'M');

          const curSubtotal = Big(isDiscountApplied ? subtotalAfterDiscount : subtotal);
          const tipVal = tip;

          const orderVal = curSubtotal.add(tipVal).valueOf();

          const input = {
            doordashPickupAddress: {
              zip_code: groceryStore?.address?.zipcode,
              city: groceryStore?.address?.city,
              street: groceryStore?.address?.street,
              full_address: groceryStore?.address?.text
            },
            doordashDropoffAddress: {
              zip_code: selectedAddress.zipcode,
              city: selectedAddress.city,
              street: selectedAddress.street,
              full_address: selectedAddress.text
            },
            deliveryProvider: groceryStore.deliveryProvider,
            deliveryMode: groceryStore.deliveryMode,
            sellerStoreId: groceryStore.id,
            doordashOrderValue: Math.round(parseFloat(orderVal))
          };

          const res = await getGroceryDoordashEstimate({
            variables: {
              input
            }
          });

          if (res && !res.errors) {
            const deliveryEstimate: GroceryDeliveryEstimateOutput = res.data.groceryDeliveryEstimateRequest;

            const deliveryCharge = deliveryEstimate && deliveryEstimate.doordashFee ? Big(deliveryEstimate.doordashFee) : Big('0');
            const quoteId = deliveryEstimate && deliveryEstimate.quote_id ? deliveryEstimate.quote_id : '';

            setDeliveryAddress({
              city,
              country,
              lat,
              lng,
              state,
              street,
              text,
              zipcode
            });

            setGroceryDoordashDeliveryEstimate({ deliveryMiles: Big(miles), deliveryCharge, doordashQuoteId: quoteId });
          } else {
            setDeliveryAddress(null);
            setGroceryDoordashDeliveryEstimate({ deliveryMiles: Big(0), deliveryCharge: Big(0), doordashQuoteId: null });

            lokoAlert('Error getting delivery estimate. Selected address is out of range.');
          }

          setBlockEstimateAPICall(false);
        }
      }
    },
    [
      blockEstimateAPICall,
      getGroceryDoordashEstimate,
      groceryStore,
      groceryStoreCoords.lat,
      groceryStoreCoords.lng,
      isDiscountApplied,
      lokoAlert,
      setDeliveryAddress,
      setGroceryDoordashDeliveryEstimate,
      subtotal,
      subtotalAfterDiscount,
      tip
    ]
  );

  useEffect(() => {
    if (orderType === GroceryOrderType.Delivery && groceryStore?.deliveryProvider === GroceryDeliveryProviders.DoordashClassic && deliveryAddress !== null && !doordashQuoteId) {
      callDoordashEstimateAPI(deliveryAddress);
    }
  }, [callDoordashEstimateAPI, deliveryAddress, doordashQuoteId, groceryStore, orderType]);

  const onBack = () => {
    history.replace(`/grocery/${groceryStoreId}/cart/`);
  };

  const onContinue = () => {
    history.push(`/grocery/${groceryStoreId}/payment`);
  };

  const onAddressClear = () => {
    if (orderType === GroceryOrderType.Delivery && groceryStore?.deliveryProvider === GroceryDeliveryProviders.DoordashClassic) {
      setGroceryDoordashDeliveryEstimate({ deliveryMiles: Big(0), deliveryCharge: Big(0), doordashQuoteId: null });
    }
  };

  const getDeliveryEstimateText = () => {
    if (groceryStore?.deliveryProvider === 'DOORDASH_CLASSIC') {
      return '(ASAP)';
    } else {
      return `(${deliveryTimeMins} mins)`;
    }
  };

  if (groceryStoreId && groceryStore) {
    return (
      <Box maxWidth={1200} margin="auto" display="flex" flexDirection="column" height="100%">
        <DialogHeader text="Choose a service" onBack={onBack} />
        <Box flex={1} display="flex" flexDirection="column" overflow="scroll">
          <Box padding={1}>
            <Box display="flex" alignItems="center">
              <ButtonGroup fullWidth={true} color="secondary">
                {!isTakeoutDisabled && (
                  <Button size="small" disableElevation={true} variant={orderType === GroceryOrderType.Takeout ? 'contained' : 'outlined'} onClick={() => setOrderType(GroceryOrderType.Takeout)}>
                    Takeout ({takeoutWaitMinutes} mins)
                  </Button>
                )}

                {!isDeliveryDisabled && (
                  <Button size="small" disableElevation={true} variant={orderType === GroceryOrderType.Delivery ? 'contained' : 'outlined'} onClick={() => setOrderType(GroceryOrderType.Delivery)}>
                    Delivery {getDeliveryEstimateText()}
                  </Button>
                )}
              </ButtonGroup>
            </Box>
          </Box>
          <Box paddingY={1}>
            {orderType === GroceryOrderType.Takeout && (
              <DateTimePicker
                orgType="GROCERY"
                onChange={() => {}}
                businessHours={bizHours ? bizHours : []}
                waitingTime={takeoutWaitMinutes}
                orderType="TAKEOUT"
                label="Takeout time"
                selectedPickerDate={grocery_selectedPickerDate}
                selectedPickerTime={grocery_selectedPickerTime}
              />
            )}

            {orderType === GroceryOrderType.Delivery && (
              <>
                {groceryStore.deliveryProvider === 'SELF' && (
                  <DateTimePicker
                    orgType="GROCERY"
                    onChange={() => {}}
                    businessHours={bizHours ? bizHours : []}
                    waitingTime={deliveryTimeMins}
                    orderType="DELIVERY"
                    label="Delivery time"
                    selectedPickerDate={grocery_selectedPickerDate}
                    selectedPickerTime={grocery_selectedPickerTime}
                  />
                )}
                <Box padding={1}>
                  <AddressField
                    defaultValue={deliveryAddress ? deliveryAddress.text : ''}
                    onChange={() => setDeliveryAddress(null)}
                    onAddressClear={onAddressClear}
                    onAddressSelect={onAddressSelect}
                    variant="outlined"
                    restaurantLatitude={groceryStoreCoords.lat}
                    restaurantLongitude={groceryStoreCoords.lng}
                    checkRange={true}
                    deliveryRadius={deliveryRadius}
                  />
                </Box>
                <Box padding={1}>
                  <TextField
                    placeholder="Enter delivery note"
                    fullWidth={true}
                    value={deliveryNote}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setDeliveryNote(e.target.value);
                    }}
                    variant="outlined"
                  />
                </Box>
                <Box padding={1}>
                  <TextField
                    placeholder="Apartment Number"
                    fullWidth={true}
                    value={apartmentNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setApartmentNumber(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: <ApartmentIcon className={classes.icon} />
                    }}
                    variant="outlined"
                  />
                </Box>
                <Box padding={1}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      select={true}
                      variant="outlined"
                      label="Drop-off Instruction"
                      value={dropOffType}
                      onChange={(e) => {
                        setDropOffType(e.target.value as GroceryDropOffType);
                      }}
                      SelectProps={{
                        native: true
                      }}
                      fullWidth>
                      <option value={GroceryDropOffType.InHand}>Hand it over in Person</option>
                      <option value={GroceryDropOffType.LeaveAtTheDoor}>Leave it at the Door</option>
                    </TextField>
                  </FormControl>
                  {dropOffType === GroceryDropOffType.InHand && (
                    <Typography color="error" variant="caption">
                      For in Person food hand over, Please follow CDC Mask Guidelines
                    </Typography>
                  )}
                </Box>
              </>
            )}
          </Box>
          <Box>
            {!!orderType && (
              <GroceryPromo
                orderType={orderType}
                coupons={groceryStore?.coupons}
                subtotalToDiscount={subtotal.valueOf()}
                subtotal={subtotal.valueOf()}
                storeId={groceryStore?.id || ''}
                appliedCoupon={couponData}
                isDiscountApplied={isDiscountApplied}
                setCouponData={setCouponData}
                isCartHavingPrediscountedItem={false}
                deliveryTime={deliveryTime}
                isPrediscountEnabled={false}
              />
            )}

            {groceryStore && (
              <Bill
                deliveryAddress={deliveryAddress}
                deliveryCharge={deliveryCharge}
                deliveryMiles={deliveryMiles}
                isServiceFeeEnabled={true}
                orderType={orderType}
                tax={tax}
                subtotal={subtotal}
                serviceFee={serviceFee}
                setServiceFee={setServiceFee}
                setTipAmount={setTipAmount}
                groceryStore={groceryStore}
                totalPriceAfterTax={finalPrice}
                discount={discount}
                isDiscountApplied={isDiscountApplied}
                couponData={couponData}
                subtotalAfterDiscount={subtotalAfterDiscount}
                isCartHavingPrediscountedItem={false}
                isPrediscountEnabled={false}
                subtotalToDiscount={subtotal}
                redeemedPoints={redeemedPoints}
              />
            )}
          </Box>
        </Box>

        <Box padding={1}>
          {storeClosed && (orderType === GroceryOrderType.Takeout || (orderType === GroceryOrderType.Delivery && groceryStore.deliveryProvider === GroceryDeliveryProviders.Self)) && (
            <Typography variant="body1" color="error">
              Store closed right now, but you can place future order.
            </Typography>
          )}

          {storeClosed && orderType === GroceryOrderType.Delivery && groceryStore.deliveryProvider !== GroceryDeliveryProviders.Self && (
            <Typography variant="body1" color="error">
              Store closed right now.{nextSlotStr !== '' ? `You can place order at ${nextSlotStr}` : ''}
            </Typography>
          )}

          {orderType === GroceryOrderType.Delivery && deliveryAddress === null && (
            <Typography variant="body1" color="error">
              Please select delivery address.
            </Typography>
          )}
          {orderType === GroceryOrderType.Delivery && Big(subtotal).lt(Big(minOrderValue)) && (
            <Typography variant="body1" color="error">
              Minimum order value for delivery order is $ {convertPriceTo(minOrderValue, 'DOLLAR')}.
            </Typography>
          )}
          {groceryStore && <GroceryStorePause groceryStore={groceryStore} />}
          <Button
            variant="contained"
            color="primary"
            fullWidth={true}
            disabled={disabled()}
            onClick={() => {
              isFutureDate(deliveryTime) ? setOpenFutureOrderPopup(true) : onContinue();
            }}>
            Continue to payment
          </Button>
          <Dialog open={openFutureOrderPopup}>
            <Box padding={2}>
              <Typography variant="body1">You are placing order for a FUTURE date.This order is NOT for TODAY. Do you still want to proceed to payment?</Typography>
              <Box display="flex" justifyContent="flex-end">
                <Box marginX={1}>
                  <Button variant="contained" color="primary" onClick={onContinue}>
                    Yes
                  </Button>
                </Box>
                <Box marginX={1}>
                  <Button variant="contained" color="primary" onClick={() => setOpenFutureOrderPopup(false)}>
                    No
                  </Button>
                </Box>
              </Box>
            </Box>
          </Dialog>
        </Box>
      </Box>
    );
  }

  return null;
};

export default SelectOrderType;

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SecureCheckoutRoute } from 'components/SecureRoutes';
import Payment from './Payment';
import PayByCard from './PayByCard';
import PayByCash from './PayByCash';
import useActiveGroceryStore from 'hooks/groceryStore/useActiveGroceryStore';
import { useGetGroceryStoreCart } from 'graphql/hooks';

const PaymentRoutes: React.FC = () => {
  const { groceryStoreId } = useActiveGroceryStore();

  const { data, loading } = useGetGroceryStoreCart(groceryStoreId);

  if (loading) {
    return null;
  }

  if (data) {
    if (data && data.getGroceryStoreCart && data.getGroceryStoreCart.items && data.getGroceryStoreCart.items.length) {
      return (
        <Switch>
          <SecureCheckoutRoute>
            <Route exact={true} path="/grocery/:groceryStoreId/payment" component={Payment} />
            <Route path="/grocery/:groceryStoreId/payment/onsite" component={PayByCash} />
            <Route path="/grocery/:groceryStoreId/payment/card" component={PayByCard} />
          </SecureCheckoutRoute>
        </Switch>
      );
    } else {
      return <Redirect to="/" />;
    }
  }

  return null;
};

export default PaymentRoutes;

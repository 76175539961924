import {
  Address,
  GroceryCoupon,
  GroceryDeliveryModes,
  GroceryDeliveryProviders,
  GroceryDoordashDeliveryTimeline,
  GroceryOrder,
  GroceryOrderLineItem,
  GroceryOrderStatus,
  GroceryOrderTimeLine,
  GroceryOrderType,
  LokobeeUser,
  Maybe,
  PaymentType
} from 'generated/types';
import { GroceryStore } from 'models';
import { convertPriceTo } from 'util/number';

class GroceryOrderModel implements Readonly<GroceryOrder> {
  seller!: GroceryStore;
  total?: number;
  items?: Maybe<GroceryOrderLineItem[]> | undefined;
  id!: string;
  requestId!: string;
  createdAt: any;
  updatedAt: any;
  orderNumber!: string;
  subTotal?: Maybe<number> | undefined;
  tip?: Maybe<number> | undefined;
  type!: GroceryOrderType;
  status!: GroceryOrderStatus;
  buyer!: LokobeeUser;
  redeemPoints?: Maybe<number> | undefined;
  expectTime?: any;
  isRated?: Maybe<boolean> | undefined;
  deliveryFee?: Maybe<number> | undefined;
  couponDiscount?: Maybe<number> | undefined;
  coupon?: Maybe<GroceryCoupon> | undefined;
  paymentType?: Maybe<PaymentType> | undefined;
  deliveryDistance?: Maybe<number> | undefined;
  deliveryAddress?: Maybe<Address> | undefined;
  deliveryNote?: Maybe<string> | undefined;
  lokobeeFee?: Maybe<number> | undefined;
  note?: Maybe<string> | undefined;
  adjustAmount?: Maybe<number> | undefined;
  totalAfterAdjust?: Maybe<number> | undefined;
  adjustReason?: Maybe<string> | undefined;
  isDiscounted?: Maybe<boolean> | undefined;
  totalTax?: Maybe<number> | undefined;
  orderPhoneNumber?: Maybe<string> | undefined;
  orderEmail?: Maybe<string> | undefined;
  deliveryProvider?: Maybe<GroceryDeliveryProviders> | undefined;
  deliveryMode?: Maybe<GroceryDeliveryModes> | undefined;
  doordashQuoteId?: Maybe<string> | undefined;
  doordashDeliveryTimeline?: Maybe<Maybe<GroceryDoordashDeliveryTimeline>[]> | undefined;
  timeLines?: Maybe<Maybe<GroceryOrderTimeLine>[]> | undefined;

  get getItems() {
    return this.items?.map((item) => {
      const { title } = item;
      return {
        ...item,
        title: title
      };
    });
  }

  get getGroceryStoreLogo() {
    const { logo } = this.seller;

    const { url } = logo || { url: '' };
    console.log('url', url);
    return url;
  }

  get getGroceryStoreName() {
    const { name } = this.seller;
    return name || '';
  }

  get getTotal() {
    if (this.total) {
      return convertPriceTo(this.total, 'DOLLAR');
    } else {
      return '0.00';
    }
  }
}

export default GroceryOrderModel;

import React, { useContext } from 'react';
import { AuthContext } from 'fbase/authContext';
import { useStore } from 'store';
import { useAddToGroceryStoreCart } from 'graphql/hooks';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import DialogHeader from 'components/DialogHeader';
import { useHistory, useLocation } from 'react-router-dom';
import { blue, grey } from '@material-ui/core/colors';
import { GroceryStoreItem } from 'generated/types';
import { Stepper } from '@lokobee/lokobee-ui';
import DeleteIcon from '@material-ui/icons/Delete';
import { useStyles } from './style';
import { convertPriceTo } from 'util/number';
import { useSnackbar } from 'notistack';
import PlaceOrderBtn from './PlaceOrderBtn';
import useGroceryCart from 'hooks/groceryStore/useGroceryCart';
import useGroceryReward from 'hooks/groceryStore/useGroceryReward';
import { plainToClass } from 'class-transformer';
import GroceryStoreItemModal from 'models/GroceryItem';
import { useAlert } from 'hooks';
import Big from 'big.js';

const GroceryCart = () => {
  const classes = useStyles();

  const { currentUser } = useContext(AuthContext);

  const { dispatch } = useStore();

  const { groceryStoreId, groceryStore, groceryCartItems, groceryCartRewardItems, subtotal, tax, cartLoading } = useGroceryCart();

  const { addToGroceryStoreCart } = useAddToGroceryStoreCart(groceryStoreId);

  const snackbar = useSnackbar();

  const history = useHistory();

  const { pathname } = useLocation();

  const taxModes = groceryStore?.getTaxModes || {};

  const { lokoAlert } = useAlert();

  const { pointsCutoff, redeemablePoints, remainingPoints, isRedeemDisabled, errorMessage } = useGroceryReward();

  const handleBack = () => {
    if (groceryStoreId) {
      history.push(`/grocery/${groceryStoreId}`);
    } else {
      history.push('/');
    }
  };

  const onQuantityChange = async (item: GroceryStoreItem, qty: number, isReward: boolean) => {
    if (currentUser) {
      const response = await addToGroceryStoreCart(plainToClass(GroceryStoreItemModal, item), qty, isReward);

      if (response && response.data && response.data.AddToGroceryStoreCart) {
      } else {
        snackbar.enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    } else {
      openLoginDialog();
    }
  };
  const openLoginDialog = () => {
    dispatch({
      type: 'UI_ACTION_LOGIN_POPUP',
      payload: true
    });
  };

  if (!currentUser) {
    return (
      <Box textAlign="center" paddingY={1} bgcolor={grey[300]}>
        <Typography color="secondary" variant="body1">
          <u className={classes.loginBtn} onClick={openLoginDialog}>
            Login
          </u>
          &nbsp;to start adding items to your cart.
        </Typography>
      </Box>
    );
  }

  if (!cartLoading && groceryCartItems.length === 0 && groceryCartRewardItems.length === 0) {
    return (
      <Box maxWidth={1200} margin="auto" display="flex" flexDirection="column" bgcolor="#fff" height="100%">
        <DialogHeader text="My Cart" onBack={handleBack} />
        <Box bgcolor={blue[100]} paddingY={1} paddingX={2}>
          <Typography variant="subtitle2">{groceryStore?.name || ''}</Typography>
        </Box>
        <Box padding={2}>
          <Typography variant="subtitle2" color="secondary">
            Your shopping cart is empty.&nbsp;
            <u
              className={classes.loginBtn}
              onClick={() => {
                history.push(`/grocery/${groceryStoreId}`);
              }}>
              Start
            </u>
            &nbsp; adding items to your cart.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box maxWidth={1200} margin="auto" display="flex" flexDirection="column" bgcolor="#fff" height="100%">
      <DialogHeader text="My Cart" onBack={handleBack} />
      <Box style={{ overflow: 'scroll', flex: 1 }}>
        <Box bgcolor={blue[100]} paddingY={1} paddingX={2}>
          <Typography variant="subtitle2">{groceryStore?.name || ''}</Typography>
        </Box>
        <Box bgcolor={grey[100]} paddingX={2} paddingY={1}>
          {groceryCartItems.map((item) => {
            if (item.item) {
              const { id, title, size, taxMode } = item.item;

              return (
                <Box key={id} paddingY={0.5} display="flex" justifyContent="space-between">
                  <Box>
                    <Typography variant="body1">{title}</Typography>
                    <Box color={grey[500]}>
                      <Typography variant="body1">{size}</Typography>
                    </Box>
                    {taxMode && taxModes[taxMode] && <Typography variant="body1">Tax : {taxModes[taxMode] * 100}%</Typography>}
                  </Box>
                  <Box display="flex">
                    <Box>
                      <DeleteIcon fontSize="small" className={classes.deleteBtn} onClick={() => onQuantityChange(item.item, 0, false)} />
                    </Box>
                    <Box>
                      <Stepper value={item.quantity} onChange={(val) => onQuantityChange(item.item, val, false)} />
                      <Typography variant="body1" align="right">
                        ${convertPriceTo(item.item.price * item.quantity, 'DOLLAR')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            }
            return null;
          })}
        </Box>
        {groceryCartRewardItems.length > 0 && (
          <>
            <Box marginY={1}>
              <Divider />
            </Box>
            <Box bgcolor={grey[100]} paddingX={2} paddingY={1}>
              {groceryCartRewardItems.map((item) => {
                if (item.item) {
                  const { id, title, size, rewardPoints } = item.item;

                  const isAddPossible = Big(remainingPoints).gte(rewardPoints || 0) && !isRedeemDisabled;

                  return (
                    <Box key={id} paddingY={0.5} display="flex" justifyContent="space-between">
                      <Box>
                        <Typography variant="body1">{title}</Typography>
                        <Box color={grey[500]}>
                          <Typography variant="body1">{size}</Typography>
                        </Box>
                      </Box>
                      <Box display="flex">
                        <Box>
                          <DeleteIcon fontSize="small" className={classes.deleteBtn} onClick={() => onQuantityChange(item.item, 0, true)} />
                        </Box>
                        <Box>
                          <Stepper
                            value={item.quantity}
                            onChange={(val) => {
                              if (val < item.quantity) {
                                onQuantityChange(item.item, val, true);
                              } else {
                                if (isAddPossible) {
                                  onQuantityChange(item.item, val, true);
                                } else {
                                  lokoAlert('Not enough points to add one more item');
                                }
                              }
                            }}
                          />
                          <Typography variant="body1" align="right">
                            {rewardPoints} PTS
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                }
                return null;
              })}
            </Box>
          </>
        )}
      </Box>

      <Box marginX={2} marginTop={2}>
        {!isRedeemDisabled && remainingPoints > 0 && (
          <>
            <Button
              variant="contained"
              disableElevation={false}
              color="primary"
              fullWidth={true}
              onClick={() => {
                history.push(`/grocery/${groceryStoreId}/rewards`, {
                  from: pathname
                });
              }}>
              Redeem Rewards ({remainingPoints} PTS)
            </Button>

            {pointsCutoff && redeemablePoints > 0 && (
              <Box paddingTop={1} textAlign="center" fontStyle="italic">
                <Typography color="secondary" variant="body1">
                  Max {redeemablePoints} PTS can be redeemed in this purchase
                </Typography>
              </Box>
            )}
          </>
        )}

        {isRedeemDisabled && (
          <Box textAlign="center" fontStyle="italic">
            <Typography color="secondary" variant="body1">
              {errorMessage}
            </Typography>
          </Box>
        )}
      </Box>

      <PlaceOrderBtn total={convertPriceTo(subtotal.valueOf(), 'DOLLAR')} tax={convertPriceTo(tax ? tax?.valueOf() : 0, 'DOLLAR')} />
    </Box>
  );
};

export default GroceryCart;

import React, { useState, useEffect, useContext, useCallback } from 'react';
import { IDish } from 'pages/MyCart/CheckoutSummary/types';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Button, Typography, Divider } from '@material-ui/core';
import CheckoutSummary from '../CheckoutSummary';
import DialogHeader from 'components/DialogHeader';
import PlaceOrder from '../PlaceOrder';
import OrderNote from '../OrderNote';
import { useStore } from 'store';
import { useReward } from 'hooks/restaurant';
import { find } from 'lodash';
import { AuthContext } from 'fbase/authContext';
import { useResetCryptoCoupon } from 'graphql/hooks';
import useCart from 'hooks/useCart';
import { convertPriceTo } from 'util/number';

interface IProps {
  cartItems: IDish[];
  cartRewardItems: IDish[];
  handleClose: () => void;
  subtotal: string;
  orderNote: string;
  setOrderNote: (payload: string) => void;
  togglePrediscount: (payload: boolean) => void;
  setDeliveryTime: (payload: number) => void;
}

const MainScreen = ({ cartItems, cartRewardItems, handleClose, subtotal, orderNote, setOrderNote, togglePrediscount, setDeliveryTime }: IProps) => {
  const history = useHistory();

  const { pathname } = useLocation();
  const { setCouponData, couponData, subtotalToDiscount } = useCart();

  const { resetCryptoCoupon } = useResetCryptoCoupon();
  const { currentUser } = useContext(AuthContext);
  const isAnonymousUser = currentUser && currentUser.isAnonymous ? currentUser.isAnonymous : null;
  const {
    dispatch,
    state: { cartRestaurant, lastActiveRestaurant, couponToken, cartSessionId }
  } = useStore();

  const restaurantId = cartRestaurant?.id;

  const { pointsCutoff, redeemablePoints, remainingPoints, isRedeemAllowed, errorMessage } = useReward();

  const [isCartAlcoholic, setIsCartAlcoholic] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'CART_ACTION_TOGGLE_COUPON_AUTO_APPLY',
      payload: true
    });
  }, [dispatch]);

  useEffect(() => {
    //Enabling pre discount on cart to show discounted dish price
    togglePrediscount(true);
    setDeliveryTime(Date.now());
  }, [setDeliveryTime, togglePrediscount]);

  useEffect(() => {
    const isAlcoholic = checkIfHasAlcohol(cartItems);

    const isRewardAlcoholic = checkIfHasAlcohol(cartRewardItems);

    setIsCartAlcoholic(!!isAlcoholic || !!isRewardAlcoholic);
  }, [cartItems, cartRewardItems]);

  const removeDiscount = useCallback(async () => {
    const response = await resetCryptoCoupon({
      variables: {
        input: {
          token: couponToken,
          sessionId: cartSessionId
        }
      }
    });
    if (response && response.data) {
      setCouponData(null);
    }
  }, [cartSessionId, couponToken, resetCryptoCoupon, setCouponData]);

  // useEffect(() => {
  //   // If subtotalToDiscount is reduced below applied coupon minimum purchase on qunatity change, remove that coupon
  //   if (couponToken && couponData && couponData.minSpending && parseInt(convertPriceTo(subtotalToDiscount, 'CENT')) < couponData?.minSpending) {
  //     removeDiscount();
  //   }
  // }, [couponData, couponToken, removeDiscount, subtotalToDiscount]);
  useEffect(() => {
    if (couponToken && cartSessionId) {
      removeDiscount();
    }
  }, [cartSessionId, couponToken, removeDiscount]);

  const checkIfHasAlcohol = (items: IDish[]) => {
    const isAlcoholic = find(items, (item) => item.isAlcohol);

    return !!isAlcoholic;
  };

  const redeemHandler = () => {
    history.push(`restaurants/${restaurantId}/rewards`, {
      from: pathname
    });
  };

  const handleBack = () => {
    if (lastActiveRestaurant) {
      history.push(`restaurants/${lastActiveRestaurant}`);
    } else {
      history.push('/');
    }
  };
  return (
    <Box maxWidth={1200} margin="auto" display="flex" flexDirection="column" bgcolor="#fff" height="100%">
      <DialogHeader text="My Cart" onBack={handleBack} />
      {cartRestaurant && (
        <Box marginX={2} marginY={1} display="flex">
          {cartRestaurant.getName && (
            <>
              <Typography>{cartRestaurant.getName}</Typography> &nbsp;
            </>
          )}
          {cartRestaurant.getSubName && <Typography variant="caption">({cartRestaurant.getSubName})</Typography>}
        </Box>
      )}
      <Box style={{ overflow: 'scroll', flex: 1 }}>
        {!!cartItems.length && (
          <CheckoutSummary
            summary={{
              dishes: cartItems,
              currency: 'USD'
            }}
          />
        )}
        {!!cartRewardItems.length && (
          <>
            <Box marginY={1}>
              <Divider />
            </Box>
            <CheckoutSummary
              summary={{
                dishes: cartRewardItems,
                currency: 'POINT'
              }}
            />
          </>
        )}
      </Box>
      {!isAnonymousUser && (
        <Box marginX={2} marginTop={2}>
          {isRedeemAllowed && remainingPoints > 0 && (
            <>
              <Button variant="contained" disableElevation={false} color="primary" fullWidth={true} onClick={redeemHandler}>
                Redeem Rewards ({remainingPoints} PTS)
              </Button>

              {pointsCutoff && redeemablePoints > 0 && (
                <Box paddingTop={1} textAlign="center" fontStyle="italic">
                  <Typography color="secondary" variant="body1">
                    Max {redeemablePoints} PTS can be redeemed in this purchase
                  </Typography>
                </Box>
              )}
            </>
          )}

          {!isRedeemAllowed && (
            <Box textAlign="center" fontStyle="italic">
              <Typography color="secondary" variant="body1">
                {errorMessage}
              </Typography>
            </Box>
          )}
        </Box>
      )}

      <OrderNote note={orderNote} onChange={setOrderNote} />
      <PlaceOrder total={subtotal} showAlcoholAgreement={isCartAlcoholic} />
    </Box>
  );
};

export default MainScreen;

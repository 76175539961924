import React, { ChangeEvent, useState, useEffect, useCallback } from 'react';
import { Box, Typography, Button, CircularProgress, DialogContent, InputAdornment, TextField } from '@material-ui/core';
import { useApolloClient } from '@apollo/react-hooks';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { verifyGroceryCouponQuery } from 'graphql/query';
import { useStyles } from './style';
import { GroceryCoupon } from 'generated/types';
import Big from 'big.js';
import Moment from 'moment';
import RedeemIcon from '@material-ui/icons/Redeem';
import { convertPriceTo } from 'util/number';
import { useAlert } from 'hooks';
import { QUALIFIED, NOT_QUALIFIED } from 'strings';
import { ICouponGroups, ICoupon, couponOrderTypeMap } from './types';
import Coupons from './Coupons';
import { GroceryOrderType } from 'generated/types';
import { useGetGroceryStoreById } from 'graphql/hooks';

interface IProps {
  storeId: string;
  coupons: GroceryCoupon[] | null | undefined;
  orderType: GroceryOrderType | null;
  subtotalToDiscount: string;
  subtotal: string;
  isDiscountApplied: boolean;
  appliedCoupon: Partial<GroceryCoupon> | null;
  isCartHavingPrediscountedItem: boolean;
  isPrediscountEnabled: boolean;
  deliveryTime: number;
  setCouponData: (payload: { coupon: Partial<GroceryCoupon>; token: string } | null) => void;
  handleClose: () => void;
}

const Content: React.FC<IProps> = ({
  storeId,
  coupons,
  orderType,
  subtotalToDiscount,
  subtotal,
  isDiscountApplied,
  appliedCoupon,
  setCouponData,
  isCartHavingPrediscountedItem,
  deliveryTime,
  isPrediscountEnabled,
  handleClose
}) => {
  const { loading } = useGetGroceryStoreById(storeId);

  const classes = useStyles();

  const snackbar = useSnackbar();

  const client = useApolloClient();

  const { lokoAlert } = useAlert();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [couponGroups, setCouponGroups] = useState<ICouponGroups | null>(null);

  const [applicableCodes, setApplicableCodes] = useState<string[] | null>(null);

  const [notApplicableCodes, setNotApplicableCodes] = useState<string[] | null>(null);

  const isCouponApplicable = useCallback(
    (coupon: ICoupon) => {
      const { orderType: couponOrderType, subtotalThreshold, dayOfWeek, startDate, endDate, maxUse, useCnt } = coupon;

      let subtotalEligible = true;

      let dayOfWeekEligible = true;

      let isNotExpired = true;

      let orderTypeEligible = true;

      let useEligible = true;

      let isEligible: boolean | undefined = false;

      if (!!subtotalThreshold) {
        //const minPurchase = convertPriceTo(subtotalThreshold, 'DOLLAR');

        subtotalEligible = Big(subtotalThreshold).lte(Big(subtotalToDiscount));
      }

      if (!!dayOfWeek) {
        const dayToday = Moment(deliveryTime).day();

        dayOfWeekEligible = dayOfWeek.includes(dayToday);
      }

      if (startDate && endDate) {
        const deliveryDate = Moment(deliveryTime).format('yyyy-MM-DD');

        isNotExpired = Moment(deliveryDate).isBetween(startDate, endDate, undefined, '[]');
      }

      if (!!couponOrderType && couponOrderType.length) {
        orderTypeEligible = couponOrderType?.includes(couponOrderTypeMap[orderType || 'TAKEOUT']);
      }

      if (!!maxUse && !!useCnt && Big(useCnt).gte(maxUse)) {
        useEligible = false;
      }

      isEligible = !['0.00', '0', '.0', ''].includes(subtotalToDiscount) && orderTypeEligible && subtotalEligible && dayOfWeekEligible && isNotExpired && useEligible;

      return isEligible;
    },
    [deliveryTime, orderType, subtotalToDiscount]
  );

  useEffect(() => {
    if (coupons) {
      const applicable: ICoupon[] = [];

      const available: ICoupon[] = [];

      const applicableCodes: string[] = [];

      const notApplicableCodes: string[] = [];

      coupons?.forEach((coupon: ICoupon) => {
        if (isCouponApplicable(coupon)) {
          coupon.isApplicable = true;

          applicableCodes.push(coupon.code);

          applicable.push(coupon);
        } else {
          coupon.isApplicable = false;

          notApplicableCodes.push(coupon.code);

          available.push(coupon);
        }
      });

      const couponGroups: ICouponGroups = {};

      if (applicable.length) {
        couponGroups.applicable = applicable;
      }

      if (available.length) {
        couponGroups.available = available;
      }

      setCouponGroups(couponGroups);

      setApplicableCodes(applicableCodes);

      setNotApplicableCodes(notApplicableCodes);
    }
  }, [coupons, isCouponApplicable, orderType, subtotalToDiscount]);

  /**
   * Removing applied coupon if not eligible
   */
  useEffect(() => {
    if (isDiscountApplied && !!appliedCoupon) {
      const isPublicCouponNotEligible = !appliedCoupon.isPrivate && !!applicableCodes && appliedCoupon.code && !applicableCodes.includes(appliedCoupon.code);

      const isPrivateCouponNotEligible = appliedCoupon.isPrivate && !isCouponApplicable(appliedCoupon as ICoupon);

      if (['0.00', '0', '.0', ''].includes(subtotalToDiscount)) {
        lokoAlert(`Item(s) in the cart are already at discounted price. Applied coupon <b>${appliedCoupon.code}</b> not eligible for this purchase`);
        setCouponData(null);
      } else if (isPublicCouponNotEligible || isPrivateCouponNotEligible) {
        lokoAlert(`Applied coupon <b>${appliedCoupon.code}</b> not eligible for this purchase`);
        setCouponData(null);
      }
    }
  }, [setCouponData, subtotalToDiscount, applicableCodes, lokoAlert, isDiscountApplied, appliedCoupon, isCouponApplicable]);

  const formik = useFormik({
    initialValues: {
      code: ''
    },
    onSubmit: async ({ code }, { resetForm, setSubmitting }) => {
      setSubmitting(true);

      try {
        //Checking if coupon is applicable on the cart
        if (['0.00', '0', '.0', ''].includes(subtotalToDiscount)) {
          throw Error('Item(s) in the cart are already at discounted price. Coupon will not apply');
        }

        if (applicableCodes && !applicableCodes.includes(code) && notApplicableCodes && notApplicableCodes.includes(code)) {
          throw Error('Coupon not applicable.');
        }

        const { data } = await client.query({
          query: verifyGroceryCouponQuery,
          variables: {
            input: {
              storeId,
              subtotal: parseInt(subtotalToDiscount),
              code,
              verifyDay: Moment(deliveryTime).format('YYYY-MM-DD'),
              orderType: couponOrderTypeMap[orderType || 'TAKEOUT']
            }
          },
          fetchPolicy: 'network-only'
        });

        if (data && data.verifyGroceryCoupon && data.verifyGroceryCoupon.success) {
          if (data.verifyGroceryCoupon.coupon && data.verifyGroceryCoupon.token) {
            setCouponData({
              coupon: data.verifyGroceryCoupon.coupon,
              token: data.verifyGroceryCoupon.token
            });

            if (isCartHavingPrediscountedItem && isPrediscountEnabled) {
              snackbar.enqueueSnackbar('Some Item(s) in the cart are already discounted. Coupons will not apply on them.', { variant: 'info', autoHideDuration: 4000 });
            }
          }

          resetForm();

          handleClose();
        } else {
          if (data.verifyGroceryCoupon.error === 'INVALID_COUPON_CODE') {
            throw Error('Invalid coupon code. Please try another coupon');
          }

          if (data.verifyGroceryCoupon.error === 'COUPON_EXPIRED') {
            throw Error('Coupon has expired. Please try another coupon');
          }

          if (data.verifyGroceryCoupon.error === 'EXCEED_MAX_USE') {
            throw Error('Maximun usage has exceeded. Please try another coupon');
          }

          if (data.verifyGroceryCoupon.error === 'DAY_OF_WEEK') {
            throw Error('Coupon not applicable today');
          }

          if (data.verifyGroceryCoupon.error === 'COUPON_ORDER_TYPE') {
            throw Error('Coupon not applicable for this order type');
          }

          if (data.verifyGroceryCoupon.error === 'LOW_SUBTOTAL') {
            let errorMessage = '';

            if (data?.verifyGroceryCoupon?.coupon?.subtotalThreshold) {
              const subtotalThresholdCents = data?.verifyGroceryCoupon?.coupon?.subtotalThreshold;

              const subtotalThreshold = Big(subtotalThresholdCents)
                .div(100)
                .toString();

              errorMessage = `A minimum purchase of $${subtotalThreshold} is required`;
            } else {
              errorMessage = `Minimum purchase required`;
            }
            throw Error(errorMessage);
          }

          throw Error('Something went wrong');
        }

        setSubmitting(false);
      } catch (e) {
        setSubmitting(false);

        setErrorMessage((e as any)?.message);
      }
    }
  });

  const { values, setFieldValue } = formik;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(null);

    if (e.target.value.includes(' ')) {
      return false;
    }

    e.target.value = e.target.value.toUpperCase();

    formik.handleChange(e);
  };

  const getHelperText = () => {
    return (
      <Typography className={classes.boldText} variant="body2">
        {errorMessage}
      </Typography>
    );
  };

  const selectCoupon = (code: string) => {
    setErrorMessage(null);
    setFieldValue('code', code);
  };

  return !loading ? (
    <DialogContent className={classes.dialogContent}>
      <Box className={classes.container}>
        <Box paddingX={1} paddingY={2}>
          <TextField
            name="code"
            value={formik.values.code}
            variant="outlined"
            fullWidth={true}
            placeholder="Enter Promo Code"
            InputProps={{
              style: { textAlign: 'center' },
              startAdornment: (
                <InputAdornment position="start">
                  <RedeemIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            onChange={onChange}
            style={{ textAlign: 'center' }}
            error={!!errorMessage}
            helperText={getHelperText()}
          />
        </Box>
        {!!applicableCodes && applicableCodes.length === 0 && (
          <Box textAlign="center">
            <Typography style={{ fontWeight: 'bold' }} variant="body1" color="error">
              No Coupons found for this Purchase ...
            </Typography>
          </Box>
        )}
        {isCartHavingPrediscountedItem && isPrediscountEnabled && (
          <Box textAlign="center" paddingX={2}>
            <Typography style={{ fontWeight: 'bold' }} variant="body1" color="secondary">
              {Big(subtotalToDiscount).gt(0) ? `${QUALIFIED} $${subtotalToDiscount}` : NOT_QUALIFIED}
            </Typography>
            <Typography style={{ fontWeight: 'bold' }} variant="body1" color="secondary">
              Already Discounted Items don’t Qualify
            </Typography>
          </Box>
        )}
        <Box paddingX={1} flex={1} overflow="scroll">
          {couponGroups && <Coupons appliedCode={values.code} couponGroups={couponGroups} selectCoupon={selectCoupon} />}
        </Box>
        <Box marginX={1} marginY={1} display="flex" boxShadow={1} flex="0 0 auto">
          <Box flex="1 1 0" paddingY={1} paddingX={2}>
            <Typography variant="body1">
              <Box fontWeight="500">Total: ${convertPriceTo(subtotal, 'DOLLAR')}</Box>
            </Typography>
          </Box>
          <Button disabled={!formik.dirty} className={classes.paymentButton} variant="contained" disableElevation={true} color="primary" fullWidth={true} onClick={formik.submitForm}>
            {formik.isSubmitting ? <CircularProgress style={{ width: '25px', height: '25px', color: '#fff' }} /> : 'Apply'}
          </Button>
        </Box>
      </Box>
    </DialogContent>
  ) : (
    <></>
  );
};

export default Content;

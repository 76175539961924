import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Big from 'big.js';
import { IAddress } from 'components/AddressModal/types';
import { GroceryOrderType } from 'generated/types';
import GroceryServiceFeeDetailsDialog from './GroceryServiceFeeDetailsDialog';
import { convertPriceTo } from 'util/number';
import Tip from '../Tip';
import GroceryStoreModel from 'models/GroceryStore';
import { GroceryCoupon } from 'generated/types';
import { green } from '@material-ui/core/colors';

interface IProps {
  tax: Big | null;
  deliveryCharge: Big | null;
  deliveryMiles: Big | null;
  orderType: GroceryOrderType | null;
  deliveryAddress: IAddress | null;
  subtotal: Big | null;
  totalPriceAfterTax: Big | null;
  serviceFee: Big | null;
  isServiceFeeEnabled: boolean;
  setServiceFee: (payload: Big) => void;
  groceryStore: GroceryStoreModel;
  setTipAmount: (payload: Big) => void;
  discount: Big | null;
  isDiscountApplied: boolean;
  couponData: Partial<GroceryCoupon> | null;
  subtotalAfterDiscount: Big | null;
  isCartHavingPrediscountedItem: boolean;
  isPrediscountEnabled: boolean;
  subtotalToDiscount: Big | null;
  redeemedPoints: number;
}

const Bill = ({
  groceryStore,
  setTipAmount,
  orderType,
  deliveryCharge,
  deliveryMiles,
  subtotal,
  deliveryAddress,
  serviceFee,
  tax,
  isServiceFeeEnabled,
  setServiceFee,
  totalPriceAfterTax,
  couponData,
  discount,
  isDiscountApplied,
  subtotalAfterDiscount,
  redeemedPoints
}: IProps) => {
  const taxAndFees = serviceFee ? tax?.add(serviceFee).valueOf() : tax?.valueOf();

  return (
    <Box paddingX={2} paddingY={1}>
      <Box display="flex" justifyContent="space-between" paddingY={1}>
        <Typography variant="body2">Sub Total</Typography>
        <Typography variant="body2">${convertPriceTo(subtotal?.valueOf() || 0, 'DOLLAR')}</Typography>
      </Box>
      {redeemedPoints > 0 && (
        <Box display="flex" justifyContent="space-between" paddingY={1}>
          <Typography variant="body2">PTS Redeemed</Typography>
          <Typography variant="body2">{redeemedPoints}</Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" paddingY={1}>
        <Box display="flex" justifyItems="center" alignItems="center">
          <Typography variant="body2">{isServiceFeeEnabled ? 'Tax and fees' : 'Tax'}</Typography>
          {isServiceFeeEnabled && <GroceryServiceFeeDetailsDialog tax={tax ? tax : Big(0)} setServiceFee={setServiceFee} />}
        </Box>

        <Typography variant="body2">${convertPriceTo(taxAndFees || '0', 'DOLLAR')}</Typography>
      </Box>
      {isDiscountApplied && discount && (
        <Box color={green[900]} display="flex" justifyContent="space-between" paddingY={1}>
          <Typography variant="body2">Discount - ({couponData?.code})</Typography>
          <Typography variant="body2">-${convertPriceTo(discount?.valueOf() || 0, 'DOLLAR')}</Typography>
        </Box>
      )}
      {orderType === 'DELIVERY' && deliveryAddress && (
        <Box display="flex" justifyContent="space-between" paddingY={1}>
          <Typography variant="body2" component="div">
            <Box fontWeight="500">Delivery Charge ( {deliveryMiles?.toFixed(2)} Miles)</Box>
          </Typography>
          <Typography variant="body2" align="right">
            ${convertPriceTo(deliveryCharge?.valueOf() || 0, 'DOLLAR')}
          </Typography>
        </Box>
      )}
      <Box paddingTop={1}>
        <Tip groceryStore={groceryStore} setTipAmount={setTipAmount} total={subtotalAfterDiscount?.toFixed(2) || '0.00'} orderType={orderType} />
      </Box>
      <Box display="flex" justifyContent="space-between" paddingY={1}>
        <Typography variant="body2" component="div">
          <Box fontWeight="500">Total</Box>
        </Typography>
        <Typography variant="body2" align="right">
          ${convertPriceTo(totalPriceAfterTax?.valueOf() || 0, 'DOLLAR')}
        </Typography>
      </Box>
    </Box>
  );
};

export default Bill;

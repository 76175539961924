import React, { useContext } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import GroceryItemCard from 'components/GroceryItemCard';
import { AuthContext } from 'fbase/authContext';
import { useAddToGroceryStoreCart, useGetGroceryDiscountedItems, useGetGroceryStoreCart } from 'graphql/hooks';
import { groupBy } from 'lodash';
import GroceryStoreItemModal from 'models/GroceryItem';
import GroceryStoreModel from 'models/GroceryStore';
import { useSnackbar } from 'notistack';

import { useStore } from 'store';

interface IProps {
  groceryStore: GroceryStoreModel;
  allowAddToCart: boolean;
}

const GroceryDiscountedItems = ({ groceryStore, allowAddToCart }: IProps) => {
  const { currentUser } = useContext(AuthContext);

  const { dispatch } = useStore();

  const { discountedItems, called } = useGetGroceryDiscountedItems(groceryStore.id);

  const { groceryCartItems } = useGetGroceryStoreCart(groceryStore.id);

  const { addToGroceryStoreCart } = useAddToGroceryStoreCart(groceryStore.id);

  const cartItems = groupBy(groceryCartItems, 'item.id');

  const snackbar = useSnackbar();

  const onQuantityChange = async (item: GroceryStoreItemModal, qty: number) => {
    if (currentUser) {
      const response = await addToGroceryStoreCart(item, qty, false);

      if (response && response.data && response.data.AddToGroceryStoreCart) {
      } else {
        snackbar.enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    } else {
      openLoginDialog();
    }
  };

  const openLoginDialog = () => {
    dispatch({
      type: 'UI_ACTION_LOGIN_POPUP',
      payload: true
    });
  };

  if (discountedItems && discountedItems.length) {
    return (
      <Box padding={1}>
        <Grid container={true} spacing={2}>
          {discountedItems.map((item: GroceryStoreItemModal) => {
            return (
              <Grid item={true} key={item.id} xs={6} sm={4} md={2}>
                <GroceryItemCard
                  imagePreview={item.images && item.images[0].preview.url}
                  brand={item.brand}
                  price={item.price}
                  title={item.title}
                  size={item.size}
                  quantity={cartItems[item.id] ? cartItems[item.id][0].quantity : 0}
                  onQuantityChange={(qty: number) => onQuantityChange(item, qty)}
                  allowAddToCart={allowAddToCart}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  }

  if (called) {
    return (
      <Typography variant="body1" color="secondary">
        No discounted items found
      </Typography>
    );
  }

  return null;
};

export default GroceryDiscountedItems;

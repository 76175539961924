import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { GroceryOrderType, PaymentType } from 'generated/types';
import AmericanCardIcon from 'assets/img/american_express_icon.svg';
import ApplePayIcon from 'assets/img/apple_pay.svg';
import DiscoverIcon from 'assets/img/discover_icon.svg';
import MasterCardIcon from 'assets/img/mastercard.svg';
import GooglePayIcon from 'assets/img/google_pay_icon.png';
import CashIcon from 'assets/img/cash_icon.svg';
import VisaIcon from 'assets/img/visa.svg';
import { map } from 'lodash';
import ContactDetails from '../ContactDetails';
import CheckoutSummary from '../CheckoutSummary';
import { useStore } from 'store';
import GroceryStoreModel from 'models/GroceryStore';

interface IProps {
  groceryStore: GroceryStoreModel;
  onsiteAvailablePaymentTypeIcons: PaymentType[] | null;
}

const PayByCashUI = ({ onsiteAvailablePaymentTypeIcons, groceryStore }: IProps) => {
  const { state } = useStore();

  const { grocery_orderType, grocery_deliveryAddress, grocery_deliveryTime } = state;

  const images = map(onsiteAvailablePaymentTypeIcons, (image, index) => {
    let src = null;

    switch (image) {
      case PaymentType.OnsiteCash:
        src = CashIcon;
        break;
      case PaymentType.OnsiteApplePay:
        src = ApplePayIcon;
        break;
      case PaymentType.OnsiteDiscoverCard:
        src = DiscoverIcon;
        break;
      case PaymentType.OnsiteMasterCard:
        src = MasterCardIcon;
        break;
      case PaymentType.OnsiteAmericanExpressCard:
        src = AmericanCardIcon;
        break;
      case PaymentType.OnsiteVisaCard:
        src = VisaIcon;
        break;
      case PaymentType.OnsiteGooglePay:
        src = GooglePayIcon;
        break;
      default:
        src = null;
    }

    if (src) {
      return <img style={{ marginRight: 10 }} width="30" key={index} src={src} alt="Payment Icon" />;
    }
  });

  return (
    <>
      <Box textAlign="center" paddingTop={2}>
        <Typography variant="caption">WE ACCEPT FOLLOWING PAYMENT METHODS</Typography>
        <Box display="flex" justifyContent="center" alignItems="center">
          {images}
        </Box>
      </Box>
      <Box padding={1}>
        <ContactDetails />
        <CheckoutSummary
          storeName={groceryStore?.name || ''}
          storeAddress={groceryStore?.address}
          orderType={grocery_orderType || GroceryOrderType.Takeout}
          deliveryAddress={grocery_deliveryAddress}
          eta={grocery_deliveryTime}
        />
      </Box>
    </>
  );
};

export default PayByCashUI;

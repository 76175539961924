import React, { useState } from 'react';
import { Box, withStyles, Tabs, useTheme, Tab } from '@material-ui/core';
import RestaurantHomePage from './Restaurant';
import GroceryHomePage from './Grocery';

const HomePage = () => {
  const theme = useTheme();

  const StyledTabs = withStyles({
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: '3px'
    }
  })((props: any) => <Tabs {...props} />);

  const [activeTab, setActiveTab] = useState('restaurants');

  return (
    <Box>
      <Box padding={1}>
        <StyledTabs variant="fullWidth" value={activeTab} onChange={(_e: any, index: any) => setActiveTab(index)}>
          <Tab label="Restaurants" value="restaurants" />
          <Tab label="Grocery" value="groceryStores" />
        </StyledTabs>
      </Box>
      {activeTab === 'restaurants' && <RestaurantHomePage />}
      {activeTab === 'groceryStores' && <GroceryHomePage />}
    </Box>
  );
};

export default HomePage;
